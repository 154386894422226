import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, Table, TableHead, withStyles, createStyles, TableCell, Theme, TableRow, TableBody } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { AppState, IClienteLiberacao } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { SetClienteLiberacao } from "../../reducer/ActionsCreator";
import { Store } from "../../reducer/Reducer";
import Component2 from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import Formatter from "../../utils/Formatters";
import { withRouter } from "react-router-dom";
import Tabela2 from "../Tabela/Tabela2";
import { INocatualizador } from "../../persistence/interfaces/INocatualizador";
import { INocatualizador_historico } from "../../persistence/interfaces/INocatualizador_historico";
import Utils from "../../utils/Utils";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate?: AppState;
    linhaproduto: string;
    SetClienteLiberacao: (clienteliberacao: IClienteLiberacao) => void;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

class ExecucaoScripts extends PrivateComponent<IProps, any> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.ativarServicos = this.ativarServicos.bind(this);
        this.inativarServicos = this.inativarServicos.bind(this);
        this.detail = this.detail.bind(this);
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    consulta(query: any) {
        const expr = (field: string, type: string, formmat: string) => {
            let s = field;
            if (type == "date")
                s = `to_char(${field}, '${formmat}')`;
            return s;
        }
        //console.log(query)
        let order = (query?.orderBy?.field || 'id') + ' ' + (query?.orderDirection || 'desc');
        let search: string = query?.search || '';
        let field: string = query?.field || '';
        let type: string = query?.fieldtype || '';
        let formmat: string = query?.fieldformmat || '';
        let where: string = "";
        where = (field == "" ? `
            where  false
            or empresa::text like '%${search.toUpperCase()}%'
            or cnpj::text like '%${search.toUpperCase()}%'
            or id::text like '%${search.toUpperCase()}%'
            or pre_script::text like '%${search.toUpperCase()}%'
            or script::text like '%${search.toUpperCase()}%'
            or post_script::text like '%${search.toUpperCase()}%'
        ` : `
            where  ${expr(field, type, formmat)}::text like '%${search.toUpperCase()}%'
        `);

        return new Promise<any>(async(resolve, reject) => {
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql: `
                    select * from (
                        select 
                            *
                        from scriptssql c
                    ) as con1
                    ${where}
                    order by ${order}               
                `,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: "" 
            })
            // console.log(result)
    
            resolve({
                data: result?.data?.data,
                page: result?.data?.page-1,
                totalCount: result?.data?.total
            });
        })   
    }
    ativarServicos(rowData: any) {
        //console.log(rowData, `${rowData.licencaativa}`.toUpperCase());
        if (`${rowData.licencaativa}`.toUpperCase() != "ATIVA") {
            this.showConfirm("Confirma ativação dos serviços desse Cliente ?", async () => {
                this.props.SetClienteLiberacao({
                    codigo: rowData.c_codcf,
                    nome: rowData.c_cliente,
                    licenca: false,
                })
                this.redirectTo("/liberarclienteinadimplente/liberar/"+rowData.c_codcf);
            });
        }
        else {
            this.showAlert("Licença do cliente já esta ativa")
        }
    }
    inativarServicos(rowData: any) {
        if (`${rowData.licencaativa}`.toUpperCase() == "ATIVA") {
            this.showConfirm("Confirma inativação dos serviços desse Cliente ?", async () => {
                this.props.SetClienteLiberacao({
                    codigo: rowData.c_codcf,
                    nome: rowData.c_cliente,
                    licenca: false,
                })
                this.redirectTo("/liberarclienteinadimplente/bloquear/"+rowData.c_codcf);
            });
        }
        else {
            this.showAlert("Licença do cliente já esta inativa")
        }
    }
    async detail(row: any): Promise<any> {
        //nocatualizador_historico
        let data: any[] = await NocRequest.sql<any>(`select * from scriptssql_execucao where idscriptssql = '${row.id}' order by id desc`);
         
        //console.log(data);      
        return <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell size="small" colSpan={2}>Scripts para Execução</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell size="small">Pré Script</StyledTableCell>
                        <StyledTableCell size="small">{row.pre_script.split('\n').map((e: any) => <div><span>{e}</span><br/></div>)}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell size="small">Script</StyledTableCell>
                        <StyledTableCell size="small">{row.script.split('\n').map((e: any) => <div><span>{e}</span><br/></div>)}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell size="small">Post Script</StyledTableCell>
                        <StyledTableCell size="small">{row.post_script.split('\n').map((e: any) => <div><span>{e}</span><br/></div>)}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell size="small" colSpan={3}>Histórico de Execução</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell size="small">Empresa/CNPJ</StyledTableCell>
                        <StyledTableCell size="small">Horário</StyledTableCell>
                        <StyledTableCell size="small">Resultado Execução</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item: any, index: number) => {
                        return [
                            <TableRow style={{backgroundColor: index%2 == 0 ? 'white' : '#ccc'}}>
                                <StyledTableCell size="small">{item.empresa}/{item.cnpj}</StyledTableCell>
                                <StyledTableCell size="small">{Formatter.date2(item.horarioexecucao, "d-m-y h:n:s")}</StyledTableCell>
                                <StyledTableCell size="small">
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell size="small">Execuções</StyledTableCell>
                                            <StyledTableCell size="small">{item.execucoes}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell size="small">Sucesso</StyledTableCell>
                                            <StyledTableCell size="small">{item.execucoes_sucesso}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell size="small">Erros</StyledTableCell>
                                            <StyledTableCell size="small">{item.execucoes_erro}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell size="small">Resultado Pré-Script</StyledTableCell>
                                            <StyledTableCell size="small">{item.resultado_pre.split('\n').map((e: any) => <div><span>{e}</span><br/></div>)}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell size="small">Erros Pré-Script</StyledTableCell>
                                            <StyledTableCell size="small">{item.error_pre.split('\n').map((e: any) => <div><span>{e}</span><br/></div>)}</StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell size="small">Resultado Script</StyledTableCell>
                                            <StyledTableCell size="small">{item.resultado.split('\n').map((e: any) => <div><span>{e}</span><br/></div>)}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell size="small">Erros Script</StyledTableCell>
                                            <StyledTableCell size="small">{item.error.split('\n').map((e: any) => <div><span>{e}</span><br/></div>)}</StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell size="small">Resultado Post-Script</StyledTableCell>
                                            <StyledTableCell size="small">{item.resultado_post.split('\n').map((e: any) => <div><span>{e}</span><br/></div>)}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell size="small">Erros Post-Script</StyledTableCell>
                                            <StyledTableCell size="small">{item.error_post.split('\n').map((e: any) => <div><span>{e}</span><br/></div>)}</StyledTableCell>
                                        </TableRow>
                                    </TableBody>

                                </StyledTableCell>
                            </TableRow>,
                        ];

                    })}
                </TableBody>
            </Table>

        </div>;        
    }
    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    actions={[
                        {
                            icon: <AddCircleIcon/>,
                            isFreeAction: true,
                            //tooltip: "Adicionar uma nova versão",
                            onClick: (data: any) => {
                                //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                                this.redirectTo("/addexecucaoscript/new");
                            }
                        },
                        {
                            icon: <DeleteOutline/>,
                            //isFreeAction: false,
                            //tooltip: "Remover versão",
                            onClick: (data: any) => {
                                //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                                //this.removerUsuario(data.idversao);
                            }
                        },
                        {
                            icon: <Edit />,
                            //isFreeAction: false,
                            //tooltip: "Remover versão",
                            onClick: (data: any) => {
                                //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                                this.redirectTo(`/addexecucaoscript/edit/${data.id}`);
                            }
                        },
                    ]}
                    
                    columns={[
                        { title: "ID", field: "id" },
                        { title: "Empresa", field: "empresa" },
                        { title: "CNPJ", field: "cnpj" },
                        { title: "Execução", field: "tipoexecucao", render: (row: any) => {
                            switch (row.tipoexecucao) {
                                case 0: return `${row.tipoexecucao} - Única vez`;
                                case 1: return `${row.tipoexecucao} - Concluido sem erros`;
                                case 2: return `${row.tipoexecucao} - Execuções Atingida`;
                                case 3: return `${row.tipoexecucao} - Sucesso Atingido`;
                                case 4: return `${row.tipoexecucao} - Erros Atingido`;
                                default: return `${row.tipoexecucao} - Única vez`;
                            }
                        } },
                        { title: "Quant.", field: "quantidade_referencia" },
                        { title: "Ativo", field: "ativo", render: (row: any) => {
                            return row.ativo === 1 ? "Sim":"Não";
                        } },
                    ]}
                    fieldsSelect={[
                        "id", "empresa", "loja", "cnpj"
                    ]}
                    data={this.consulta}
                    orderByField="id"
                    orderByDirection="desc"
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     minBodyHeight: 'calc(100vh - 200px)',
                    //     maxBodyHeight: 'calc(100vh - 200px)',
                    //     pageSize: 30,
                    //     pageSizeOptions: [9, 20, 30, 50, 100],
                    //     search: true,
                    // }}
                    // search2={true}
                    search={true}
                    pageSize={30}
                    // onSearchChange={(txt: string) => { console.log(txt); }}
                    // onQueryChange={() => { console.log('query changed') }}

                    title="Execução de Scripts"
                    actionBarFreeWidth={2}
                    actionsColumns={2}
                    actionColumnWidth={25}
                    // onRowClick={this.rowClick/*RowClick*/}
                    // localization={{
                    //     header: {
                    //         actions: ""
                    //     }
                    // }}
                    detail={this.detail}               
                    timeoutDetail={5000}     
                    
                />
                {this.baseRender()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
        SetClienteLiberacao: (clienteliberacao: IClienteLiberacao) => dispatch(SetClienteLiberacao(clienteliberacao)),
    };
}

export default connect(mapStateToProps, mapDisptachToProps)(ExecucaoScripts);