import {Action as Acao} from "redux";
import { ITiposistema } from "../persistence/interfaces/ITiposistema";
import { ISprint } from "../persistence/interfaces/ISprint";
import { IClienteLiberacao } from "./ReducerTypes";
import { IConfiguracoes } from "../persistence/interfaces/IConfiguracoes";
export const ACTION_LOGIN: string = "ACTION_LOGIN";
export const ACTION_LOGINOK: string = "ACTION_LOGINOK";
export const ACTION_LOGOUT: string = "ACTION_LOGOUT";
export const ACTION_SETLINHAPRODUTO: string = "ACTION_SETLINHAPRODUTO";
export const ACTION_GETTIPOSSISTEMAS: string = "ACTION_GETTIPOSSISTEMAS";
export const ACTION_FETCHTIPOSSISTEMAS: string = "ACTION_FETCHTIPOSSISTEMAS";
export const ACTION_GETSPRINTS: string = "ACTION_GETSPRINTS";
export const ACTION_FETCHSPRINTS: string = "ACTION_FETCHSPRINTS";
export const ACTION_SETCLIENTELIBERACAO: string = "ACTION_SETCLIENTELIBERACAO";
export const ACTION_SETMAINCOLOR: string = "ACTION_SETMAINCOLOR";
export const ACTION_GETCONFIGURACOES: string = "ACTION_GETCONFIGURACOES";
export const ACTION_FETCHCONFIGURACOES: string = "ACTION_FETCHCONFIGURACOES";
export interface ActionLogin extends Acao<typeof ACTION_LOGIN> {
    login: string;
    password: string;
}
export interface ActionLoginOk extends  Acao<typeof ACTION_LOGINOK> {
    login: string;
    password: string;
    gruposUsuario: string[];
}
export interface ActionLogout extends Acao<typeof ACTION_LOGOUT> {
    
}
export interface ActionSetLinhaProduto extends Acao<typeof ACTION_SETLINHAPRODUTO> {
    linhaproduto: string;
}
export interface ActionGetTiposSistemas extends Acao<typeof ACTION_GETTIPOSSISTEMAS> {
}
export interface ActionFetchTiposSistemas extends Acao<typeof ACTION_FETCHTIPOSSISTEMAS> {
    tipossistemas: ITiposistema[];
}
export interface ActionGetSprints extends Acao<typeof ACTION_GETSPRINTS> {
}
export interface ActionFetchSprints extends Acao<typeof ACTION_FETCHSPRINTS> {
    sprints: ISprint[];
}
export interface ActionSetClienteLiberacao extends Acao<typeof ACTION_SETCLIENTELIBERACAO> {
    clienteliberacao: IClienteLiberacao;
}
export interface ActionSetMainColor extends Acao<typeof ACTION_SETMAINCOLOR> {
    cor: string;
}
export interface ActionGetConfiguracoes extends Acao<typeof ACTION_GETCONFIGURACOES> {
}
export interface ActionFetchConfiguracoes extends Acao<typeof ACTION_FETCHCONFIGURACOES> {
    configuracoes: IConfiguracoes[];
}
export type Action = ActionLogin | ActionLogout | ActionLoginOk | ActionSetLinhaProduto | ActionGetTiposSistemas | ActionFetchTiposSistemas | ActionGetSprints | ActionFetchSprints | ActionSetClienteLiberacao | ActionSetMainColor | ActionGetConfiguracoes | ActionFetchConfiguracoes;