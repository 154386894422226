import { IConfiguracoes } from "../persistence/interfaces/IConfiguracoes";

export default class Utils {
    static onynumbers(str: string): string {
        let r: string = "";
        const is = (v: number) => (v >= 48) && (v <= 57);
        
        for (let c = 0; c <= str.length-1; c++) {
            r += is(str.charCodeAt(c)) ? str.charAt(c) : "";
        }
        return r;
    }
    static fullReplace(str: string, search: string, subst: string): string {
        let c = -1;
        let r: string = "" + str;
        while ((c = r.indexOf(search, c + 1)) >= 0) {
          r = r.substring(0, c) + r.substring(c).replace(search, subst);
          //console.log(c, r);
          c += subst.length - 1;
        }
        return r;
      };
      
    static dateFormat(date: string, inFormat: string = "d-m-Y", outFormat: string = "Y-m-d", outSeparator: string = "-"): string {
        date = this.fullReplace(date, "/", "-");
        inFormat = this.fullReplace(inFormat, "/", "-");
        outFormat = this.fullReplace(outFormat, "/", "-");
        let aIn = inFormat.split("-");
        let aOut = outFormat.split("-");
        let aDate = date.split("-");
        const n = (s: any) => new Number(s).valueOf().toString();
        const len = (str: string, len: number) => n(str).padStart(len, '0');
        const d = () => len(aDate[aIn.indexOf("d")], 2);
        const m = () => len(aDate[aIn.indexOf("m")], 2);
        const Y = () => len(aDate[aIn.indexOf("Y")], 4);
        aOut[aOut.indexOf("d")] = d();
        aOut[aOut.indexOf("m")] = m();
        aOut[aOut.indexOf("Y")] = Y();
        return aOut.join(outSeparator);
    }
    static dateFormat2(date: any, format: string = "d-m-Y"): string {
        const lenl = (s: number|number, len: number, ch: string) => {
            return `${s}`.padStart(len, ch);
        }
        let d = new Date(date);
        let s = format;
        s = s.replace("d", lenl(d.getDate(), 2, '0'))
        s = s.replace("m", lenl(d.getMonth()+1, 2, '0'))
        s = s.replace("Y", lenl(d.getFullYear(), 4, '0'))
        s = s.replace("h", lenl(d.getHours(), 2, '0'))
        s = s.replace("n", lenl(d.getMinutes(), 2, '0'))
        s = s.replace("i", lenl(d.getMinutes(), 2, '0'))
        s = s.replace("s", lenl(d.getSeconds(), 2, '0'))
        return s;
    }
    static Configuracao(data: IConfiguracoes[], param: string): string {
        let v = '';
        data.forEach(conf => {
            if (`${conf.classe}/${conf.parametro}`.trim().toUpperCase() === param.toUpperCase()) {
                console.log('valor', conf)
                v = conf.valor;
            }
        });
        return v;
    }
    static ConfiguracaoBool(data: IConfiguracoes[], param: string): boolean {
        let v = Utils.Configuracao(data, param).trim().toUpperCase();
        
        return (v != '') && (['1','S','Y','SIM','YES','TRUE','T','V'].indexOf(v.trim().toUpperCase()) >= 0);
    }

}