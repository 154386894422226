/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory, createHashHistory } from "history";
import { Router, Route, Switch, Redirect, Link, HashRouter } from "react-router-dom";

// core components
import Admin from "./ui/layouts/Admin";
import RTL from "./ui/layouts/RTL";
import Clientes from "./components/Clientes/Clientes";

import "./ui/assets/css/material-dashboard-react.css?v=1.9.0";
import { Provider } from "react-redux";
import { Store } from "./reducer/Reducer";
import Login from "./components/Login/Login";
import "./includes/fontawesome";
import Dashboard from "./ui/views/Dashboard/Dashboard";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./secure/keycloak";
//const hist = createHashHistory();

ReactDOM.render(
  // <ReactKeycloakProvider authClient={keycloak} initOptions={{
  //   onLoad: "login-required",
  //   checkLoginIframe: true,
  //   autoRefreshToken: true
  // }}>
  <Provider store={Store}>
    <HashRouter>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/rtl" component={RTL} />
        <Route path="/" component={Admin} />
        {/* <Redirect from="/" component={Admin} /> */}
      </Switch>
    </HashRouter>
  </Provider>
  // </ReactKeycloakProvider>
  ,
  document.getElementById("root")
);
