import React, { useEffect, useState, useRef } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import SaveIcon from '@material-ui/icons/Save';
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Toc from "@material-ui/icons/Toc";
import Info from "@material-ui/icons/Info";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Table from "../../components/Table/Table";
import Tasks from "../../components/Tasks/Tasks";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Danger from "../../components/Typography/Danger";
import Success from "../../components/Typography/Success";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";

import { bugs, website, server } from "../../variables/general";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "../../variables/charts";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import cert from "../../assets/img/certificate.png";
import nfe from "../../assets/img/nota-fiscal-eletronica.png";
import NocRequest from "../../../utils/NocRequest";
import Tabela2 from "../../../components/Tabela/Tabela2";
import Formatter from "../../../utils/Formatters";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [info, setinfo] = useState({});
  const [updateCount, setupdateCount] = useState(0);
  const refCount = useRef(updateCount);
  refCount.current = updateCount;
  useEffect(() => {
    async function g() {
      setupdateCount(refCount.current+1);
      let r = await NocRequest.get("/informacoes/geral");
      if (r?.data)
        setinfo(r.data);
      setTimeout(g, 60000);
    }
    g();
  }, [ ]);
  const totalPendencias = () => {
    let r = 0;
    if (info.pendenciasNFe) {
      for (let c = 0; c <= info.pendenciasNFe.length-1; c++) {
        r += info.pendenciasNFe[c].quant;
      }
    }
    return r;
  }
  const getLojaMaiorPendencia = () => {
    let r = "";
    console.clear();
    console.log(info?.pendenciasNFe?.length > 0, info?.pendenciasNFe);
    r = info?.pendenciasNFe?.length > 0 ? (info.pendenciasNFe[0].filial ?? "") : "";
    if (r.length > 30)
      r = r.substring(0, 30)+ '...';

    return r;
  }

  let certData = [];
  if (info.certificadosVencendo)
    certData = info.certificadosVencendo;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Empresas/Lojas</p>
              <h3 className={classes.cardTitle}>{info?.info?.empresas}/<small>{info?.info?.lojas}</small></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                {info?.info?.ultimaatualizacao}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={info && info.disk && info.disk.length>0 && info.disk[0].perc_uso >= 70 ? "warning" : "success"} stats icon>
              <CardIcon color={info && info.disk && info.disk.length>0 && info.disk[0].perc_uso >= 70 ? "warning" : "success"}>
                <Toc/>
              </CardIcon>
              <p className={classes.cardCategory}>{info && info.disk && info.disk.length>0 && info.disk[0].perc_uso >= 70 ? "Uso crítico de disco" : "Uso de disco"}</p>
              <h3 className={classes.cardTitle}>
                {info && info.disk && info.disk.length>0 ? info.disk[0].perc_uso : "0"} <small>%</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              {info && info.disk && info.disk.length>0 && info.disk[0].perc_uso >= 70 ? 
                <Danger>
                  <Warning/>
                </Danger>
              : 
                <Success>
                  <Warning />
                </Success>
              }
                <a href="#pablo" onClick={e => e.preventDefault()}>
                {info && info.disk && info.disk.length>0 ? info.disk[0].empresa : ""}
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                  <Icon fontSize="small">
                  <img src={cert} width={48} height={32} />
                  </Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Certificados Vencendo</p>
              <h3 className={classes.cardTitle}>{info?.certificadosVencendo?.length}/<small>{info.certificadosVencidos}</small></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <CalendarTodayIcon />
                {info && info.certificadosVencendo && info.certificadosVencendo.length > 0 ? `${info.certificadosVencendo[0].empresa} (${info.certificadosVencendo[0].validade})` : ""}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                  <Icon fontSize="small">
                    <img src={nfe} width={48} height={35} />
                  </Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Pedências NF-e</p>
              <h3 className={classes.cardTitle}>{info?.pendenciasNFe?.length}/<small>{totalPendencias()}</small></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {getLojaMaiorPendencia()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Licenças:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Vencendo (2 dias)",
                tabIcon: Code,
                tabContent: (
                  <Tabela2
                    disableHeader={true}
                    actions={[
                    ]}
                    columns={[
                        { title: "CNPJ", field: "cnpj" },
                        { title: "Loja", field: "nome" },
                        { title: "Validade", field: "validade", render: Formatter.date("validade", "d-m-Y") },
                    ]}
                    actionBarFreeWidth={2}
                    actionColumnWidth={25}
                    actionsColumns={20}
                    pageSize={8}
                    data={(info?.listaLicencasVencendo || []).filter(e => e.dias <= 2)}
                    title=""
                  />
                )
              },
              {
                tabName: "Vencendo (+2 dias)",
                tabIcon: Code,
                tabContent: (
                  <Tabela2
                    disableHeader={true}
                    actions={[
                    ]}
                    columns={[
                        { title: "CNPJ", field: "cnpj" },
                        { title: "Loja", field: "nome" },
                        { title: "Validade", field: "validade", render: Formatter.date("validade", "d-m-Y") },
                    ]}
                    actionBarFreeWidth={2}
                    actionColumnWidth={25}
                    actionsColumns={20}
                    pageSize={8}
                    data={(info?.listaLicencasVencendo || []).filter(e => e.dias > 2)}
                    title=""
                  />
                )
              },
            ]}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="NF-e:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Rejeitadas",
                tabIcon: Code,
                tabContent: (
                  <Tabela2
                    // icons={tableIcons}
                    disableHeader={true}
                    actions={[
                    ]}
                    columns={[
                        { title: "Loja", field: "loja" },
                        { title: "Nome", field: "nome" },
                        { title: "Quant.", field: "quant" },
                    ]}
                    actionBarFreeWidth={2}
                    actionColumnWidth={25}
                    actionsColumns={20}
                    pageSize={8}
                    data={info?.listaPendenciasNFe?.filter((a) => a.situacao == "NF Rejeitada") || []}
                    title=""
                  />
                  // <Tasks
                  //   checkedIndexes={[0, 3]}
                  //   tasksIndexes={[0, 1, 2, 3]}
                  //   tasks={bugs}
                  // />
                )
              },
              {
                tabName: "Pendentes",
                tabIcon: Code,
                tabContent: (
                  <Tabela2
                    // icons={tableIcons}
                    disableHeader={true}
                    actions={[
                    ]}
                    columns={[
                        { title: "Loja", field: "loja" },
                        { title: "Nome", field: "nome" },
                        { title: "Quant.", field: "quant" },
                    ]}
                    actionBarFreeWidth={2}
                    actionColumnWidth={25}
                    actionsColumns={20}
                    pageSize={5}
                    data={info?.listaPendenciasNFe?.filter((a) => a.situacao == "NF Pendente") || []}
                    title=""
                  />
                  // <Tasks
                  //   checkedIndexes={[0]}
                  //   tasksIndexes={[0, 1]}
                  //   tasks={website}
                  // />
                )
              },
              {
                tabName: "Cont. Não Enviada",
                tabIcon: Code,
                tabContent: (
                  <Tabela2
                    // icons={tableIcons}
                    disableHeader={true}
                    actions={[
                    ]}
                    columns={[
                        { title: "Loja", field: "loja" },
                        { title: "Nome", field: "nome" },
                        { title: "Quant.", field: "quant" },
                    ]}
                    actionBarFreeWidth={2}
                    actionColumnWidth={25}
                    actionsColumns={20}
                    pageSize={5}
                    data={info?.listaPendenciasNFe?.filter((a) => a.situacao == "Cont. NF Nao Env") || []}
                    title=""
                  />
                  // <Tasks
                  //   checkedIndexes={[1]}
                  //   tasksIndexes={[0, 1, 2]}
                  //   tasks={server}
                  // />
                )
              },
              {
                tabName: "Cont. Rejeitada",
                tabIcon: Code,
                tabContent: (
                  <Tabela2
                    // icons={tableIcons}
                    disableHeader={true}
                    actions={[
                    ]}
                    columns={[
                        { title: "Loja", field: "loja" },
                        { title: "Nome", field: "nome" },
                        { title: "Quant.", field: "quant" },
                    ]}
                    actionBarFreeWidth={2}
                    actionColumnWidth={25}
                    actionsColumns={20}
                    pageSize={5}
                    data={info?.listaPendenciasNFe?.filter((a) => a.situacao == "Cont. NF Rejeitada") || []}
                    title=""
                  />
                  // <Tasks
                  //   checkedIndexes={[1]}
                  //   tasksIndexes={[0, 1, 2]}
                  //   tasks={server}
                  // />
                )
              }
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>

          <Tabela2
            // icons={tableIcons}
            actions={[
            ]}
            columns={[
                { title: "Empresa", field: "empresa" },
                { title: "Código", field: "codigo" },
                { title: "Nome", field: "nome" },
                { title: "Loja", field: "loja" },
                { title: "Validade", field: "validade" },
            ]}
            fieldsSelect={[
                "empresa", "nomeempresa", "cnpj", "nome", "datai", "dataf", "count"
            ]}
            actionBarFreeWidth={2}
            actionColumnWidth={25}
            actionsColumns={20}
            pageSize={5}
            data={certData}
            //search={true}
            title="Certificados Vencendo"
            subtitle="Listagem de lojas com certificados vencendo"
          />


          {/* <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Employees Stats</h4>
              <p className={classes.cardCategoryWhite}>
                New employees on 15th September, 2016
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["ID", "Name", "Salary", "Country"]}
                tableData={[
                  ["1", "Dakota Rice", "$36,738", "Niger"],
                  ["2", "Minerva Hooper", "$23,789", "Curaçao"],
                  ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
                  ["4", "Philip Chaney", "$38,735", "Korea, South"]
                ]}
              />
            </CardBody>
          </Card> */}
        </GridItem>
      </GridContainer>


      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Sales</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer> */}

    </div>
  );
}
