import React, { Component, forwardRef, createRef } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Typography, withStyles, Theme } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import fileDownload from "js-file-download";

interface IState {
    pageNumber: number;
    numPages: number;
}
interface IProps {
    open: boolean;
    title: string;
    pdf: string;

    dialogClose: () => void;
}

class PDFViewer extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.download = this.download.bind(this);
        this.state = {
            pageNumber: 1,
            numPages: null as any,
        }
    }
    onDocumentLoadSuccess(pdf: any) {
        this.setState({numPages: pdf.numPages});
    }
    download() {
        fileDownload(Buffer.from(this.props.pdf, "base64"), "report.pdf");
    }

    render() {
        let r: any[] = [];

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.dialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                // fullScreen={true}
                >
                    <DialogTitle id="alert-dialog-title" onClick={this.props.dialogClose} >
                        <Typography variant="h6">{this.props.title || ""}</Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Document options={{ workerSrc: "pdf.worker.js" }} onLoadSuccess={this.onDocumentLoadSuccess} file={"data:applicaion/pdf;base64," + this.props.pdf} >
                            {/* <Page pageNumber={this.state.pageNumber} /> */}
                            {Array.from(new Array(this.state.numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}

                        </Document>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.download} color="primary" autoFocus>
                            DOWNLOAD
                        </Button>
                        <Button onClick={this.props.dialogClose} color="primary" autoFocus>
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


export default PDFViewer;