import React, { Component, ChangeEventHandler, useState } from "react";
import {BrowserRouter} from "react-router-dom";
import "./Login.css";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { Redirect } from "react-router-dom";
import { Store } from "../../reducer/Reducer";
import { Login as DoLogin, Logout, LoginOk, GetTiposSistemas, GetSprints } from "../../reducer/ActionsCreator";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { IconButton, Theme, makeStyles, withStyles, createStyles, WithStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import Button from "@material-ui/core/Button"
import MessageBox from "../MessageBox/MessageBox";
import WS from "../../Config/WS";
import Component2, { EmptyBaseState } from "../Component2/Component2";
import TabelaGridContainer from "../Tabela/TabelaGridContainer";
import TabelaGridItem from "../Tabela/TabelaGridItem";
import Card from "../../ui/components/Card/Card";
import CardHeader from "../../ui/components/Card/CardHeader";
import CardBody from "../../ui/components/Card/CardBody";
import CustomInput from "../../ui/components/CustomInput/CustomInput";
import PrivateComponent from "../Component2/PrivateComponent";

interface IState {
}
interface IProps {
    appstate: AppState;
}
interface IRef {
    [Name: string]: React.RefObject<any>;
}

class SemAcesso extends Component2<IProps, any> {
    constructor(props: any) {
        super(props);
        //this.closeAlert = this.closeAlert.bind(this);
        this.state = { 
        };
    }
    componentDidMountPrivate() {
    }
    render() {
        return (
            <React.Fragment>
            <span>Você não possui permissão de acesso ao recurso selecionado!</span>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: AppState): any => {
    return {
        appstate: state,
    }
}
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemAcesso);