/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks";
import RTLNavbarLinks from "../Navbars/RTLNavbarLinks";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle";
import { Collapse } from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles(styles);

function activeRoute(routeName) {
  return window.location.href.indexOf(routeName) > -1 ? true : false;
}


export default function Sidebar(props) {
  let oOpen = {};
  for(let x of props.routes) {
    if ((x?.items?.length || 0) > 0) {
      oOpen[x.name] = false;
    }
  }
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(oOpen);

  function handleClick() {
    setOpen(!open);
  }
  function handleClick2(grupo) {
    //console.log(grupo, open2);
    if (grupo != null) {
      open2[grupo.name] = ! open2[grupo.name];
      for (let s in open2) {
        if (s != grupo.name)
          open2[s] = false;
      }
      setOpen(open2);
    }
  }

  function getGrupo(props, classes, grupo) {
    // verifies if routeName is the one active (in browser input)
    const { color, logo, image, logoText, routes } = props;
    var links = [
      <List className={classes.list} disablePadding>
        {(grupo != null ? grupo.items : routes).map((prop, key) => {
          
          var activePro = " ";
          var listItemClasses;
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path)
          });
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
          });
          //console.log(prop.layout + prop.path)
          return (
            <NavLink
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses} onClick={(prop?.items?.length || 0) == 0 ? null : () => handleClick2(prop)}>
                {/* {(grupo?.items?.length || 0) == 0 ? null : <div style={{width: '20px'}}>O</div>} */}
                {/* <ListItemIcon> */}
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(grupo == null ? classes.itemIcon : classes.itemIconSub, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                    <prop.icon
                      className={classNames(grupo == null ? classes.itemIcon : classes.itemIconSub, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive
                      })}
                    />
                  )}
                {/* </ListItemIcon> */}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive
                  })}
                  
                  disableTypography={false}
                />

                {(prop?.items?.length || 0) == 0 ? null : (open2[prop.name] ? <ExpandLess /> : <ExpandMore />)}
                
              </ListItem>
              {(prop?.items?.length || 0) > 0 ? <Collapse in={open2[prop.name]} timeout="auto" unmountOnExit>{getGrupo(props, classes, prop)} </Collapse> : null}
            </NavLink>
          );
        })}
      </List>
    ];
    return links;
  }
  
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  const { color, logo, image, logoText, routes } = props;
  var links = getGrupo(props, classes, null);


  var brand = (
    <div className={classes.logo}>
      <NavLink to="/admin/dashboard">
        <a
          href="#"
          className={classNames(classes.logoLink, {
            [classes.logoLinkRTL]: props.rtlActive
          })}
          
        >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
        </a>
      </NavLink>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
