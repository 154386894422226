import React, {Component, ReactElement} from "react";
export default class Formatter {
    /**
     * Funcao de redenrizacao de valores em moeda com **size** casas decimais
     */
    static moeda(field: string, size: number = 2): any {
        return (data: any): string => {
            if (data[field] instanceof Number)
                return (data[field] as Number).toFixed(2);
            
            return new Number(data[field]).toFixed(2);
        }
    }

    static date(field: string, format: string = "d-m-Y"): any {
        const lenl = (s: number|number, len: number, ch: string) => {
            return `${s}`.padStart(len, ch);
        }
        return (data: any): string => {
            let d = new Date(data[field]);
            let s = format;
            s = s.replace("d", lenl(d.getDate(), 2, '0'))
            s = s.replace("m", lenl(d.getMonth()+1, 2, '0'))
            s = s.replace("y", lenl(d.getFullYear(), 4, '0'))
            s = s.replace("Y", lenl(d.getFullYear(), 4, '0'))
            s = s.replace("h", lenl(d.getHours(), 2, '0'))
            s = s.replace("n", lenl(d.getMinutes(), 2, '0'))
            s = s.replace("i", lenl(d.getMinutes(), 2, '0'))
            s = s.replace("s", lenl(d.getSeconds(), 2, '0'))
            return (data[field] ?? "") == "" ? "" : s;
        }
    }

    static date2(data: any, format: string = "d-m-Y"): any {
        const lenl = (s: number|number, len: number, ch: string) => {
            return `${s}`.padStart(len, ch);
        }
        let d = new Date(data);
        let s = format;
        s = s.replace("d", lenl(d.getDate(), 2, '0'))
        s = s.replace("m", lenl(d.getMonth()+1, 2, '0'))
        s = s.replace("y", lenl(d.getFullYear(), 4, '0'))
        s = s.replace("Y", lenl(d.getFullYear(), 4, '0'))
        s = s.replace("h", lenl(d.getHours(), 2, '0'))
        s = s.replace("n", lenl(d.getMinutes(), 2, '0'))
        s = s.replace("i", lenl(d.getMinutes(), 2, '0'))
        s = s.replace("s", lenl(d.getSeconds(), 2, '0'))
        return s;
    }    
}