import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios, { AxiosRequestConfig } from "axios";
import { Input, Button, Select, MenuItem } from "@material-ui/core";
import "./Licencas.css";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import { EmptyBaseState } from "../Component2/Component2";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tabela2 from "../Tabela/Tabela2";
import { Filter1, Filter2, Filter3, Filter4, Filter5 } from "@material-ui/icons";
import { IGruposUsuario } from "../../utils/SecTypes";

const tableIcons: any = {
    Add: forwardRef((props, ref: any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref: any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref: any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref: any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref: any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref: any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref: any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref: any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref: any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref: any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref: any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref: any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref: any) => <ViewColumn {...props} ref={ref} />)
};

const RowClick = async (event: any, rowdata: any, errorFunc: any) => {
}
interface IRef {
    [Name: string]: React.RefObject<HTMLInputElement>;
}
interface IState {
    estado: "grid" | "edit" | "insert";
    razao_social: string;
    cnpj: string;
    ativo: string;
    listaAtivos: "S" | "N" | "T" | "NC" | "DP";
}
interface IProps {
    state: AppState;
}
class Licencas extends PrivateComponent<IProps, IState> {
    Ref: IRef;
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.dochange = this.dochange.bind(this);
        this.submit = this.submit.bind(this);
        this.consulta = this.consulta.bind(this);
        this.subtitle = this.subtitle.bind(this);
        this.userHas = this.userHas.bind(this);

        this.doinsert = this.doinsert.bind(this);
        this.doedit = this.doedit.bind(this);
        this.doremove = this.doremove.bind(this);

        this.Ref = {
            'cnpj': React.createRef(),
            'razao_social': React.createRef(),
            'ativo': React.createRef(),
        };
        
        this.state = {
            ...EmptyBaseState,
            estado: "grid",
            cnpj: "",
            razao_social: "",
            ativo: "",
            listaAtivos: "T",
        }
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    userHas(roles: IGruposUsuario[]): boolean {
        let r = false;
        for (let role of roles) {
            if (this.props.state.login.gruposUsuario.indexOf(role) != -1) {
                r = true;
                break;
            }
        }
        return r;
    }
    doinsert() {
        if (this.userHas(["financeiro", "noc"])) {
            this.setState(Object.assign({}, this.state, { estado: "insert", cnpj: "", razao_social: "", ativo: "" }));
        }
        else {
            this.showAlert("Você não possui permissão de acesso ao recurso selecionado!");
        }
    }
    doedit(cnpj: string, razao_social: string, ativo: string) {
        if (this.userHas(["financeiro", "noc"])) {
            this.setState(Object.assign({}, this.state, { estado: "edit", cnpj, razao_social, ativo }));
        }
        else {
            this.showAlert("Você não possui permissão de acesso ao recurso selecionado!");
        }
    }
    doremove(cnpj: string) {
        if (this.userHas(["financeiro", "noc"])) {
            console.log(cnpj);
            this.showConfirm(['Tem certeza que deseja remove essa Licença ?', '(Atenção: Esse CNPJ poderá ser re-inserido pelo Sistema de Licenciamento Automático)'], async () => {
                let config: AxiosRequestConfig = {
                    method: 'DELETE',
                    url: WS.URL_NOC + `licenca/removerLicenca/${cnpj}`,
                }
                await axios(config);
                this.addRefreshCount();
            }, "lg");
            }
        else {
            this.showAlert("Você não possui permissão de acesso ao recurso selecionado!");
        }
    }
    dogrid() {
        this.setState(Object.assign({}, this.state, { estado: "grid", cnpj: "", razao_social: "", ativo: "" }));
    }
    dochange(e: any) {
        e.preventDefault();
        let { name, value } = e.target;
        let s: any = this.state;
        s[name] = value;
        this.setState(s)
    }
    async submit(e: any) {
        e.preventDefault();
        let { cnpj, razao_social, ativo } = this.state;
        let config: AxiosRequestConfig = {
            method: 'POST',
            url: WS.URL_NOC + 'licenca/updateLicenca',
            data: {
                cnpj, razao_social, ativo
            }
        }
        await axios(config);
        this.dogrid();
    }
    consulta(query: any) {
        let order = (query?.orderBy?.field || 'razao_social') + ' ' + (query?.orderDirection || 'desc');
        let search: string = query?.search || '';
        return new Promise<any>(async (resolve, reject) => {
            let sql = `
                    select n.cnpj, n.razao_social, case when n.ativo then 'S' else 'N' end as ativo , cli.c_codigo as codigocliente, dup.quant, dup.codigos
                    from nfes_licencas n
                    left join a_client cli on cli.c_cgccpf = n.cnpj and trim(cli.c_cgccpf) <> ''
                    left join (select c_cgccpf , count(*) as quant, regexp_replace(array_agg(c_codigo)::text, '[^0-9,]', '', 'gi') as codigos from a_client where trim(c_cgccpf)<>'' group by c_cgccpf having count(*)>1) dup on dup.c_cgccpf = n.cnpj
                    where 
                        ${this.state.listaAtivos == "S" ? 'ativo = true and ' : (this.state.listaAtivos == "N" ? 'ativo = false and ' : (this.state.listaAtivos == "NC" ? 'cli.c_codigo is null and ' : (this.state.listaAtivos == "DP" ? 'dup.codigos is not null and ' : '')))}
                        (
                        (regexp_replace(cnpj, '[^0-9]', '', 'gi') like '%'||regexp_replace('${search}', '[^0-9]', '', 'gi')||'%' and regexp_replace('${search}', '[^0-9]', '', 'gi') <> '')
                        or upper(razao_social) like upper('%'||'${search}'||'%')
                        or upper(cli.c_codigo) like upper('%'||'${search}'||'%')
                        )
                    order by ${order}               
                `;
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: ""
            })
    
            resolve({
                data: result?.data?.data,
                page: result?.data?.page - 1,
                totalCount: result?.data?.total
            });
        })
    }
    subtitle(): string {
        if (this.state.listaAtivos == "T") {
            return "Listagem de licenças registradas";
        }
        else if (this.state.listaAtivos == "S") {
            return "Listagem de licenças registradas e ativas";
        }
        else if (this.state.listaAtivos == "N") {
            return "Listagem de licenças registradas e inativas";
        }
        else if (this.state.listaAtivos == "NC") {
            return "Listagem de licenças sem cadastro de clientes";
        }
        else if (this.state.listaAtivos == "DP") {
            return "Listagem de licenças com cadastro de clientes duplicados";
        }
        else {
            return "Listagem de licenças registradas";
        }
    }
    renderPrivate() {
        if (this.state.estado == "grid") {
            return (
                <div style={{ maxWidth: "100%", width: '100%' }}>
                    <Tabela2
                        // icons={tableIcons}

                        actions={[
                            {
                                icon: <Filter1 />,
                                tooltip: 'Exibir Todos',
                                onClick: () => { this.estado({listaAtivos: "T", refreshCount: this.state.refreshCount+1}) },
                                isFreeAction: true,
                            },
                            {
                                icon: <Filter2 />,
                                tooltip: 'Exibir Ativos',
                                onClick: () => { this.estado({listaAtivos: "S", refreshCount: this.state.refreshCount+1}) },
                                isFreeAction: true,
                            },
                            {
                                icon: <Filter3 />,
                                tooltip: 'Exibir Inativos',
                                onClick: () => { this.estado({listaAtivos: "N", refreshCount: this.state.refreshCount+1}) },
                                isFreeAction: true,
                            },
                            {
                                icon: <Filter4 />,
                                tooltip: 'Exibir Não Cadastrados como Clientes',
                                onClick: () => { this.estado({listaAtivos: "NC", refreshCount: this.state.refreshCount+1}) },
                                isFreeAction: true,
                            },
                            {
                                icon: <Filter5 />,
                                tooltip: 'Exibir Clientes duplicados',
                                onClick: () => { this.estado({listaAtivos: "DP", refreshCount: this.state.refreshCount+1}) },
                                isFreeAction: true,
                            },
                            {
                                icon: <AddBox/>,
                                tooltip: 'Nova Licença',
                                onClick: () => { this.doinsert() },
                                isFreeAction: true,
                            },
                            {
                                icon: <Edit/>,
                                tooltip: 'Editar Licença',
                                onClick: (data: any) => { this.doedit(data.cnpj, data.razao_social, data.ativo) }
                            },
                            {
                                icon: <DeleteOutline/>,
                                tooltip: 'Remover Licença 2',
                                onClick: (data: any) => { this.doremove(data.cnpj) }
                            },
                        ]}
                        actionBarFreeWidth={2}
                        actionsColumns={6}
                        actionColumnWidth={25}
                        columns={[
                            { title: "Código", field: "codigocliente", render: (data: any) => {
                                return <span className={data.ativo == 'S' ? 'green' : 'red'}> {data.codigocliente} </span>;
                            }  },
                            { title: "CNPJ", field: "cnpj", render: (data: any) => {
                                return <span className={data.ativo == 'S' ? 'green' : 'red'}> {data.cnpj} </span>;
                            }  },
                            { title: "Razão Social", field: "razao_social", render: (data: any) => {
                                return <span className={data.ativo == 'S' ? 'green' : 'red'}> {data.razao_social}<br/>{this.state.listaAtivos == "DP" ? data.codigos : null}  </span>;
                            }  },
                            {
                                title: "Ativo", field: "ativo", render: (data: any) => {
                                    return <span className={data.ativo == 'S' ? 'green' : 'red'}> {data.ativo == 'S' ? "Sim" : "Não"} </span>;
                            } },

                        ]}
                        

                        data={this.consulta}
                        // options={{
                        //     searchFieldAlignment: 'right',
                        //     //maxBodyHeight: '50vw',
                        //     pageSize: 30,
                        //     pageSizeOptions: [5, 10, 20, 30, 50, 100]
                        // }}
                        search={true}
                        pageSize={30}
                        title="Licenças"
                        subtitle={this.subtitle()}
                        // onRowClick={this.rowClick/*RowClick*/}
                        refreshCount={this.state.refreshCount}

                    />
                    {this.baseRender()}
                </div>
            );
        }
        else if ((this.state.estado == "edit") || (this.state.estado == "insert")) {
            return (
                <div className="container col-4">
                    <div className="card">
                        <div className="card-header">
                            Licenças
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col col-6">
                                    CNPJ:
                                </div>
                                <div className="col col-6">
                                    <Input {...this.props}
                                        inputRef={this.Ref['cnpj']}
                                        name="cnpj"
                                        value={this.state.cnpj}
                                        onChange={this.dochange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-6">
                                    Raz&atilde;o Social:
                                </div>
                                <div className="col col-6">
                                    <Input {...this.props}
                                        inputRef={this.Ref['razao_social']}
                                        name="razao_social"
                                        value={this.state.razao_social}
                                        onChange={this.dochange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-6">
                                    Ativo:
                                </div>
                                <div className="col col-6">
                                    <Select {...this.props}
                                        inputRef={this.Ref['ativo']}
                                        name="ativo"
                                        value={this.state.ativo}
                                        onChange={this.dochange}>

                                        <MenuItem value="N">N&atilde;o</MenuItem>
                                        <MenuItem value="S">Sim</MenuItem>

                                    </Select>
                                </div>
                            </div>
                            <br /><br />
                            <div className="row">
                                <div className="col col-6">
                                    <Button color="primary" variant="contained" onClick={(e: any) => this.submit(e)}>Salvar</Button>
                                    &nbsp;&nbsp;<Button color="secondary" variant="contained" onClick={() => this.dogrid()}>Voltar</Button>
                                </div>
                                <div className="col col-6">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.baseRender()}
                </div>
            );

        }
        return <div></div>;
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {state, ...ownProps};
}
export default connect(mapStateToProps)(Licencas);