import React, { Component, forwardRef, ChangeEvent, KeyboardEvent, useMemo } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Input, Button, Select, MenuItem } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { ITiposistema } from "../../persistence/interfaces/ITiposistema";
import { EmptyBaseState } from "../Component2/Component2";
import { RecordWithTtl } from "dns";
import MessageBox from "../MessageBox/MessageBox";
import NumericInput from "../NumericInput/NumericInput";
import { connect } from "react-redux";
import { AppState } from "../../reducer/ReducerTypes";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import { ISprint } from "../../persistence/interfaces/ISprint";
import { withRouter } from "react-router-dom";
import TabelaGridContainer from "../Tabela/TabelaGridContainer";
import TabelaGridItem from "../Tabela/TabelaGridItem";
import Card from "../../ui/components/Card/Card";
import CardHeader from "../../ui/components/Card/CardHeader";
import CardBody from "../../ui/components/Card/CardBody";
import Formulario, { FormularioClass } from "../Formulario/Formulario";
import { FormFuncs, OldState } from "../Formulario/FormularioTypes";
import md5 from "md5";
import { IGruposUsuario } from "../../utils/SecTypes";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate: AppState;
}
interface IState {
    sistemas: ITiposistema[];
    versoes: IVersao;
}
interface IRefs {
    [Name: string]: React.RefObject<any>;
}
interface IVersao {
    [Name: string]: string;
}
class AddUsuario extends PrivateComponent<IProps, IState> {
    Refs: IRefs;
    constructor(props: any) {
        super(props);
        this.onBeforePost = this.onBeforePost.bind(this);
        
        this.Refs = {};
        let v: IVersao = {};
        this.lista().forEach((t: ITiposistema, index: number) => {
            this.Refs[t.codigo] = React.createRef();
            v[t.codigo] = "";
        });
        this.Refs["sprint"] = React.createRef();
        v["sprint"] = "null";

        this.state = {
            ...EmptyBaseState,
            sistemas: [],
            versoes: v,
        }
    }
    lista(): ITiposistema[] {
        return this.props.appstate.dados.tipossistemas.filter((v: ITiposistema) => v.linhaproduto == this.props.appstate.dados.linhaproduto);

    }
    componentDidMountPrivate() {
    }
    async onBeforePost(self: FormularioClass, state: OldState) {
        let perms: IGruposUsuario[] = state.gruposinscritos.split(",").map((s: string) => s.trim());
        if (this.props.appstate.login.gruposUsuario.indexOf("noc") == -1) {
            state.gruposinscritos = perms.filter((s: IGruposUsuario) => this.props.appstate.login.gruposUsuario.indexOf(s) >= 0).join(",");
        }
        
        if (state.senha != self.state.valoresIniciais.senha) {
            state.senha = md5(state.senha);
            return {...state};
        }


        return {...state};
    }
    renderPrivate() {
        return (
            <Formulario
                formWidth={8}
                formTitle="Cadastro de Usuário"
                entity="Usuarios"
                goBack={() => this.redirectTo("/admin/usuarios")}
                onBeforePost={this.onBeforePost}
                Campos={[
                    {
                        type: "string",
                        name: "usuario",
                        caption: "Usuário",
                        widthField: 3,
                    },
                    {
                        type: "string",
                        name: "nome",
                        caption: "Nome",
                        widthField: 3,
                    },
                    {
                        type: "password",
                        name: "senha",
                        caption: "Senha",
                        helpText: "Informar a senha em formato plano ou md5",
                        widthField: 3,
                    },
                    {
                        type: "combo",
                        comboItensDef: [
                            {caption: "Ativo", value: "A"},
                            {caption: "Inativo", value: "I"},
                        ],
                        widthField: 3,
                        caption: "Status",
                        name: "status",
                    },
                    //FormFuncs.space(2),
                    // {
                    //     type: "string",
                    //     name: "gruposinscritos",
                    //     caption: "Grupos Push/WhatsApp",
                    //     helpText: "Informar a lista de números de Grupos Push separadados por vírgula. Grupos permitidos: noc,financeiro,comercial,backup",
                    //     widthField: 4,
                    // },
                    {
                        type: "string",
                        name: "idwhatsapp",
                        caption: "WhatsApp",
                        helpText: "Informar a lista de números de WhatsApp separadados por vírgula",
                        widthField: 4,
                    },
                    //FormFuncs.space(4),
                    {
                        type: "chipgroup",
                        name: "gruposinscritos",
                        caption: "Grupos do Usuário",
                        helpText: "Informar a lista de números de WhatsApp separadados por vírgula",
                        widthField: 8,
                        chipGroupLabels: ["Administração", "Segurança", "Backup's", "Suporte", "Atualização", "Instalação", "Financeiro", "Licença", "Criar Licença 1 mês", "Criar Licença 2 meses", "Criar Licença 3 meses"],
                        chipGroupValues: ["noc", "seguranca", "backup", "suporte", "atualizacao", "instalacao", "financeiro", "licenca", "criarlicenca1", "criarlicenca2", "criarlicenca3"],
                        chipValueSeparator: ","
                    },
                    // {
                    //     type: "combo",
                    //     comboItensDef: {
                    //         sql: "select * from a_client limit 2",
                    //         fieldLabel: "c_cliente",
                    //         fieldValue: "c_codigo",
                    //     },
                    //     widthField: 8,
                    //     caption: "Cliente",
                    //     name: "cliente",
                    // },
                ]}
                
            >

            </Formulario>
            
        );    
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
// const mapDisptachToProps = (dispatch: any, ownProps: any) => {
//     return {
//         //SetClienteVenda: (codigocliente: number, nome: string) => dispatch(SetClienteVenda(codigocliente, nome)),
//         ...ownProps,
//     };
// }

export default connect(mapStateToProps)(AddUsuario);
