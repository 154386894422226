import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { SetLinhaProduto } from "../../reducer/ActionsCreator";
import { Store } from "../../reducer/Reducer";
import Component2 from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import Formatter from "../../utils/Formatters";
import { withRouter } from "react-router-dom";
import Tabela2 from "../Tabela/Tabela2";
import Utils from "../../utils/Utils";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate?: AppState;
    linhaproduto: string;
    SetLinhaProduto?: (linhaproduto: string) => void;
}

class Clientes extends PrivateComponent<IProps, any> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.removerVersao = this.removerVersao.bind(this);
        this.removerVersao1 = this.removerVersao1.bind(this);
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    consulta(query: any) {
        const expr = (field: string, type: string, formmat: string) => {
            let s = field;
            if (type == "date")
                s = `to_char(${field}, '${formmat}')`;
            return s;
        }
        //console.log(query)
        let order = (query?.orderBy?.field || 'pendente') + ' ' + (query?.orderDirection || 'desc');
        let search: string = query?.search || '';
        let field: string = query?.field || '';
        let type: string = query?.fieldtype || '';
        let formmat: string = query?.fieldformmat || '';
        let where: string = "";
        where = field == "" ? `
            where  false
            or codigo::text like '%${search.toUpperCase()}%'
            or cpfcnpj::text like '%${search.toUpperCase()}%'
            or cliente::text like '%${search.toUpperCase()}%'
        ` : `
            where  ${expr(field, type, formmat)}::text like '%${search.toUpperCase()}%'
        `;
        let contrato = Utils.ConfiguracaoBool(this.props.appstate?.dados.configuracoes || [], 'financeiro/contas-contrato');
        return new Promise<any>(async(resolve, reject) => {
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql: `
                    select * from (
                        select c.c_codigo as codigo, c.c_cgccpf as cpfcnpj, c.c_cliente as cliente, pendente.c_saldo as pendente, vencido.c_saldo as vencido, pago.c_saldo as pago, pendente.c_dtvenc,
                               case when l.ativo is null then '' else case when coalesce(l.ativo,false) then 'Ativa' else 'Inativa' end end as licencaativa
                        from a_client c
                        left join (
                            select c_codcf, sum(h.c_valor) as c_saldo, min(c_dtvenc) as c_dtvenc  from a_contas c
                            inner join a_hpcont h on h.c_chave=c.c_chave
                            where c.c_tipo='R' ${contrato ? ` and coalesce(c_contrato, 0) > 0 ` : ''}
                            group by c_codcf
                        ) as pago on c.c_codigo = pago.c_codcf
                        left join (
                            select c_codcf, sum(c_saldo) as c_saldo, min(c_dtvenc) as c_dtvenc  from a_contas
                            where c_tipo='R' and trim(c_sit)='' ${contrato ? ` and coalesce(c_contrato, 0) > 0 ` : ''}
                            group by c_codcf
                        ) as pendente on c.c_codigo = pendente.c_codcf
                        left join (
                            select c_codcf, sum(c_saldo) as c_saldo  from a_contas
                            where c_tipo='R' and trim(c_sit)='' and c_dtvenc < current_date ${contrato ? ` and coalesce(c_contrato, 0) > 0 ` : ''}
                            group by c_codcf
                        ) as vencido on c.c_codigo = vencido.c_codcf
                        left join nfes_licencas l on regexp_replace(l.cnpj, '[^0-9]', '', 'gi') = regexp_replace(c.c_cgccpf, '[^0-9]', '', 'gi')
                        ${contrato ? ` inner join lateral (select c_codcf from a_contas where c_codcf = c.c_codigo and coalesce(c_contrato, 0) > 0 group by c_codcf ) ccon on ccon.c_codcf = c.c_codigo ` : ''}
                        where coalesce(pago.c_saldo,0)+coalesce(pendente.c_saldo,0)+coalesce(vencido.c_saldo,0) > 0
                    ) as con1
                                    
                    ${where}
                    order by ${order}               
                `,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: "" 
            })
    
            resolve({
                data: result?.data?.data,
                page: result?.data?.page-1,
                totalCount: result?.data?.total
            });
        })   
    }
    addVersao() {
    }
    removerVersao(idversao: number) {
        this.showConfirm("Confirma a remoção ?", async () => {
            let r: IControllerResult = await NocRequest.post("noc/delversao", {
                linhaproduto: this.props.linhaproduto,
                idversao
            });
            if (this.isOk(r)) {
                this.showAlert("Versão removida com sucesso.");
            }
            else
                this.showAlert(this.getMessage(r));
    
        });
    }
    removerVersao1() {

    }
    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    // actions={[
                    //     {
                    //         icon: <Clear />,
                    //         onClick: () => {

                    //         },
                    //         // tooltip: "Inativar Serviços"
                    //     },
                    //     {
                    //         icon: <AddCircleIcon />,
                    //         onClick: () => {

                    //         },
                    //         // tooltip: "Ativar Serviços"
                    //     },
                        
                    // ]}
                    actionBarFreeWidth={2}
                    actionsColumns={2}
                    actionColumnWidth={25}
                    columns={[
                        { title: "Código", field: "codigo" },
                        { title: "CPF/CNPJ", field: "cpfcnpj" },
                        { title: "Cliente", field: "cliente" },
                        { title: "Prim. Venc.", field: "c_dtvenc", type: 'date', render: (data: any) => {
                            if ( (data.c_dtvenc == null) || (data.c_dtvenc == undefined) )
                                return (row: any) => "";
                            return Formatter.date("c_dtvenc");
                         } },
                        { title: "Vencido R$", field: "vencido", type: 'numeric', render: Formatter.moeda("vencido", 2) },
                        { title: "Pendente R$", field: "pendente", type: 'numeric', render: Formatter.moeda("pendente", 2) },
                        { title: "Pago R$", field: "pago", type: 'numeric', render: Formatter.moeda("pago", 2) },
                        { title: "Licença", field: "licencaativa" },
                    ]}
                    fieldsSelect={[
                        "codigo", "cpfcnpj", "cliente"
                    ]}
                    data={this.consulta}
                    orderByField="pendente"
                    orderByDirection="desc"
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     minBodyHeight: 'calc(100vh - 200px)',
                    //     maxBodyHeight: 'calc(100vh - 200px)',
                    //     pageSize: 30,
                    //     pageSizeOptions: [9, 20, 30, 50, 100],
                    //     search: true,
                    // }}
                    // search2={true}
                    search={true}
                    pageSize={30}
                    // onSearchChange={(txt: string) => { console.log(txt); }}
                    // onQueryChange={() => { console.log('query changed') }}

                    title="Clientes Consolidado"
                    // onRowClick={this.rowClick/*RowClick*/}
                    // localization={{
                    //     header: {
                    //         actions: ""
                    //     }
                    // }}
                    
                />
                {this.baseRender()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
        SetLinhaProduto: (linhaproduto: string) => dispatch(SetLinhaProduto(linhaproduto)),
    };
}

export default connect(mapStateToProps)(Clientes);