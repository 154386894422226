import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { SetLinhaProduto } from "../../reducer/ActionsCreator";
import { Store } from "../../reducer/Reducer";
import Component2, { EmptyBaseState } from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import { withRouter } from "react-router-dom";
import Tabela2, { ITableQuery } from "../Tabela/Tabela2";
import GroupIcon from '@material-ui/icons/Group';
import GroupOutlined from '@material-ui/icons/GroupOutlined';

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate: AppState;
    linhaproduto: string;
    SetLinhaProduto?: (linhaproduto: string) => void;
}
interface IState {
    tipo: "ativos" | "inativos" | "todos";
}

class GruposEconomicos extends PrivateComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.removerUsuario = this.removerUsuario.bind(this);
        this.state = {
            ...EmptyBaseState,
            tipo: "ativos",
        };
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    async consulta(query: ITableQuery) { 
        let order = (query?.orderBy?.field || 'c_codigo') + ' ' + (query?.orderDirection || 'asc');
        let search: string = query?.search || '';
        let field: string = query?.field || '';
        let where: string = "";
        where = field == "" ? `
            where  false
            or upper(c_codigo::text) like '%${search.toUpperCase()}%'
            or upper(c_descricao::text) like '%${search.toUpperCase()}%'
            or upper(c_cgccpf::text) like '%${search.toUpperCase()}%'
        ` : `
            where  upper(${field}::text) like '%${search.toUpperCase()}%'
        `;

        return new Promise<any>(async(resolve, reject) => {
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql: `
                    select * from (
                        select 
                            ge.*, ag.c_codcli , ag.c_cgccpf , cli.c_cliente,
                            case nl.ativo
                                when false then 0
                                when true  then 1
                                else 2
                            end::int as ativo
                        from  a_grupoeconomico ge
                        left join a_grupoeconomicodet ag on ag.c_idgrupo = ge.c_codigo 
                        left join a_client cli on cli.c_Codigo = ag.c_codcli 
                        left join nfes_licencas nl on nl.cnpj = ag.c_cgccpf 
                        order by ge.c_codigo , ge.c_descricao 
                    ) as con
                    ${where}
                    order by ${order}               
                `,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: ""
            })
            
    
            resolve({
                data: result?.data?.data,
                page: result?.data?.page-1,
                totalCount: result?.data?.total
            });
        })   
    }
    async removerUsuario(usuario: any) {
        this.showConfirm(`Tem certeza que deseja remover o usuário ${usuario.usuario} ?`, async () => {
            let result: any = await axios.post(WS.URL_NOC + "entity/delete/Usuarios", usuario);
            this.showAlert("Usuário removido com sucesso.");
            this.addRefreshCount();
        });
    }
    renderPrivate() {
        
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // actions={[
                    //     {
                    //         icon: <GroupIcon/>,
                    //         isFreeAction: true,
                    //         tooltip: "Usuários Ativos",
                    //         onClick: (data: any) => {
                    //             this.estado({tipo: "ativos", refreshCount: this.state.refreshCount+1})
                    //         }
                    //     },
                    //     {
                    //         icon: <GroupOutlined/>,
                    //         isFreeAction: true,
                    //         tooltip: "Usuários Inativos",
                    //         onClick: (data: any) => {
                    //             this.estado({tipo: "inativos", refreshCount: this.state.refreshCount+1})
                    //         }
                    //     },
                    //     {
                    //         icon: <AddCircleIcon/>,
                    //         isFreeAction: true,
                    //         tooltip: "Adicionar um novo usuário",
                    //         onClick: (data: any) => {
                    //             //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                    //             this.redirectTo("/addusuario/new");
                    //         }
                    //     },
                    //     {
                    //         icon: <DeleteOutline/>,
                    //         //isFreeAction: false,
                    //         tooltip: "Remover Usuário",
                    //         onClick: (data: any) => {
                    //             //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                    //             this.removerUsuario(data);
                    //         }
                    //     },
                    //     {
                    //         icon: <Edit />,
                    //         //isFreeAction: false,
                    //         //tooltip: "Remover versão",
                    //         onClick: (data: any) => {
                    //             //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                    //             this.redirectTo(`/addusuario/edit/${data.usuario}`);
                    //         }
                    //     },
                    // ]}
                    actionBarFreeWidth={3}
                    actionsColumns={3}
                    actionColumnWidth={25}
                    columns={[
                        { title: "Código", field: "c_codigo" },
                        { title: "Descrição", field: "c_descricao" },
                        { title: "CPF/CNPJ", field: "c_cgccpf" },
                        { title: "Cód.Cliente", field: "c_codcli" },
                        { title: "Cliente", field: "c_cliente" },
                        { title: "Licença", field: "ativo", render: (data: any) => (data.ativo === 0 ? 'Inativa' : (data.ativo === 1 ? 'Ativa' : 'Sem licença')) },
                        { title: "Ativo NOC", field: "ativanoc", render: (data: any) => (data.ativanoc === 1 ? 'Sim' : '') },
                    ]}
                    fieldsSelect={[
                        "c_codigo", "c_descricao", "c_cgccpf", "c_codcli", "c_cliente"
                    ]}
                    data={this.consulta}
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     minBodyHeight: 'calc(100vh - 200px)',
                    //     maxBodyHeight: 'calc(100vh - 200px)',
                    //     pageSize: 30,
                    //     pageSizeOptions: [9, 20, 30, 50, 100],
                    //     search: true,
                    // }}
                    // search2={true}
                    search={true}
                    pageSize={30}
                    refreshCount={this.state.refreshCount}
                    // onSearchChange={(txt: string) => { console.log(txt); }}
                    // onQueryChange={() => { console.log('query changed') }}

                    title={"Grupos Econômicos"}
                    subtitle={`Listagem de grupos econômicos e seus participantes`}
                    // localization={{
                    //     header: {
                    //         actions: ""
                    //     }
                    // }}
                />
                {this.baseRender()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state, ...ownProps};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
        SetLinhaProduto: (linhaproduto: string) => dispatch(SetLinhaProduto(linhaproduto)),
    };
}

export default  connect(mapStateToProps)(GruposEconomicos);

