import React, { Component, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Route, Redirect } from "react-router-dom";
import SemAcesso from "../Login/SemAcesso";
import DadosKeycloak from "../../secure/keycloak"
import keycloak from "../../secure/keycloak";

export function KeycloakPrivateRoute({roles, ...rest}) {
  //const { component, roles, ...rest } = props;
  // const {keycloak, initialized} = useKeycloak();


// console.log(keycloak.sessionId);

  const [cnt, setCnt] = useState(0);

  // useEffect(() => {
  //   setTimeout(() => setCnt(cnt+1), 1000);
  // }, [initialized])

  const isAutherized = (roles: any[]) => {
    if (keycloak && roles) {
      return roles.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  const isprivate = (roles ?? []).length > 0;
  const ok = ((roles ?? []).length == 0) || ((keycloak.authenticated === true) && (roles && roles.length > 0) && (isAutherized(roles)));
  // console.log(isprivate, keycloak.authenticated, keycloak.userInfo)

  /*if (!initialized) {
    //keycloak.init(DadosKeycloak);
    // setTimeout(() => setCnt(cnt+1), 1000);
    return <div>inicializando contexto de segurança</div>
  }
  else */if (isprivate && ! keycloak.authenticated) {
    keycloak.login({
      prompt: "login",
      redirectUri: "http://localhost:3000/"
    }).then(() => {
      keycloak.loadUserInfo();
    });
    return <div>login</div>;
  }
  else if (isprivate && ! isAutherized(roles)) {
    return <SemAcesso></SemAcesso>;
  }
  else {
    return (
      <Route
        {...rest}
        /*render={(props) => {
          return isAutherized(roles) ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          );
        }}*/
      />
    );
  }
}

export default KeycloakPrivateRoute;