import { ActionLogin, ACTION_LOGIN, ActionLogout, ACTION_LOGOUT, ActionLoginOk, ACTION_LOGINOK, ACTION_SETLINHAPRODUTO, ACTION_GETTIPOSSISTEMAS, ACTION_FETCHTIPOSSISTEMAS, ACTION_GETSPRINTS, ACTION_FETCHSPRINTS, ACTION_SETCLIENTELIBERACAO, ActionSetMainColor, ACTION_SETMAINCOLOR, ACTION_GETCONFIGURACOES, ACTION_FETCHCONFIGURACOES } from "./Actions";
import { ITiposistema } from "../persistence/interfaces/ITiposistema";
import { ISprint } from "../persistence/interfaces/ISprint";
import { IClienteLiberacao } from "./ReducerTypes";
import { IConfiguracoes } from "../persistence/interfaces/IConfiguracoes";

export function Login(login: string, password: string): ActionLogin {
    return {
        type: ACTION_LOGIN,
        login, password
    }
}
export function LoginOk(login: string, password: string, gruposUsuario: string[]): ActionLoginOk {
    return {
        type: ACTION_LOGINOK,
        login, password, gruposUsuario
    }
}

export function Logout(): ActionLogout {
    return {
        type: ACTION_LOGOUT,
    }
}
export function SetLinhaProduto(linhaproduto: string) {
    return {
        type: ACTION_SETLINHAPRODUTO,
        linhaproduto
    }
}
export function GetTiposSistemas() {
    return {
        type: ACTION_GETTIPOSSISTEMAS,
    }
}
export function FetchTiposSistemas(tipossistemas: ITiposistema[]) {
    return {
        type: ACTION_FETCHTIPOSSISTEMAS,
        tipossistemas
    }
}

export function GetSprints() {
    return {
        type: ACTION_GETSPRINTS,
    }
}
export function FetchSprints(sprints: ISprint[]) {
    return {
        type: ACTION_FETCHSPRINTS,
        sprints
    }
}
export function SetClienteLiberacao(clienteliberacao: IClienteLiberacao) {
    return {
        type: ACTION_SETCLIENTELIBERACAO,
        clienteliberacao,
    }
}

export class ActionCreator {
    static SetMainColor(cor: string): ActionSetMainColor {
        return {
            type: ACTION_SETMAINCOLOR,
            cor
        }
    }
}

export function GetConfiguracoes() {
    return {
        type: ACTION_GETCONFIGURACOES,
    }
}
export function FetchConfiguracoes(configuracoes: IConfiguracoes[]) {
    return {
        type: ACTION_FETCHCONFIGURACOES,
        configuracoes,
    }
}