import React, { Component, forwardRef } from "react";
import MaterialTable, { Column } from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, Table, TableHead, TableRow, TableBody, TableCell, withStyles, createStyles, Theme } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { SetLinhaProduto } from "../../reducer/ActionsCreator";
import { Store } from "../../reducer/Reducer";
import Component2, { EmptyBaseState } from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import Formatter from "../../utils/Formatters";
import SubjectIcon from '@material-ui/icons/Subject';
import { withRouter } from "react-router-dom";
import Tabela2, { ITableColumn } from "../Tabela/Tabela2";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
    Subject: forwardRef((props, ref:any) => <SubjectIcon {...props} ref={ref} />),
};

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

interface IProps {
    appstate?: AppState;
    linhaproduto: string;
    SetLinhaProduto?: (linhaproduto: string) => void;
}
interface IDetailRender {
    [Name: string]: any;
}
interface IState {
    modelo: number;
    detailRender: any[];
}
const sistema = (field: string) => (data: any) => {
    let c = data[field];
    return (c == -1 ? <span title={data['vc_'+field] ? "Versão "+data['vc_'+field] : "Versão não informada"} style={{color: 'red'}}>Sim</span> : (c == 1 ? "" : 
                      <span title={data['vc_'+field] ? "Versão "+data['vc_'+field] : "Versão não informada"} style={{color: 'green'}}>Não</span>))
}
class ProximasAtualizacoes extends PrivateComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.getExtraInfo = this.getExtraInfo.bind(this);
        this.state = {
            ...EmptyBaseState,
            modelo: 1,
            detailRender: [],
        }
    }
    componentDidMountPrivate() {
        this.getExtraInfo();
    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    modelo() {
        //this.estado({modelo: this.state.modelo+1 > 2 ? 1 : this.state.modelo+1, refreshCount: this.state.refreshCount+1});
        this.addRefreshCount({modelo: this.state.modelo+1 > 2 ? 1 : this.state.modelo+1});
    }
    consulta(query: any) {
        let order = (query?.orderBy?.field || 'idcliente') + ' ' + (query?.orderDirection || '');
        let search: string = query?.search || '';
        let field: string = query?.field || '';
        let where: string = "";
        where = field == "" ? `
            where  false
            or cnpj::text like '%${search.toUpperCase()}%'
            or idcliente::text like '%${search.toUpperCase()}%'
            or nomecliente::text like '%${search.toUpperCase()}%'
        ` : `
            where  ${field}::text like '%${search.toUpperCase()}%'
        `;
    
        let Sql = "";
        if ([1, 3].indexOf(this.state.modelo) >= 0) {
            Sql = `
                select * from (
                    select idcliente, cnpj, nomecliente, idversao, idsprint, codigosistema, nomesistema, versao, count(*) as tarefas, max(dataliberacao) as dataliberacao from view_proximasatualizacoes
                    where linhaproduto = '${this.props.linhaproduto}' 
                    group by idcliente, cnpj, nomecliente, idversao, idsprint, codigosistema, nomesistema, versao
                ) as con2
                ${where}
                order by ${order}          
            `;
        }
        else {
            Sql = `
                select * from (
                    select idcliente, cnpj, nomecliente, idversao, idsprint, count(*) as tarefas, max(dataliberacao) as dataliberacao from view_proximasatualizacoes
                    where linhaproduto = '${this.props.linhaproduto}' 
                    group by idcliente, cnpj, nomecliente, idversao, idsprint
                ) as con2
                ${where}
                order by ${order}          
            `;
        }

        return new Promise<any>(async(resolve, reject) => {
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql: Sql,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: ""
            })
    
            resolve({
                data: result?.data?.data,
                page: result?.data?.page-1,
                totalCount: result?.data?.total
            });
        })   
    }
    async getExtraInfo() {
        let Sql2 = `
            select idcliente, codigosistema, idsprint, idorservico, iddetalhe, descricaoos, detalheos
            from view_proximasatualizacoes
            where true
                and linhaproduto = '${this.props.linhaproduto}' 
            order by idorservico
        `;
        let result2: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
            sql: Sql2,
            page: 1,
            pageSize: 20000,
            search: "",
            orderBy: ""
        });
        this.estado({detailRender: result2?.data?.data}); 
    }
    getModeloColumns(): ITableColumn[] {
        let r: ITableColumn[] = [];
        if ([1, 3].indexOf(this.state.modelo) >= 0) {
            r = [
                { title: "Id.Cliente", field: "idcliente" },
                { title: "CNPJ", field: "cnpj" },
                { title: "Cliente", field: "nomecliente" },
                { title: "ID", field: "idversao" },
                { title: "Sprint", field: "idsprint" },
                { title: "Sistema", field: "nomesistema" },
                { title: "Versão", field: "versao" },
                { title: "Tarefas", field: "tarefas" },
                { title: "Liberação", field: "dataliberacao", render: Formatter.date("dataliberacao", "d-m-Y") },
            ];
        }
        else {
            r = [
                { title: "Id.Cliente", field: "idcliente" },
                { title: "CNPJ", field: "cnpj" },
                { title: "Cliente", field: "nomecliente" },
                { title: "ID", field: "idversao" },
                { title: "Sprint", field: "idsprint" },
                //{ title: "Sistema", field: "nomesistema" },
                //{ title: "Versão", field: "versao" },
                { title: "Tarefas", field: "tarefas" },
                { title: "Liberação", field: "dataliberacao", render: Formatter.date("dataliberacao", "d-m-Y") },
            ];
        }
        return r;
    }
    getModeloTitle(): string {
        let r: string = "";
        if (this.state.modelo == 1) {
            r = "por Cliente/Sistema ";
        }
        else if (this.state.modelo == 2) {
            r = "por Cliente ";
        }
        else if (this.state.modelo == 3) {
            r = "por Cliente/Tarefas ";
        }
        return r;
    }
    renderPrivate() {
        if (this.state.refreshCount != this.state.refreshCountOld) {

        }
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    actions={[
                        {
                            isFreeAction: true,
                            icon: <SubjectIcon/>,
                            //position: "row",
                            onClick: (data: any) => {
                                this.modelo();
                            }
                        },
                    ]}
                    actionBarFreeWidth={2}
                    actionColumnWidth={25}
                    columns={this.props.linhaproduto == "linha1" ? this.getModeloColumns(): this.getModeloColumns()}
                    fieldsSelect={[
                        "cnpj", "nomecliente", "idcliente"
                    ]}
                    data={this.consulta}
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     minBodyHeight: 'calc(100vh - 200px)',
                    //     maxBodyHeight: 'calc(100vh - 200px)',
                    //     pageSize: 30,
                    //     pageSizeOptions: [9, 20, 30, 50, 100],
                    //     search: true,
                    // }}
                    // search2={true}
                    search={true}
                    pageSize={20}
                    refreshCount={this.state.refreshCount}
                    // onSearchChange={(txt: string) => { console.log(txt); }}
                    // onQueryChange={() => { console.log('query changed') }}

                    title={"Próximas Atualizações " + this.getModeloTitle() + (this.props.linhaproduto == "linha1" ? "Linha 1" : "Linha 2")}
                    // onRowClick={this.rowClick/*RowClick*/}
                    // localization={{
                    //     header: {
                    //         actions: ""
                    //     }
                    // }}
                    // detailPanel={[1, 2, 3].indexOf(this.state.modelo) == -1 ? null as any : (data: any) => this.getDetailRender(data)}
                    detail={[1, 2, 3].indexOf(this.state.modelo) == -1 ? null as any : (data: any) => this.getDetailRender(data)}
                    //onTreeExpandChange={(data: any) => this.getDetail(data)}
                    
                />
                {this.baseRender()}
            </div>
        );
    }
    getDetailRender(data: any) {
        if (this.state.detailRender.length > 0) {
            return <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell size="small">&nbsp;</StyledTableCell>
                            <StyledTableCell size="small">O.S.</StyledTableCell>
                            <StyledTableCell size="small">Descrição</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.detailRender.map((rowData: any, index: number) => {
                            if (
                                (([1, 3].indexOf(this.state.modelo) >= 0) && (rowData.codigosistema == data.codigosistema) && (rowData.idsprint == data.idsprint) && (rowData.idcliente == data.idcliente)) ||
                                (([2].indexOf(this.state.modelo) >= 0) && (rowData.idcliente == data.idcliente))
                            )
                                return ([
                                    <TableRow>
                                        <TableCell size="small" className="green">&nbsp;</TableCell>
                                        <TableCell size="small" className="green">{rowData.idorservico}</TableCell>
                                        <TableCell size="small" className="green">{rowData.descricaoos}</TableCell>
                                    </TableRow>,
                                    <TableRow>
                                        <TableCell size="small" className="green">&nbsp;</TableCell>
                                        <TableCell size="small" className="green">&nbsp;</TableCell>
                                        <TableCell size="small" className="green">{rowData.detalheos}</TableCell>
                                    </TableRow>
                                ]);
                        })}

                    </TableBody>
                </Table>
            </div>;

        }
        else {
            return <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell size="small">&nbsp;</StyledTableCell>
                            <StyledTableCell size="small">O.S.</StyledTableCell>
                            <StyledTableCell size="small">Descrição</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                                <TableCell size="small" colSpan={3}>aguardando a carga dos dados</TableCell>
                                
                        </TableRow>
                    </TableBody>
                </Table>
            </div>;
        }
    }

}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
        SetLinhaProduto: (linhaproduto: string) => dispatch(SetLinhaProduto(linhaproduto)),
    };
}

const CProximasAtualizacoes = connect(mapStateToProps)(ProximasAtualizacoes);
export default CProximasAtualizacoes;
export const ProximasAtualizacoesLinha1 = () => <CProximasAtualizacoes linhaproduto="linha1"/>;
export const ProximasAtualizacoesLinha2 = () => <CProximasAtualizacoes linhaproduto="linha2"/>;
