import React, {Component} from "react"
import { TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Input, InputProps } from "@material-ui/core";
import "./EditBox.css";
import Key from "../../utils/Keys";

interface IState {
    valor: string;
    startSel: number;
}
interface IProps extends InputProps {
    /**
     * Máscara utilizada para o campo
     * Tipos:
     *   9 - aceita apenas caracters númericos
     *   A - aceita apenas caracters alfabéticos
     *   b - aceita apenas caracters alfabéticos na forma minuscula
     *   B - aceita apenas caracters alfabéticos na forma maiuscula
     *   S - aceita apenas caracters alfanuméricos 
     *   t - aceita apenas caracters alfanuméricos na forma minuscula
     *   T - aceita apenas caracters alfanuméricos na forma maiuscula
     */
    mask: string;
    maskFillChar?: string;
    saveLiteralChar?: boolean;
    forwardedRef?: React.RefObject<any>;
    value2?: any;
    dataChanged: (name: string, value: string, value2: string) => void;
}

class EditBoxBase extends Component<IProps, IState> {
    ref:  React.RefObject<HTMLInputElement>;
    constructor(props: any) {
        super(props);
        this.onchange = this.onchange.bind(this);
        this.onkeydown = this.onkeydown.bind(this);
        this.onkeyup = this.onkeyup.bind(this);
        this.onblur = this.onblur.bind(this);
        this.onfocus = this.onfocus.bind(this);
        this.applymask = this.applymask.bind(this);
        this.selectCapture = this.selectCapture.bind(this);
        let mask = this.props.mask || '';
        let v: any = (this.props?.value || '');
        this.state = {
            valor: mask==""?v:(v==""?this.emptyMask():this.getmasked(v)),
            startSel: 0,
        };
        this.ref = React.createRef();
    }
    dostate(state: any, CB?: Function) {
        //state.valor = this.getunmasked(state.valor);
        this.setState(state, () => {
            let mask = this.props.mask || '';
            if (mask != '') {
                this.props?.forwardedRef?.current?.setSelectionRange(this.state.startSel, this.state.startSel);
                if (this.props?.dataChanged) {
                    let v1 = (this.props.saveLiteralChar === true) ? this.state.valor : this.removefillchar(this.getunmasked(this.state.valor));
                    let v2 = (this.props.saveLiteralChar === true) ? this.removefillchar(this.getunmasked(this.state.valor)) : this.state.valor;
                    this.props?.dataChanged(this.props?.name||'', v1, v2);
                }
            }
            else {
                if (this.props?.dataChanged) {
                    this.props?.dataChanged(this.props?.name||'', this.state.valor, this.state.valor);
                }
            }

            if (CB) CB();
        });
    }
    maskChar(index: number) {
        let mask = this.props.mask || '';
        return (mask!='') && ('9aASbBtT*'.indexOf(mask.charAt(index)) >= 0);
    }
    getMaskChar(index: number) {
        let mask = this.props.mask || '';
        return mask.charAt(index);
    }
    maskCharPos(index: number) {
        return this.maskChar(index) ? index : index+1;
    }
    valorCharPos(index: number) {
        index = this.maskChar(index) ? index : index-1;
        return index-1 < 0 ? 0 : index-1;
    }
    ajustPosition(pos: number, setState: boolean = true) {
        let mask = this.props.mask || '';
        if (pos != this.state.startSel) {
            while ((pos <= mask.length-1) && (! this.maskChar(pos)) ) {
                pos++;
            }
            if (setState)
                this.dostate({startSel: pos})
        }
        return pos;
    }
    getmasked(value: string = '') {
        let r: string = '';
        let valor: string = value != '' ? value : this.state.valor;
        let mask = this.props.mask || '';
        if (mask != '') {
            let c1 = -1;
            for (let c = 0; c <= mask.length-1; c++) {
                r += this.maskChar(c) ? (valor[++c1] || (this.props.maskFillChar || ' ')) : mask[c]; 
            }
        }
        return r;
    }
    getunmasked(v: any) {
        let r: string = '';
        let valor: string = v || this.state.valor;
        let mask = this.props.mask || '';
        if (mask != '') {
            let c1 = -1;
            for (let c = 0; c <= mask.length-1; c++) {
                r += this.maskChar(c) ? (valor[c] || '') : ''; 
            }
        }
        return r;
    }
    removefillchar(v: string) {
        while (v.indexOf(this.props?.maskFillChar || ' ') >= 0)
            v = v.replace(this.props?.maskFillChar || ' ', '');
        return v;
    }
    remask(v: string) {
        return this.getmasked(this.getunmasked(v));
    }
    applymask(value: string = '', startSel: number = 0) {
        let r: string = '';
        let valor: string = value != '' ? value : this.state.valor;
        let mask = this.props.mask || '';
        if (mask != '') {
            let c1 = -1;
            for (let c = 0; c <= mask.length-1; c++) {
                r += this.maskChar(c) ? (valor[++c1] || (this.props.maskFillChar || ' ')) : mask[c]; 
            }
            //this.dostate({valor: r, startSel});
        }
    }
    emptyMask(value: string = '') {
        let r: string = '';
        let valor: string = value;
        let mask = this.props.mask || '';
        if (mask != '') {
            let c1 = -1;
            for (let c = 0; c <= mask.length-1; c++) {
                r += this.maskChar(c) ? (valor[++c1] || (this.props.maskFillChar || ' ')) : mask[c]; 
            }
        }
        return r;
    }
    maskLength() {

    }
    onchange(e: any) {
        let {name, value} = e.target;
        let mask = this.props.mask || '';
        if (mask == "") {
            this.dostate({valor: value, startSel: 0})
        }

        if (this?.props?.onChange) {
            this.props.onChange(e);
        }
    }
    onkeydown(e: React.KeyboardEvent) {
        let {name, value} = e.target as any;
        let k = e.which;
        let mask = this.props.mask || '';
        let start = this.state.startSel;
        let v: string = this.remask(this.state.valor);
        
        if (mask != '') {
            if (Key.isEspecial(k))
            {
                if ((k == Key.VK_BACK_SPACE) && (start > 0)) {
                    if (! this.maskChar(start-1)) start -= 1;
                    v = v.substr(0, start-1) + (this.props.maskFillChar || ' ') + v.substr(start);
                    v = v.substr(0, mask.length);
                    this.dostate({valor: v, startSel: this.ajustPosition(start-1, false)})
                    e.preventDefault();
                    e.stopPropagation();
                }
                else if ((k == Key.VK_LEFT) && (start > 0)) {
                    this.dostate({startSel: this.ajustPosition(start - (! this.maskChar(start-1) ? 2 : 1), false)})
                    e.preventDefault();
                    e.stopPropagation();
                }
                else if (k == Key.VK_DELETE) {
                    e.preventDefault();
                    e.stopPropagation();
                    
                }
            }
            else {
                switch (this.getMaskChar(start)) {
                    case '9': {
                        if (Key.isNumeric(e.keyCode) && (start <= v.length-1)) {
                            v = v.substr(0, start) + e.key.charAt(0) + v.substr(start+1);
                            v = v.substr(0, mask.length);
                            this.dostate({valor: v, startSel: this.ajustPosition(start+1, false)})
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        else {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        break;
                    }
                    case 'A':
                    case 'b':
                    case 'B': {
                        let cK = e.key.charAt(0);
                        if (this.getMaskChar(start) == 'b') cK = cK.toLowerCase();
                        if (this.getMaskChar(start) == 'B') cK = cK.toUpperCase();
                        if (Key.isAlpha(e.keyCode) && (start <= v.length-1)) {
                            v = v.substr(0, start) + cK + v.substr(start+1);
                            v = v.substr(0, mask.length);
                            this.dostate({valor: v, startSel: this.ajustPosition(start+1, false)})
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        else {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        break;
                    }
                    case 'S':
                    case 't':
                    case 'T': {
                        let cK = e.key.charAt(0);
                        if (this.getMaskChar(start) == 't') cK = cK.toLowerCase();
                        if (this.getMaskChar(start) == 'T') cK = cK.toUpperCase();
                        if (Key.isAlphaNumeric(e.keyCode) && (start <= v.length-1)) {
                            v = v.substr(0, start) + cK + v.substr(start+1);
                            v = v.substr(0, mask.length);
                            this.dostate({valor: v, startSel: this.ajustPosition(start+1, false)})
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        else {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        break;
                    }
                    case '*': {
                        e.preventDefault();
                        e.stopPropagation();
                        break;
                    }
                    default: {
                        e.preventDefault();
                        e.stopPropagation();
                        break;
                    }
                }
            }
        }

        if (this?.props?.onKeyDown) {
            this.props.onKeyDown(e as any);
        }
    }
    onkeyup(e: React.KeyboardEvent) {
    }
    selectCapture(e: any) {
        let {name, value} = e.target;
        let mask = this.props.mask || '';
        let start = this.props?.forwardedRef?.current?.selectionStart;
        this.ajustPosition(start);
    }
    onblur(e: any) {
        let {name, value} = e.target;
        let sv: string = `${value}`;

        if (this?.props?.onBlur) {
            this.props.onBlur(e);
        }
    }
    onfocus(e: any) {
        this.dostate({startSel: 0})
        //this.props?.forwardedRef?.current?.setSelectionRange(this.state.startSel, this.state.startSel);
        if (this?.props?.onFocus) {
            this.props.onFocus(e);
        }
    }
    render() {
        let mask = this.props.mask || '';
        let p: any = {...this.props};
        //p.autoFocus = this.props.autoFocus === true;

        return <Input {...this.props} 
                    // autoFocus={this.props.autoFocus === true}
                    ref={this.ref}
                    inputRef={this.props.forwardedRef}
                    value={mask == "" ? this.props.value : this.remask(this.state.valor)}
                    onChange={this.onchange}
                    onKeyDown={this.onkeydown}
                    onKeyUp={this.onkeyup}
                    onBlur={this.onblur}
                    onFocus={this.onfocus}
                    disabled={this.props.disabled || false}
                    onSelectCapture={this.selectCapture}
                    className="EditBox"
                    //ref={this.props.forwardedref2}
                />
    }
}

const EditBox = React.forwardRef((props: any, ref) => {
    if (! ref) {
        ref = React.createRef();
    }
    return <EditBoxBase {...props} forwardedRef={ref} />;
});

export default EditBox;