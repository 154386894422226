import React, { Component } from "react";
import PrivateComponent from "../Component2/PrivateComponent";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";

export interface IProps {

}

class AgenteSuporte extends Component<IProps, any> {
    constructor(props: any) {
        super(props);
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    
    render() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                Agente suporte
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
    };
}

export default connect(mapStateToProps, mapDisptachToProps)(AgenteSuporte);