import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "../../assets/jss/material-dashboard-react/components/cardHeaderStyle";
import { connect } from "react-redux";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
});

const getColor = (cor) => {
  switch (cor) {
    case "purple": {
      return "primary";
    }
    case "blue": {
      return "info";
    }
    case "green": {
      return "success";
    }
    case "orange": {
      return "rose";
    }
    case "red": {
      return "danger";
    }
    default: {
      return "info";
    } 
  }
  
}
function CardHeader(props) {
  const classes = useStyles();
  const classes2 = useStyles2();
  let { className, children, color, plain, stats, icon, title, subtitle, actionBar, actionBarWidth, searchBar, searchBarWidth, ...rest } = props;
  if (color == "")
    color = getColor(props.state.dados.mainColor)
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + "CardHeader"]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [className]: className !== undefined
  });
  let wid = 12 - (actionBarWidth||0) - (searchBarWidth||0);
  return (
    <div className={cardHeaderClasses} {...rest}>
      <GridContainer>
        <GridItem xs={wid} sm={wid} md={wid}>
          {title ? <h4 className={classes2.cardTitleWhite}>{title}</h4> : null}
          {subtitle ? <p className={classes2.cardCategoryWhite}>{subtitle}</p> : null}

        </GridItem>
        
        {(searchBarWidth||0) > 0 ? <GridItem xs={searchBarWidth} sm={searchBarWidth} md={searchBarWidth}>
          {searchBar}
        </GridItem> : null}
        {(actionBarWidth||0) > 0 ? <GridItem xs={actionBarWidth} sm={actionBarWidth} md={actionBarWidth}>
          {actionBar}
        </GridItem> : null}
      </GridContainer>
      {children}
    </div>
  );
}

CardHeader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  plain: PropTypes.bool,
  stats: PropTypes.bool,
  icon: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actionBarWidth: PropTypes.number,
  actionBar: PropTypes.node,
  searchBarWidth: PropTypes.number,
  searchBar: PropTypes.node,
};

const mapStateToProps = (state, ownProps) => {
  return {
    state, ...ownProps
  }
}
export default connect(mapStateToProps)(CardHeader)