import React, { forwardRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/customInputStyle";

import { Tooltip } from '@material-ui/core';
const interrogation = require("../../../resources/interrogation.png");

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    helpText,
    id,
    labelProps,
    inputProps,
    inputProps2,
    error,
    success,
    ref,
    inputRef,
    name,
    value,
    onChange,
    onKeyDown,
    type,
    disabled,
    required,
    onlynumbers,
    multiline,
    multilineRows,
  } = props;

  const doKeyDown = (e) => {
    let isNumber = [
      48,49,50,51,52,53,54,55,56,57,
      96,97,98,99,100,101,102,103,104,105,
      8,
      37,38,39,40,
      35,36,46,
      9,13,
    ].indexOf(e.keyCode) != -1; 
    //console.log(e.charCode, e.keyCode, onlynumbers && ! isNumber)
    if (onlynumbers && ! isNumber) {
      e.preventDefault();
      return;
    }
    if (onKeyDown)
      onKeyDown(e);
  }

  let [focused, setFocused] = useState(false);
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText+" "}
          {((value != "" && value != undefined) || focused) && helpText !== undefined && helpText !== "" ? <Tooltip title={helpText}><img src={interrogation} /></Tooltip> : null}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        {...inputProps}
        inputRef={inputRef}
        name={name}
        value={value}
        onChange={onChange}
        onKeyDown={doKeyDown}
        type={type}
        disabled={disabled || false}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        inputProps={{...inputProps2}}
        multiline={multiline || false}
        rows={multilineRows || 0}
      />
      {required && (value == "" || value == undefined || value == null) ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : null}
      {/* {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null} */}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  helpText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  inputProps2: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  ref: PropTypes.any,
  inputRef: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.any,
  onKeyDown: PropTypes.any,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onlynumbers: PropTypes.bool,
  multiline: PropTypes.bool,
  multilineRows: PropTypes.number,
};
