import { ITiposistema } from "../persistence/interfaces/ITiposistema";
import { ISprint } from "../persistence/interfaces/ISprint";
import { IConfiguracoes } from "../persistence/interfaces/IConfiguracoes";
import { IGruposUsuario } from "../utils/SecTypes";

export interface AppState {
    login: {
        logged: boolean;
        login: string;
        password: string;
        gruposUsuario: IGruposUsuario[];
    },
    dados: {
        mainColor: string;
        linhaproduto: string;
        tipossistemas: ITiposistema[];
        sprints: ISprint[];
        clienteliberacao: IClienteLiberacao;
        configuracoes: IConfiguracoes[];
    }
}

export interface IClienteLiberacao {
    codigo: string;
    nome: string;
    licenca: boolean;
}

export const emptyClienteLiberacao: IClienteLiberacao = {
    codigo: "",
    nome: "",
    licenca: false,
}