import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, Table, TableHead, TableRow, TableBody, TableCell, withStyles, Theme, createStyles } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import Tabela2 from "../Tabela/Tabela2";
import Formatter from "../../utils/Formatters";
import Utils from "../../utils/Utils";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddCliente: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

const consulta = (query: any) => {
    let order = (query?.orderBy?.field || 'quant') + ' ' + (query?.orderDirection || 'desc');
    let search: string = query?.search || '';
    let field: string = query?.field || '';
    let where: string = "";
    where = field == "" ? `
        where false
        or nome like '%${search.toUpperCase()}%'
        or codigo like '%${search.toUpperCase()}%'
        or quant::text like '%${search.toUpperCase()}%'
    ` : `
        where  ${field} like '%${search.toUpperCase()}%'
    `;
    
    return new Promise<any>(async(resolve, reject) => {
        let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
            sql: `
                select * from (
                    select * from (
                        SELECT 
                            codigo,
                            nome,
                            (select count(*) from chavenfeduplicadas where empresa=p.codigo and tipo=2 and data >= current_date-interval '200 month') as quant
                        FROM 
                            "public".empresas p
                        ) as con
                        where con.quant > 0
                        order by con.quant desc
                    ) as con
                    ${where}
                order by ${order}               
            `,
            page: query.page,
            pageSize: query.pageSize,
            search: query.search,
            orderBy: ""
        })


        resolve({
            data: result?.data?.data,
            page: result?.data?.page-1,
            totalCount: result?.data?.total
        });
    })   
}
interface IProps {
    selecao?: boolean;
    SetClienteVenda: (codigocliente: number, nome: string) => void;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

class ChavesDuplicadasNFe extends PrivateComponent<IProps, any> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    columns={[
                        { title: "Código", field: "codigo" },
                        { title: "Empresa", field: "nome" },
                        { title: "Quantidade", field: "quant" },
                    ]}
                    fieldsSelect={[
                        "codigo", "empresa", "quant"
                    ]}
                    data={consulta}
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     minBodyHeight: 'calc(100vh - 200px)',
                    //     maxBodyHeight: 'calc(100vh - 200px)',
                    //     pageSize: 30,
                    //     pageSizeOptions: [9, 20, 30, 50, 100],
                    //     search: true,
                    // }}
                    // search2={true}
                    search={true}
                    pageSize={30}
                    // onSearchChange={(txt: string) => { console.log(txt); }}
                    // onQueryChange={() => { console.log('query changed') }}

                    title="Chaves Duplicadas"
                    subtitle="Listagem de chaves de NF-e transmitidas por vários movimentos"
                    // onRowClick={this.rowClick/*RowClick*/}
                    // localization={{
                    //     header: {
                    //         actions: ""
                    //     }
                    // }}
                    detail={async (data: any) => {

                        let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                            sql: `
                            select * from chavenfeduplicadas 
                            where empresa='${data.codigo}' and tipo=2 and data >= current_date-interval '200 month' order by tipo, chave desc, data desc                                            
                            `,
                            page: 1,
                            pageSize: 20000,
                            search: "",
                            orderBy: ""
                        })
                        //console.log(result)
                        let r: any[];
                        
                        r = result?.data?.data.map((dados: any, index: number) => {
                            return <TableRow>
                                        <TableCell size="small" className="green" align="left">{dados.chave}</TableCell>
                                        <TableCell size="small" className="green" align="left">{dados.operacao} ({dados.movcom})</TableCell>
                                        <TableCell size="small" className="green" align="left">{dados.codcliente} - {dados.cliente}</TableCell>
                                        <TableCell size="small" className="green" align="left">{Utils.dateFormat2(dados.data, "d-m-Y")}</TableCell>
                                        <TableCell size="small" className="green" align="right">{dados.valor}</TableCell>
                                        <TableCell size="small" className="green" align="left">{dados.chavenfe}</TableCell>
                                    </TableRow>
                        })
                        return <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell size="small" align="left">Chave</StyledTableCell>
                                        <StyledTableCell size="small" align="left">Operação</StyledTableCell>
                                        <StyledTableCell size="small" align="left">Cliente</StyledTableCell>
                                        <StyledTableCell size="small" align="left">Data</StyledTableCell>
                                        <StyledTableCell size="small" align="right">Valor</StyledTableCell>
                                        <StyledTableCell size="small" align="left">Chave NFe</StyledTableCell>
                                    </TableRow>
                                </TableHead> 
                                <TableBody>
                                    {r}
                                </TableBody>
                            </Table>
                        </div>;

                    }}
                    
                />

            </div>
        );
    }
}


// const mapStateToProps = (state: any, ownProps: any) => {
//     return state;
// }
// const mapDisptachToProps = (dispatch: any, ownProps: any) => {
//     return {
//         //SetClienteVenda: (codigocliente: number, nome: string) => dispatch(SetClienteVenda(codigocliente, nome)),
//         ...ownProps,
//     };
// }

export default ChavesDuplicadasNFe;