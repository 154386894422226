class Key {
    static VK_CANCEL: number = 3;
    static VK_HELP: number = 6;
    static VK_BACK_SPACE: number = 8;
    static VK_TAB: number = 9;
    static VK_CLEAR: number = 12;
    static VK_RETURN: number = 13;
    static VK_ENTER: number = 14;
    static VK_SHIFT: number = 16;
    static VK_CONTROL: number = 17;
    static VK_ALT: number = 18;
    static VK_PAUSE: number = 19;
    static VK_CAPS_LOCK: number = 20;
    static VK_ESCAPE: number = 27;
    static VK_SPACE: number = 32;
    static VK_PAGE_UP: number = 33;
    static VK_PAGE_DOWN: number = 34;
    static VK_END: number = 35;
    static VK_HOME: number = 36;
    static VK_LEFT: number = 37;
    static VK_UP: number = 38;
    static VK_RIGHT: number = 39;
    static VK_DOWN: number = 40;
    static VK_PRINTSCREEN: number = 44;
    static VK_INSERT: number = 45;
    static VK_DELETE: number = 46;
    static VK_0: number = 48;
    static VK_1: number = 49;
    static VK_2: number = 50;
    static VK_3: number = 51;
    static VK_4: number = 52;
    static VK_5: number = 53;
    static VK_6: number = 54;
    static VK_7: number = 55;
    static VK_8: number = 56;
    static VK_9: number = 57;
    static VK_SEMICOLON: number = 59;
    static VK_EQUALS: number = 61;
    static VK_A: number = 65;
    static VK_B: number = 66;
    static VK_C: number = 67;
    static VK_D: number = 68;
    static VK_E: number = 69;
    static VK_F: number = 70;
    static VK_G: number = 71;
    static VK_H: number = 72;
    static VK_I: number = 73;
    static VK_J: number = 74;
    static VK_K: number = 75;
    static VK_L: number = 76;
    static VK_M: number = 77;
    static VK_N: number = 78;
    static VK_O: number = 79;
    static VK_P: number = 80;
    static VK_Q: number = 81;
    static VK_R: number = 82;
    static VK_S: number = 83;
    static VK_T: number = 84;
    static VK_U: number = 85;
    static VK_V: number = 86;
    static VK_W: number = 87;
    static VK_X: number = 88;
    static VK_Y: number = 89;
    static VK_Z: number = 90;
    static VK_CONTEXT_MENU: number = 93;
    static VK_NUMPAD0: number = 96;
    static VK_NUMPAD1: number = 97;
    static VK_NUMPAD2: number = 98;
    static VK_NUMPAD3: number = 99;
    static VK_NUMPAD4: number = 100;
    static VK_NUMPAD5: number = 101;
    static VK_NUMPAD6: number = 102;
    static VK_NUMPAD7: number = 103;
    static VK_NUMPAD8: number = 104;
    static VK_NUMPAD9: number = 105;
    static VK_MULTIPLY: number = 106;
    static VK_ADD: number = 107;
    static VK_SEPARATOR: number = 108;
    static VK_SUBTRACT: number = 109;
    static VK_DECIMAL: number = 110;
    static VK_DIVIDE: number = 111;
    static VK_F1: number = 112;
    static VK_F2: number = 113;
    static VK_F3: number = 114;
    static VK_F4: number = 115;
    static VK_F5: number = 116;
    static VK_F6: number = 117;
    static VK_F7: number = 118;
    static VK_F8: number = 119;
    static VK_F9: number = 120;
    static VK_F10: number = 121;
    static VK_F11: number = 122;
    static VK_F12: number = 123;
    static VK_F13: number = 124;
    static VK_F14: number = 125;
    static VK_F15: number = 126;
    static VK_F16: number = 127;
    static VK_F17: number = 128;
    static VK_F18: number = 129;
    static VK_F19: number = 130;
    static VK_F20: number = 131;
    static VK_F21: number = 132;
    static VK_F22: number = 133;
    static VK_F23: number = 134;
    static VK_F24: number = 135;
    static VK_NUM_LOCK: number = 144;
    static VK_SCROLL_LOCK: number = 145;
    static VK_COMMA: number = 188;
    static VK_PERIOD: number = 190;
    static VK_SLASH: number = 191;
    static VK_BACK_QUOTE: number = 192;
    static VK_OPEN_BRACKET: number = 219;
    static VK_BACK_SLASH: number = 220;
    static VK_CLOSE_BRACKET: number = 221;
    static VK_QUOTE: number = 222;
    static VK_META: number = 224
    static isNumeric(key: number): boolean {
        return [
            Key.VK_0, Key.VK_1, Key.VK_2, Key.VK_3, Key.VK_4,
            Key.VK_5, Key.VK_6, Key.VK_7, Key.VK_8, Key.VK_9,
            Key.VK_NUMPAD0, Key.VK_NUMPAD1, Key.VK_NUMPAD2, Key.VK_NUMPAD3, Key.VK_NUMPAD4,
            Key.VK_NUMPAD5, Key.VK_NUMPAD6, Key.VK_NUMPAD7, Key.VK_NUMPAD8, Key.VK_NUMPAD9,
        ].indexOf(key) >= 0;
    }
    static isAlpha(key: number): boolean {
        return [
            Key.VK_A, Key.VK_B, Key.VK_C, Key.VK_D, Key.VK_E,
            Key.VK_F, Key.VK_G, Key.VK_H, Key.VK_I, Key.VK_J,
            Key.VK_K, Key.VK_L, Key.VK_M, Key.VK_N, Key.VK_O,
            Key.VK_P, Key.VK_Q, Key.VK_R, Key.VK_S, Key.VK_T,
            Key.VK_U, Key.VK_V, Key.VK_W, Key.VK_X, Key.VK_Y,
            Key.VK_Z, Key.VK_SPACE,
        ].indexOf(key) >= 0;
    }
    static isAlphaNumeric(key: number): boolean {
        return Key.isNumeric(key) || Key.isAlpha(key);
    }
    static isOther(key: number): boolean {
        return ! Key.isAlphaNumeric(key);
    }
    static isEspecial(key: number): boolean {
        return [
            Key.VK_CANCEL, Key.VK_HELP, Key.VK_BACK_SPACE, Key.VK_TAB, Key.VK_CLEAR,
            Key.VK_RETURN, Key.VK_ENTER, Key.VK_SHIFT, Key.VK_CONTROL, Key.VK_ALT,
            Key.VK_PAUSE, Key.VK_CAPS_LOCK, Key.VK_ESCAPE, Key.VK_PAGE_UP, Key.VK_PAGE_DOWN,
            Key.VK_END, Key.VK_HOME, Key.VK_LEFT, Key.VK_UP, Key.VK_RIGHT,
            Key.VK_DOWN, Key.VK_PRINTSCREEN, Key.VK_INSERT, Key.VK_DELETE, Key.VK_CONTEXT_MENU,
            Key.VK_F1, Key.VK_F2, Key.VK_F3, Key.VK_F4, Key.VK_F5,
            Key.VK_F6, Key.VK_F7, Key.VK_F8, Key.VK_F9, Key.VK_F10,
            Key.VK_F11, Key.VK_F12, Key.VK_F13, Key.VK_F14, Key.VK_F15,
            Key.VK_F16, Key.VK_F17, Key.VK_F18, Key.VK_F19, Key.VK_F20,
            Key.VK_F21, Key.VK_F22, Key.VK_F23, Key.VK_F24, Key.VK_NUM_LOCK,
            Key.VK_SCROLL_LOCK, Key.VK_META,
        
        ].indexOf(key) >= 0;
    }
}

export default Key;