import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";

import routes, {RoutesNonMenu, IRouteInfo} from "../routes";

import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";

import bgImage from "../assets/img/sidebar-2.jpg";
import logo from "../assets/img/logo192.png";
import { connect } from "react-redux";
import { ActionCreator, GetConfiguracoes, GetSprints, GetTiposSistemas, LoginOk, Logout } from "../../reducer/ActionsCreator";
import SemAcesso from "../../components/Login/SemAcesso";
import KeycloakPrivateRoute from "../../components/Component2/KeycloakPrivateRoute";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import keycloak from "../../secure/keycloak";
import { userInfoRemap } from "../../secure/UserInfoModel";

let ps;

export var aRoutes = [];

const useStyles = makeStyles(styles);

function Admin(props) {

  const GenRoutes = (prop, key) => {
    prop.forEach((prop, key) => {
      let gruposPermitidos = prop.gruposPermitidos || [];
      if ((prop?.items?.length || 0) > 0) {
        return GenRoutes(prop.items);
      }
      else {
        if (prop.layout === "/admin") {
          aRoutes.push (
            <KeycloakPrivateRoute
              roles={gruposPermitidos}
              path={prop.layout + prop.path}
              component={prop.component}
              gruposPermitidos={gruposPermitidos}
              key={key}
            />
          );
        }
        else {
          aRoutes.push (
            <KeycloakPrivateRoute
              roles={gruposPermitidos}
              path={prop.path}
              component={prop.component}
              gruposPermitidos={gruposPermitidos}
              key={key}
            />
          );
  
        }
      }
      return null;
    })
  }
  GenRoutes([...routes, ...RoutesNonMenu]);
  //(aRoutes);
  const switchRoutes = (
    <Switch>
      {aRoutes
      /*routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })*/}
      <Redirect from="/admin" to="/admin/dashboard" exact />
      <Redirect from="/" to="/admin/dashboard" exact />
    </Switch>
  );


  let {state, SetMainColor, ...rest } = props;

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [keycloakInitialized, setKeycloakInitialized] = React.useState(false);
  const [loginMessage, setLoginMessage] = React.useState("");

  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
    SetMainColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("message", (event) => {
      // console.log('mensagem', event);
      // console.log('mensagem ', event.data);
    })
  }, []);

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  React.useEffect(() => {
    keycloak.init({
      // onLoad: "login-required", 
      // adapter: "default",
      onLoad: 'check-sso',
      flow: "implicit", 
      redirectUri: window.origin,
      useNonce: true, 
      scope: "openid profile email",
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      // silentCheckSsoFallback: true,
      pkceMethod: 'S256',
      enableLogging: true,
      messageReceiveTimeout: 60000,
      checkLoginIframe: true,
      onAuthError: () => {
      },
    }).then(async (fullfilled) => {
      //alert(fullfilled);

      if (fullfilled) { 
        await keycloak.loadUserInfo();
        let r1 = await keycloak.loadUserProfile();
        let r = userInfoRemap(keycloak.userInfo);
        
        console.log('SSO Provider', fullfilled, keycloak.authenticated, keycloak.userInfo, r1);
        console.log(r);

        if ((r?.clientRoles?.noc_ui?.roles || []).indexOf("acesso-permitido") === -1) {
          setLoginMessage("Usuário não possui acesso ao Módulo requerido");
          setTimeout(() => {
            keycloak.login({prompt: "login"});
          }, 2000)
        }
        else {
          setLoginMessage("");
          props.login(r.preferred_username, '', (r?.clientRoles?.noc_ui?.roles || []).map(s => s.trim()));
          props.tipossistemas();
          props.sprints();
          props.configuracoes();
  
          setKeycloakInitialized(true);
        }

      }
      else {
        keycloak.login({prompt: "login"});
      }
    }, (reason) => {
      console.log('onReject reason', reason)
      keycloak.login({prompt: "login"});
    }).catch(async (err) => {
      console.log('Error SSO Provider', err, keycloak.userInfo);
    })
  }, [])

  if (! keycloakInitialized) {
    return (
      <div style={{
        display: 'grid', 
        alignItems: "center", 
        textAlign: "center",
        width: '100vw',
        height: '100vh',
      }} className={classes.mainPanel} ref={mainPanel}>
        <div>
        <img src="/noc.png" alt="noc" /> <br />
        <span>Inicializando NOC</span><br />
        <span>{loginMessage}</span> <br />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={(props?.state?.login?.logged || false) ? (props?.state?.login?.login || 'NOC') : "NOC"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        {/* <Navbar
          routes={[...routes, ...RoutesNonMenu]}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        /> */}
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}
        <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    state, ...ownProps
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    SetMainColor: (cor) => dispatch(ActionCreator.SetMainColor(cor)),
    login: (usuario, senha, gruposUsuario) => dispatch(LoginOk(usuario, senha, gruposUsuario)),
    logout: () => dispatch(Logout()),
    tipossistemas: () => dispatch(GetTiposSistemas()),
    sprints: () => dispatch(GetSprints()),
    configuracoes: () => dispatch(GetConfiguracoes()),
    ...ownProps,
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(Admin);