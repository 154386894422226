import React, { Component, ChangeEventHandler, useState } from "react";
import {BrowserRouter} from "react-router-dom";
import "./Login.css";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { Redirect } from "react-router-dom";
import { Store } from "../../reducer/Reducer";
import { Login as DoLogin, Logout, LoginOk, GetTiposSistemas, GetSprints, GetConfiguracoes } from "../../reducer/ActionsCreator";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { IconButton, Theme, makeStyles, withStyles, createStyles, WithStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import Button from "@material-ui/core/Button"
import MessageBox from "../MessageBox/MessageBox";
import WS from "../../Config/WS";
import Component2, { EmptyBaseState } from "../Component2/Component2";
import TabelaGridContainer from "../Tabela/TabelaGridContainer";
import TabelaGridItem from "../Tabela/TabelaGridItem";
import Card from "../../ui/components/Card/Card";
import CardHeader from "../../ui/components/Card/CardHeader";
import CardBody from "../../ui/components/Card/CardBody";
import CustomInput from "../../ui/components/CustomInput/CustomInput";
let Index = 10;
interface IState {
    usuario: string;
    password: string;
}
interface IProps {
    appstate: AppState;
    login: (usuario: string, senha: string, gruposUsuario: string[]) => void;
    logout: () => void;
    tipossistemas: () => void;
    sprints: () => void;
    configuracoes: () => void;
}
interface IRef {
    [Name: string]: React.RefObject<any>;
}
const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };
let estilo = (theme: Theme) => createStyles(styles as any);
class Login extends Component2<IProps & WithStyles, IState> {
    Ref: IRef;
    Seq: string[];
    constructor(props: any) {
        super(props);
        this.change = this.change.bind(this);
        this.submit = this.submit.bind(this);
        this.focus = this.focus.bind(this);
        this.keydown = this.keydown.bind(this);
        //this.closeAlert = this.closeAlert.bind(this);
        this.state = { 
            usuario: '', 
            password: '',
            error: false,
            errorMessage: '',
            redirectTo: '',
            confirm: false,
            confirmMessage: '',
            refreshCount: 0,
            refreshCountOld: 0,
            confirmCB: ()=>{},
            alertCB: ()=>{},
        
        };
        this.Ref = {
            "usuario": React.createRef(),
            "password": React.createRef(),
            "botao": React.createRef(),
        };
        this.Seq = ["usuario", "password", "botao"];
    }
    change(e: any) {
        const {name, value} = e.target;
        this.setState(Object.assign(this.state, {[name]: value, error: false}));
    }
    focus(e: any) {
        const {name, target} = e.target;
        if (name == "password") {
        }
    }
    keydown(e: any) {
        let {name, value} = e.target;
        if (e.which == 13) { 
            e.preventDefault();
            let id = this.Seq.indexOf(name);
            this?.Ref[this.Seq[id+1]]?.current?.focus();
        }
    }
    fetchData() {
        this.props.tipossistemas();
        this.props.sprints();
        this.props.configuracoes();
    }
    async submit(e: any) {
        e.preventDefault();
        const {usuario, password} = this.state;
        try {
            // this.props.login("fagnerX", ' ');
            // this.fetchData();
            // this.redirectTo("/");
            // return;

            if (usuario.trim().length == 0) {
                this.loginError('Usuario deve ser informado ');
            }
            else if (password.trim().length == 0) {
                this.loginError('Senha deve ser informada');
            }
            else {
                let r = await axios.post(WS.URL_NOC + "auth/login", {usuario, password});
                //console.log(r)
                if (this.isError(r.data)) {
                    this.loginError(this.getMessage(r.data));
                }
                else {
                    this.props.login(r?.data?.data?.usuario, '', (r?.data?.data?.gruposinscritos || "").split(",").map((s: string) => s.trim()));
                    this.fetchData();
                    this.redirectTo("/")
                }
            }            
        }
        catch(e: any) {
            this.loginError(e.message);
        }
    }
    loginError(msg: string) {
        this.showAlert(msg);
    }
    componentDidMount() {
        if (this.props.appstate.login.logged )
            this.props.logout();

        this.Ref['usuario']?.current?.focus();
    }
    render() {
        if (this.state.redirectTo != '')
            return <Redirect to={this.state.redirectTo}/>
        else {
            //Store.dispatch(NocLogout());
            
            return (
                <React.Fragment>
                <TabelaGridContainer>
                    <TabelaGridItem xs={12} sm={12} md={12}>

                        <div style={{alignItems: 'center'}}>
                        <TabelaGridContainer>
                            <TabelaGridItem xs={6} md={6} sm={6}>
                                <Card>
                                    <CardHeader color=""
                                        title="Login"
                                        subtitle="Informe seus dados de acesso"
                                    />
                                    
                                    <CardBody>
                                        <TabelaGridContainer>
                                            <TabelaGridItem xs={12} sm={12} md={6}>
                                                <CustomInput
                                                    inputRef={this.Ref['usuario']} name="usuario"
                                                    labelText="Usuário"
                                                    id="usuario"
                                                    formControlProps={{
                                                    fullWidth: true
                                                    }}
                                                    onChange={this.change} value={this.state.usuario} onKeyDown={this.keydown}
                                                />                                


                                            </TabelaGridItem>
                                            <TabelaGridItem xs={12} sm={12} md={6}>
                                                <CustomInput
                                                    inputRef={this.Ref['password']} name="password"
                                                    labelText="Senha"
                                                    id="password"
                                                    formControlProps={{
                                                    fullWidth: true
                                                    }}
                                                    type="password"
                                                    onChange={this.change} value={this.state.password} onKeyDown={this.keydown}
                                                />                                


                                            </TabelaGridItem>
                                            <TabelaGridItem xs={12} sm={12} md={12}>
                                                <Button ref={this.Ref['botao']} name="botao" onClick={this.submit} variant="contained" color="primary">Login</Button>
                                            </TabelaGridItem>

                                        </TabelaGridContainer>
                                    </CardBody>
                                </Card>
                            </TabelaGridItem>
                        </TabelaGridContainer>
                        </div>
                    </TabelaGridItem>
                </TabelaGridContainer>

                {/* <div className="container col-4">
                    <div className="card" style={{marginTop: '20px'}}>
                        <div className="card-header">
                            <span>Login</span>
                        </div>
                        <div className="card-body">
                            <form className="form-group">
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label>Usuario</label>
                                        <input ref={this.Ref['usuario']} name="usuario" className="form-control" autoFocus onChange={this.change} value={this.state.usuario} onKeyDown={this.keydown}/>
                                        {(this.state.usuario == "") &&
                                            <div className="alert alert-danger">Usu&aacute;rio &eacute; obrigat&oacute;rio</div>
                                        }
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Senha</label>
                                        <input ref={this.Ref['password']} name="password" className="form-control" type="password" onChange={this.change} value={this.state.password} onFocus={this.focus} onKeyDown={this.keydown}/>
                                    </div>
                                </div>
                                <button ref={this.Ref['botao']} name="botao" onClick={this.submit} className="btn btn-primary">Confirmar</button>
                            </form>
                        </div>
                    </div>
                </div> */}
                {/* <Popup2 open={this.state.error} msg={this.state.errorMessage} close={this.closeAlert} /> */}
                {this.baseRender()}
                {/* <MessageBox title="Aviso" open={this.state.error} message={this.state.errorMessage} dialogClose={this.closeAlert} /> */}

                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = (state: AppState): any => {
    return {
        appstate: state,
    }
}
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
    return {
        login: (usuario: string, senha: string, gruposUsuario: string[]) => dispatch(LoginOk(usuario, senha, gruposUsuario)),
        logout: () => dispatch(Logout()),
        tipossistemas: () => dispatch(GetTiposSistemas()),
        sprints: () => dispatch(GetSprints()),
        configuracoes: () => dispatch(GetConfiguracoes()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(estilo)(Login));