import { AppState, emptyClienteLiberacao } from "./ReducerTypes";
import {createStore, Reducer, applyMiddleware, combineReducers} from "redux";
import createSagaMidlleware, { SagaMiddleware } from "redux-saga";
import { Action, ACTION_LOGIN, ACTION_LOGOUT, ActionLogin, ActionLogout, ACTION_LOGINOK, ActionLoginOk, ACTION_SETLINHAPRODUTO, ActionSetLinhaProduto, ACTION_FETCHTIPOSSISTEMAS, ActionFetchTiposSistemas, ACTION_FETCHSPRINTS, ActionFetchSprints, ACTION_SETCLIENTELIBERACAO, ActionSetClienteLiberacao, ACTION_SETMAINCOLOR, ActionSetMainColor, ACTION_FETCHCONFIGURACOES, ActionFetchConfiguracoes } from "./Actions";
import {rootSaga} from "./Reducer-saga";
import {logger} from "redux-logger";
export let initialState: AppState = {
    login: {
        logged: false,
        login: "",
        password: "",
        gruposUsuario: [],
    },
    dados: {
        mainColor: "blue",
        linhaproduto: "",
        tipossistemas: [],
        sprints: [],
        clienteliberacao: emptyClienteLiberacao,
        configuracoes: [],
    }
}

const reducer = (state: AppState = initialState, action: Action): AppState => {
    switch (action.type) {
        case ACTION_LOGINOK: {
            return Object.assign({}, state, {login: {
                logged: true,
                login: (action as ActionLoginOk).login,
                gruposUsuario: (action as ActionLoginOk).gruposUsuario,
            }});
        }
        case ACTION_LOGOUT: {
            return Object.assign({}, state, {login: {
                logged: false,
                login: "",
                gruposUsuario: [],
            }})
        }
        case ACTION_SETLINHAPRODUTO: {
            return Object.assign({}, state, {dados: {
                ...state.dados,
                linhaproduto: (action as ActionSetLinhaProduto).linhaproduto,
            }})
        }
        case ACTION_FETCHTIPOSSISTEMAS: {
            return Object.assign({}, state, {dados: {
                ...state.dados,
                tipossistemas: (action as ActionFetchTiposSistemas).tipossistemas,
            }})
        }
        case ACTION_FETCHSPRINTS: {
            return Object.assign({}, state, {dados: {
                ...state.dados,
                sprints: (action as ActionFetchSprints).sprints,
            }})
        }
        case ACTION_FETCHCONFIGURACOES: {
            return Object.assign({}, state, {dados: {
                ...state.dados,
                configuracoes: (action as ActionFetchConfiguracoes).configuracoes,
            }})
        }
        case ACTION_SETCLIENTELIBERACAO: {
            return Object.assign({}, state, {dados: {
                ...state.dados,
                clienteliberacao: (action as ActionSetClienteLiberacao).clienteliberacao,
            }})
        }
        case ACTION_SETMAINCOLOR: {
            return Object.assign({}, state, {dados: {
                ...state.dados,
                mainColor: (action as ActionSetMainColor).cor,
            }})
        }
        default: {
            return state;
        }
    }
}
export var saga: SagaMiddleware = createSagaMidlleware();
export let Store = createStore(reducer, initialState, applyMiddleware(saga/*, logger*/));
saga.run(rootSaga);