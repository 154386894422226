import React, { Component, forwardRef, ChangeEvent, KeyboardEvent, useMemo } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Input, Button, Select, MenuItem } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { ITiposistema } from "../../persistence/interfaces/ITiposistema";
import { EmptyBaseState } from "../Component2/Component2";
import { RecordWithTtl } from "dns";
import MessageBox from "../MessageBox/MessageBox";
import NumericInput from "../NumericInput/NumericInput";
import { connect } from "react-redux";
import { AppState } from "../../reducer/ReducerTypes";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import { ISprint } from "../../persistence/interfaces/ISprint";
import { withRouter } from "react-router-dom";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate: AppState;
}
interface IState {
    sistemas: ITiposistema[];
    versoes: IVersao;
}
interface IRefs {
    [Name: string]: React.RefObject<any>;
}
interface IVersao {
    [Name: string]: string;
}
class AddVersao extends PrivateComponent<IProps, IState> {
    Refs: IRefs;
    constructor(props: any) {
        super(props);
        
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.onchange = this.onchange.bind(this);
        this.onkeydown = this.onkeydown.bind(this);
        this.submit = this.submit.bind(this);
        this.getultimaversao = this.getultimaversao.bind(this);
        this.Refs = {};
        let v: IVersao = {};
        this.lista().forEach((t: ITiposistema, index: number) => {
            this.Refs[t.codigo] = React.createRef();
            v[t.codigo] = "";
        });
        this.Refs["sprint"] = React.createRef();
        v["sprint"] = "null";

        this.state = {
            ...EmptyBaseState,
            sistemas: [],
            versoes: v,
        }
    }
    lista(): ITiposistema[] {
        return this.props.appstate.dados.tipossistemas.filter((v: ITiposistema) => v.linhaproduto == this.props.appstate.dados.linhaproduto);

    }
    componentDidMountPrivate() {
        this.consulta();
    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    async consulta() {
        
        let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
            sql: `
                select * from tiposistema where linhaproduto = ''
                order by codigo='banco' desc            
            `,
            page: 1,
            pageSize: 100,
            search: '',
            orderBy: ""
        });
    }
    onchange(ev: ChangeEvent) {
        const {name, value} = ev.target as any;
        let s: IState = this.state;
        s.versoes[name] = value;
        this.setState(s);
    }
    onkeydown(e: KeyboardEvent) {
        let lista = this.lista();
        const {name, value} = e.target as any;
        if (e.which == 13) {
            let id = -1;
            lista.forEach((t: ITiposistema, index: number) => {
                if (t.codigo == name) {
                    id = index+1;
                }
            });

            //console.log(id, lista[id].codigo)
            id = id >= lista.length ? 0 : id;
            this.state.versoes[name] = value;
            e.preventDefault();
            this.Refs[lista[id].codigo].current?.focus();
        }
    }
    async submit() {
        let r: IControllerResult = await NocRequest.post("noc/addversao", {
            ...this.state.versoes,
            linhaproduto: this.props.appstate.dados.linhaproduto,
        });
        if (this.isOk(r)) {
            this.showAlert("Versão adicionada com sucesso.", () => {
                this.redirectTo(this.props.appstate.dados.linhaproduto.replace("linha", "versao"));
            });
        }
        else
            this.showAlert(this.getMessage(r));
    }
    async getultimaversao() {
        let result: any = await NocRequest.post("entity/sqlpage", {
            sql: `
                select * from (
                    select idversao, 
                        versao[buscaindice(codigosistema,'banco')] as banco, 
                        versao[buscaindice(codigosistema,'icompany')] as icompany, 
                        versao[buscaindice(codigosistema,'wcompany')] as wcompany, 
                        versao[buscaindice(codigosistema,'ipdv')] as ipdv, 
                        versao[buscaindice(codigosistema,'imendes')] as imendes, 
                        versao[buscaindice(codigosistema,'ibalanco')] as ibalanco, 
                        versao[buscaindice(codigosistema,'nfedanfe')] as nfedanfe, 
                        versao[buscaindice(codigosistema,'xboleto')] as xboleto, 
                        versao[buscaindice(codigosistema,'nfemonitor')] as nfemonitor,
                        versao[buscaindice(codigosistema,'xport')] as xport, 
                        versao[buscaindice(codigosistema,'xpdv')] as xpdv, 
                        versao[buscaindice(codigosistema,'xposto')] as xposto,
                        versao[buscaindice(codigosistema,'icompanysd')] as icompanysd,
                        versao[buscaindice(codigosistema,'smartcompany')] as smartcompany,
                        versao[buscaindice(codigosistema,'sincdemander')] as sincdemander,
                        sprint
                    from (
                        select 
                            v.idversao, 
                            array_agg(v.codigosistema) as codigosistema, 
                            array_agg(t.descricao) as sistema, 
                            array_agg(v.linhaproduto) as linhaproduto, 
                            array_agg(v.versao) as versao ,
                            coalesce(max(v.idsprint)::text, 'null') as sprint
                        from versoes v
                        left join tiposistema t on (t.linhaproduto,t.codigo)=(v.linhaproduto,v.codigosistema)
                        where v.linhaproduto = '${this.props.appstate.dados.linhaproduto}' and
                            idversao = (select max(idversao) from versoes where linhaproduto = '${this.props.appstate.dados.linhaproduto}')
                        group by idversao 
                        order by idversao desc
                    ) as cc 
                ) as con            
            `,
            page: 1,
            pageSize: 100,
            search: "",
            orderBy: ""
        });

        if (result.data && result.data.length > 0) {
            let v: any = this.state.versoes;
            for (let c in result.data[0]) {
                if ((typeof result.data[0][c] !== "function") && (typeof result.data[0][c] !== null) && ("idversao/".indexOf(c) == -1)) {
                    v[c] = result.data[0][c];
                }
            }
            this.estado({versoes: v});
        }
        //console.log(result)

    }
    getSelectSprint() {
        let opt = this.props.appstate.dados.sprints.map((el: ISprint) => {
            return <MenuItem value={el.idsprint}>{el.idsprint} - {el.descricao}</MenuItem>
        });
        
        return <Select name="sprint" value={this.state.versoes["sprint"]} onChange={this.onchange as any} >
                <MenuItem value="null">&nbsp;</MenuItem>
                {opt}
            </Select>;
    }
    renderPrivate() {
        console.log(this.props)
        let Cells = this.lista().map((v: ITiposistema, index: number) => {
            return [
                <TableCell className="formapag col-3">{v.descricao}</TableCell>,
                <TableCell className="formapag col-3">
                    <Input inputRef={this.Refs[v.codigo]} 
                        className="formControl numericInput " 
                        name={v.codigo} 
                        value={this.state.versoes[v.codigo]} 
                        onChange={this.onchange} 
                        onKeyDown={this.onkeydown}
                        autoFocus={index == 0} 
                    />
                </TableCell>,
            ];
        });
        if (Cells.length % 2 != 0) {
            Cells.push([
                <TableCell className="formapag col-3">&nbsp;</TableCell>,
                <TableCell className="formapag col-3">&nbsp;</TableCell>,
            ]);
        }
        
        // Linha extra para Sprint
        Cells.push([
            <TableCell className="formapag col-3">Sprint</TableCell>,
            <TableCell className="formapag col-9" colSpan={3}>
                {this.getSelectSprint()}
            </TableCell>,
            null as any
        ]);
        if (Cells.length % 2 != 0) {
                Cells.push([
                null as any,
                null as any
            ]);
        }


        return (
            <div className="container-fluid col-6">
                <div className="row">
                    <div className="col col-12">
                        <div className="card">
                            <div className="card-header">
                                Mapeamento de Versões
                            </div>
                            <div className="card-body">
                                <TableContainer component={Paper} style={{height: '100%'}}>
                                    <Table className="" aria-label="simple table">
                                        <TableBody>
                                            {Cells.map((v: any, index: number) => {
                                                if (index % 2 == 0)
                                                    return (
                                                        <TableRow>
                                                            {Cells[index][0]}
                                                            {Cells[index][1]}
                                                            {Cells[index+1][0]}
                                                            {Cells[index+1][1]}
                                                        </TableRow>
                                                    );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Button color="primary" variant="contained" onClick={this.submit}>Salvar</Button>
                                                    &nbsp;<Button color="primary" variant="contained" onClick={this.getultimaversao}>Última Versao</Button>
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>            
                                <MessageBox 
                                    open={this.state.error} message={this.state.errorMessage}  
                                    dialogClose={this.closeAlert}
                                    title={"Informações"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );    
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
// const mapDisptachToProps = (dispatch: any, ownProps: any) => {
//     return {
//         //SetClienteVenda: (codigocliente: number, nome: string) => dispatch(SetClienteVenda(codigocliente, nome)),
//         ...ownProps,
//     };
// }

export default connect(mapStateToProps)(AddVersao);
