import axios, {AxiosResponse, AxiosRequestConfig} from "axios";
import WS from "../Config/WS";
import { IControllerResult } from "../controllers/Types";

export default class {
    static ajustURL(url: string): string {
        let r: string = url;
        r = r.replace("://", ":||");
        while (r.indexOf("//") >= 0) {
            r = r.replace("//", "/");
        }
        r = r.replace(":||", "://");
        return r;
    }
    static async post<T = IControllerResult>(endPoint: string, data: any): Promise<T> {
        let config: AxiosRequestConfig  = {
            url: this.ajustURL(WS.URL_NOC + `${endPoint}`),
            method: "POST",
            data,
        };
        let res: T;
        try {
            let r: AxiosResponse<T> = await axios(config);
            res = r.data;
        } catch(e) {
            res = e as any;
        }
        
        return res;
    }
    static async get<T = IControllerResult>(endPoint: string): Promise<T> {
        let config: AxiosRequestConfig  = {
            url: this.ajustURL(WS.URL_NOC + `${endPoint}`),
            method: "GET",
        };
        let res: T;
        try {
            let r: AxiosResponse<T> = await axios(config);
            res = r.data;
        } catch(e) {
            res = e as any;
        }
        
        return res;
    }
    static async sql<T = any>(sql: string): Promise<T[]> {
        let data: any = await this.post<T>("entity/sql", { sql });
        return data;
    }
    static async execSql<T = any>(sql: string): Promise<T[]> {
        let data: any = await this.post<T>("entity/executeSql", { sql });
        return data;
    }
}