import { IOptions } from "./FormularioTypes"

export default class Combos {
    static SimNao(valueSim: any = "S", valueNao: any = "N"): IOptions[] {
        return [{caption: "Não", value: valueNao},{caption: "Sim", value: valueSim}];
    }
    static FaseAtualizacao(): IOptions[] {
        return [
            {caption: "Concluída com Erro", value: -1},
            {caption: "Não Iniciada", value: 0},
            {caption: "Iniciada", value: 1},
            {caption: "Concluída com Sucesso", value: 2},
        ];
    }
    static PrioridadeNotificacao(): IOptions[] {
        return [
            {caption: "Sem prioridade", value: 0},
            {caption: "Baixa", value: 1},
            {caption: "Padrão", value: 2},
            {caption: "Alta", value: 3},
            {caption: "Urgencia", value: 4},
        ];
    }
    static TipoExecucaoScript(): IOptions[] {
        return [
            {caption: "Execução Única Vez", value: 0},
            {caption: "Execução Até Concluir sem Erros", value: 1},
            {caption: "Execução Até Atingir Quantidade", value: 2},
            {caption: "Execução Até Atingir Quant. Sucessos", value: 3},
            {caption: "Execução Até Atingir Quant. Erros", value: 4},
        ];
    }
    // static BancoPortador(): ISQLCombo {
    //     return {
    //         sql: `select * from a_portad order by id`,
    //         keyField: "$codigo - $descricao",
    //         valueField: "id",
    //     };
    // }
    

}