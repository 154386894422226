import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios, { AxiosRequestConfig } from "axios";
import { Input, Button, Select, MenuItem } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import { EmptyBaseState } from "../Component2/Component2";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tabela2 from "../Tabela/Tabela2";
import Formatter from "../../utils/Formatters";
import { IGruposUsuario } from "../../utils/SecTypes";
var fileDownload = require('js-file-download');

const tableIcons: any = {
    Add: forwardRef((props, ref: any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref: any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref: any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref: any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref: any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref: any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref: any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref: any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref: any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref: any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref: any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref: any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref: any) => <ViewColumn {...props} ref={ref} />)
};

const consulta = (query: any) => {
    let order = (query?.orderBy?.field || 'datahora') + ' ' + (query?.orderDirection || 'desc');
    let search: string = query?.search || '';
    return new Promise<any>(async (resolve, reject) => {
        let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
            sql: `
                select * from liberacaolicencas
                where 
                    (listacnpj like '%'||regexp_replace('${search}', '[^0-9]', '', 'gi')||'%')
                order by datahora desc, ${order}               
            `,
            page: query.page,
            pageSize: query.pageSize,
            search: query.search,
            orderBy: ""
        })

        resolve({
            data: result?.data?.data,
            page: result?.data?.page - 1,
            totalCount: result?.data?.total
        });
    })
}
const RowClick = async (event: any, rowdata: any, errorFunc: any) => {
}
interface IRef {
    [Name: string]: React.RefObject<HTMLInputElement>;
}
interface IState {
    estado: "grid" | "edit" | "insert";
    razao_social: string;
    cnpj: string;
    ativo: string;
}
class LiberacaoLicenca extends PrivateComponent<any, IState> {
    Ref: IRef;
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.baixar = this.baixar.bind(this);
        this.userHas = this.userHas.bind(this);
        this.Ref = {
            'cnpj': React.createRef(),
            'razao_social': React.createRef(),
            'ativo': React.createRef(),
        };
        
        this.state = {
            ...EmptyBaseState,
            estado: "grid",
            cnpj: "",
            razao_social: "",
            ativo: ""
        }
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    userHas(roles: IGruposUsuario[]): boolean {
        let r = false;
        for (let role of roles) {
            if (this.props.state.login.gruposUsuario.indexOf(role) != -1) {
                r = true;
                break;
            }
        }
        return r;
    }
    baixar(data: any) {
        if (this.userHas(["criarlicenca1", "criarlicenca2", "criarlicenca3", "noc"])) {
            fileDownload(data.arquivolib, 'infolib.dat');
        }
        else {
            this.showAlert("Você não possui permissão de acesso ao recurso selecionado!");
        }
    }

    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    actions={[
                        {
                            isFreeAction: true,
                            icon: <AddBox />,
                            onClick: (rowData: any) => {
                                if (this.userHas(["criarlicenca1", "criarlicenca2", "criarlicenca3", "noc"])) {
                                    this.redirectTo("/getinfolib");
                                }
                                else {
                                    this.showAlert("Você não possui permissão de acesso ao recurso selecionado!");
                                }
                            }
                        }
                    ]}
                    actionBarFreeWidth={2}
                    actionsColumns={2}
                    actionColumnWidth={25}
                    columns={[
                        { title: "ID", field: "id" },
                        { title: "Usuário", field: "usuario" },
                        { title: "Data/Hora", field: "datahora", render: Formatter.date("datahora", "d-m-Y h:n:s") },
                        { title: "Lista CNPJ", field: "listacnpj" },
                        { title: "Validade", field: "validade", render: Formatter.date("validade", "d-m-Y") },
                        { title: "Observação", field: "motivo" },
                        { title: "Arquivo LIB", field: "arquivolib", render: (data: any) => {
                            return <Button color="primary" onClick={() => this.baixar(data)}>Baixar</Button>
                        } },
                    ]}
                    

                    data={consulta}
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     //maxBodyHeight: '50vw',
                    //     pageSize: 30,
                    //     pageSizeOptions: [5, 10, 20, 30, 50, 100]
                    // }}
                    search={true}
                    pageSize={30}
                    title="Licenças Liberadas"
                    // onRowClick={this.rowClick/*RowClick*/}

                />
                {this.baseRender()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {state,...ownProps};
}
export default connect(mapStateToProps)(LiberacaoLicenca);