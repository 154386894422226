import {takeEvery, all, put} from "redux-saga/effects";
import { ACTION_LOGIN, ACTION_LOGINOK, ActionLogin, ACTION_GETTIPOSSISTEMAS, ACTION_GETSPRINTS, ACTION_GETCONFIGURACOES } from "./Actions";
import axios from "axios";
import { LoginOk, FetchTiposSistemas, FetchSprints, FetchConfiguracoes } from "./ActionsCreator";
import WS from "../Config/WS";
import { ResponseGenerator } from "next/dist/server/response-cache";

interface YieldInterface {
    data: any;
}

function* GetLogin(action: ActionLogin) {
    const resp: YieldInterface = yield axios.get(`endpoint`);

    if ((resp?.data?.status) && (`${resp?.data?.status}`.toUpperCase() == "ERROR")) {
        //console.log(resp)
    }
    else {
        yield put(LoginOk(action.login, action.password, []));
    }

}
function* GetTiposSistemas() {
    const resp: YieldInterface = yield axios.post(WS.URL_NOC + "entity/sqlpage", {
        sql: `
            select * from tiposistema
            order by linhaproduto, codigo='banco' desc            
        `,
        page: 1,
        pageSize: 100,
        search: '',
        orderBy: ""
    });
    if ((resp?.data?.status) && (`${resp?.data?.status}`.toUpperCase() == "ERROR")) {
        //console.log(resp)
    }
    else {
        yield put(FetchTiposSistemas(resp?.data?.data));
    }

}
function* GetSprints() {
    let resp: YieldInterface = yield axios.post(WS.URL_NOC + "entity/sqlpage", {
        sql: `
            select * from sprint
            order by idsprint desc
        `,
        page: 1,
        pageSize: 100,
        search: '',
        orderBy: ""
    });
    if ((resp?.data?.status) && (`${resp?.data?.status}`.toUpperCase() == "ERROR")) {
        //console.log(resp)
    }
    else {
        //console.log('saga', resp?.data?.data);
        yield put(FetchSprints(resp?.data?.data));
    }

}
function* GetConfiguracoes() {
    let resp: YieldInterface = yield axios.post(WS.URL_NOC + "entity/sqlpage", {
        sql: `
            select * from configuracoes
            order by classe, parametro
        `,
        page: 1,
        pageSize: 100,
        search: '',
        orderBy: ""
    });
    if ((resp?.data?.status) && (`${resp?.data?.status}`.toUpperCase() == "ERROR")) {
        //console.log(resp)
    }
    else {
        //console.log('saga', resp?.data?.data);
        yield put(FetchConfiguracoes(resp?.data?.data));
    }

}
export function* rootSaga() {
    yield all([
        takeEvery("OLA", GetLogin),
        takeEvery(ACTION_GETTIPOSSISTEMAS, GetTiposSistemas),
        takeEvery(ACTION_GETSPRINTS, GetSprints),
        takeEvery(ACTION_GETCONFIGURACOES, GetConfiguracoes),
    ]);
}