import React, {Component} from "react"
import { TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Input, InputProps, Chip } from "@material-ui/core";
import { Store } from "../../reducer/Reducer";
import { IGruposUsuario } from "../../utils/SecTypes";

interface IState {
    chipsInserted: boolean[];
    value: string;
}
interface IProps extends InputProps {
    chipLabels: string[];
    chipValues: string[];
    chipValueSeparator?: string;
    colorOn?: string;
    colorOff?: string;
    value: string;
    name: string;
}

class ChipGroup extends Component<IProps, IState> {
    ref:  React.RefObject<HTMLInputElement>;
    constructor(props: any) {
        super(props);
        this.doclick = this.doclick.bind(this);
        this.userHas = this.userHas.bind(this);

        let v: any = (this.props?.value || '');
        this.state = {
            chipsInserted: this.props.chipValues.map(e => this.props.value.split(this.props.chipValueSeparator || ',').indexOf(e) >= 0),
            value: this.props.value,
        };
        //console.log(this.props.value, this.state.chipsInserted)
        this.ref = React.createRef();
    }
    userHas(roles: IGruposUsuario[]): boolean {
        let r = false;
        
        for (let role of roles) {
            if (Store.getState().login.gruposUsuario.indexOf(role) != -1) {
                r = true;
                break;
            }
        }
        return r;
    }
    static getDerivedStateFromProps(nextProps: IProps, oldState: IState) {
        // console.log('derived', nextProps.value , oldState.value)
        if (nextProps.value != oldState.value) {
            return {
                value: nextProps.value,
                chipsInserted: nextProps.chipValues.map(e => nextProps.value.split(nextProps.chipValueSeparator || ',').indexOf(e) >= 0),
            };
        }
        return null;
    }
    
    doclick(index: number) {
        let e = [...this.state.chipsInserted]
        e[index] = ! e[index];
        this.setState({chipsInserted: e});

        if (this?.props?.onChange) {
            let ev = {
                target: {
                    name: this.props.name, 
                    value: e.map((e, i) => e ? this.props.chipValues[i] : "").filter(e => e != "").join(this.props.chipValueSeparator || ","),
                },
            };
            this.props.onChange(ev as any);
        }

    }
    
    render() {
        const colorOn = this.props.colorOn || '#3f51b5';
        const colorOff = this.props.colorOff || '#ccc';
        return this.props.chipValues.map((v, i) => {
            return <span onClick={() => this.doclick(i)}><Chip label={i <= this.props.chipLabels.length-1 ? this.props.chipLabels[i] : v}  style={{margin: '5px', cursor: 'pointer', backgroundColor: this.state.chipsInserted[i] ? colorOn : colorOff}} /></span>;
            // 
        }) 
    }
}

export default ChipGroup;