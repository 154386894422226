import React, { Component, forwardRef, ChangeEvent, KeyboardEvent, useMemo } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Input, Button, Select, MenuItem } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { ITiposistema } from "../../persistence/interfaces/ITiposistema";
import { EmptyBaseState } from "../Component2/Component2";
import { RecordWithTtl } from "dns";
import MessageBox from "../MessageBox/MessageBox";
import NumericInput from "../NumericInput/NumericInput";
import { connect } from "react-redux";
import { AppState } from "../../reducer/ReducerTypes";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import { ISprint } from "../../persistence/interfaces/ISprint";
import { withRouter } from "react-router-dom";
import TabelaGridContainer from "../Tabela/TabelaGridContainer";
import TabelaGridItem from "../Tabela/TabelaGridItem";
import Card from "../../ui/components/Card/Card";
import CardHeader from "../../ui/components/Card/CardHeader";
import CardBody from "../../ui/components/Card/CardBody";
import Formulario, { FormularioClass } from "../Formulario/Formulario";
import Combos from "../Formulario/Combos";
import { FormFuncs, OldState } from "../Formulario/FormularioTypes";
import md5 from "md5";
import Utils from "../../utils/Utils";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate: AppState;
}
interface IState {
}
class AddNotificacao extends PrivateComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        //this.onBeforePost = this.onBeforePost.bind(this);
        this.state = {
            ...EmptyBaseState,
        }
    }
    componentDidMountPrivate() {
    }
    renderPrivate() {
        return (
            <Formulario
                
                formWidth={8}
                formTitle="Notificação"
                entity="Notificacao"
                goBack={() => this.redirectTo("/admin/notificacao")}
                Campos={[
                    {
                        type: "string",
                        name: "id",
                        caption: "ID",
                        widthField: 2,
                        disabled: true,
                    },
                    {
                        type: "string",
                        name: "titulo",
                        caption: "Título",
                        helpText: "Título da notificação",
                        widthField: 10,
                    },

                    {
                        type: "multiline",
                        name: "corpo",
                        caption: "Notificação",
                        helpText: "Conteudo da notificação",
                        widthField: 12,
                        defaultValue: "",
                        multiline: true,
                        multilineRows: 4,
                    },                    
                    
                    {
                        type: "string",
                        name: "envio_minuto",
                        caption: "Minuto",
                        helpText: "Minuto do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "string",
                        name: "envio_hora",
                        caption: "Hora",
                        helpText: "Hora do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "string",
                        name: "envio_dia",
                        caption: "Dia",
                        helpText: "Dia do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "string",
                        name: "envio_mes",
                        caption: "Mês",
                        helpText: "Mês do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "string",
                        name: "envio_ano",
                        caption: "Ano",
                        helpText: "Ano do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "string",
                        name: "envio_diasemana",
                        caption: "Dia Semana",
                        helpText: "Dia da Semana do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },

                    {
                        type: "combo",
                        name: "prioridade",
                        caption: "Prioridade",
                        helpText: "Indica a prioridade da notificação",
                        comboItensDef: Combos.PrioridadeNotificacao(),
                        widthField: 3, 
                        defaultValue: 0,
                    },
                    {
                        type: "number",
                        name: "repcount",
                        caption: "Repetições",
                        helpText: "Quantas vezes a notificação deve ser enviada",
                        widthField: 2,
                        defaultValue: "0",
                    },
                    {
                        type: "number",
                        name: "envios",
                        caption: "Envios",
                        helpText: "Quantas vezes a notificação já foi enviada",
                        widthField: 2,
                        defaultValue: "0",
                    },
                    {
                        type: "number",
                        name: "maxenvios",
                        caption: "Máximo de Envios",
                        helpText: "Quantidade máxima de envios da notificação",
                        widthField: 2,
                        defaultValue: 1,
                    },
                    {
                        type: "combo",
                        name: "ativa",
                        caption: "Ativa",
                        helpText: "Indica se a notificação esta ativa",
                        comboItensDef: Combos.SimNao(1, 0),
                        widthField: 3, 
                        defaultValue: 0,
                    },



                    {
                        type: "string",
                        name: "tableref",
                        caption: "Tabela Referência",
                        helpText: "Tabela de referência vinculada a notificação",
                        widthField: 3,
                        defaultValue: "",
                    },
                    {
                        type: "string",
                        name: "idref",
                        caption: "Identificador Referência",
                        helpText: "Identificador da tabela de referência vinculada a notificação",
                        widthField: 3,
                        defaultValue: "0",
                    },
                    {
                        type: "string",
                        name: "strref",
                        caption: "Texto de Referência",
                        helpText: "Texto de referência vinculada a notificação",
                        widthField: 3,
                        defaultValue: "0",
                    },
                    {
                        type: "date",
                        name: "dateref",
                        caption: "Data de Referência",
                        helpText: "Date de referência vinculada a notificação",
                        widthField: 3,
                        defaultValue: "",
                    },
                    {
                        type: "multiline",
                        name: "error",
                        caption: "Mensagem de erro",
                        helpText: "Mensagem de erro ocorrido durante o envio da notificação",
                        widthField: 12,
                        defaultValue: "",
                        multiline: true,
                        multilineRows: 4,
                    },


                ]}
            >
            </Formulario>
        );    
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
// const mapDisptachToProps = (dispatch: any, ownProps: any) => {
//     return {
//         //SetClienteVenda: (codigocliente: number, nome: string) => dispatch(SetClienteVenda(codigocliente, nome)),
//         ...ownProps,
//     };
// }

export default connect(mapStateToProps)(AddNotificacao);
