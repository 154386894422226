import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { SetLinhaProduto } from "../../reducer/ActionsCreator";
import { Store } from "../../reducer/Reducer";
import Component2 from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import Formatter from "../../utils/Formatters";
import { withRouter } from "react-router-dom";
import Tabela2 from "../Tabela/Tabela2";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate?: AppState;
    linhaproduto: string;
    SetLinhaProduto?: (linhaproduto: string) => void;
}

class InformacaoBackup extends PrivateComponent<IProps, any> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.removerVersao = this.removerVersao.bind(this);
        this.removerVersao1 = this.removerVersao1.bind(this);
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    consulta(query: any) {
        const expr = (field: string, type: string, formmat: string) => {
            let s = field;
            if (type == "date")
                s = `to_char(${field}, '${formmat}')`;
            return s;
        }

        let order = (query?.orderBy?.field || 'nomeempresa') + ' ' + (query?.orderDirection || '');
        let search: string = query?.search || '';
        let field: string = query?.field || '';
        let type: string = query?.fieldtype || '';
        let formmat: string = query?.fieldformmat || '';
        let where: string = "";
        where = field == "" ? `
            where  false
            or empresa::text like '%${search.toUpperCase()}%'
            or nomeempresa::text like '%${search.toUpperCase()}%'
            or cnpj::text like '%${search.toUpperCase()}%'
            or nomeloja::text like '%${search.toUpperCase()}%'
            or datai::text like '%${search.toUpperCase()}%'
            or dataf::text like '%${search.toUpperCase()}%'
            or count::text like '%${search.toUpperCase()}%'
        ` : `
            where  ${expr(field, type, formmat)}::text like '%${search.toUpperCase()}%'
        `;
        
        return new Promise<any>(async(resolve, reject) => {
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql: `
                    select * from (
                        select i.*, e.nome as nomeempresa, l.filial, l.nome as nomeloja from informacaobackup i
                        left join empresas e on e.codigo = i.empresa
                        left join lojas l on l.empresa=i.empresa and l.codigo=i.loja and regexp_replace(l.cnpj, '[^0-9]', '', 'gi') = regexp_replace(i.cnpj, '[^0-9]', '', 'gi')
                    ) as con1
                                    
                    ${where}
                    order by ${order}, datai               
                `,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: "" 
            })
    
            resolve({
                data: result?.data?.data,
                page: result?.data?.page-1,
                totalCount: result?.data?.total
            });
        })   
    }
    addVersao() {
    }
    removerVersao(idversao: number) {
        this.showConfirm("Confirma a remoção ?", async () => {
            let r: IControllerResult = await NocRequest.post("noc/delversao", {
                linhaproduto: this.props.linhaproduto,
                idversao
            });
            if (this.isOk(r)) {
                this.showAlert("Versão removida com sucesso.");
            }
            else
                this.showAlert(this.getMessage(r));
    
        });
    }
    removerVersao1() {

    }
    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    actions={[
                    ]}
                    columns={[
                        { title: "Empresa", field: "empresa" },
                        { title: "Nome", field: "nomeempresa" },
                        { title: "CNPJ", field: "cnpj" },
                        { title: "Loja", field: "nomeloja" },
                        { title: "Data Inicial", field: "datai", render: Formatter.date("datai") },
                        { title: "Data Final", field: "dataf", render: Formatter.date("dataf") },
                        { title: "Realizado", field: "realizado" },
                        { title: "Quant.", field: "count" },
                    ]}
                    fieldsSelect={[
                        "empresa", "nomeempresa", "cnpj", "nomeloja", "datai", "dataf", "count"
                    ]}
                    actionBarFreeWidth={2}
                    actionColumnWidth={25}
                    actionsColumns={20}
                    pageSize={30}
                    data={this.consulta}
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     minBodyHeight: 'calc(100vh - 200px)',
                    //     maxBodyHeight: 'calc(100vh - 200px)',
                    //     pageSize: 30,
                    //     pageSizeOptions: [9, 20, 30, 50, 100],
                    //     search: true,
                    // }}
                    // search2={true}
                    search={true}
                    // onSearchChange={(txt: string) => { console.log(txt); }}
                    // onQueryChange={() => { console.log('query changed') }}

                    title="Informações de Backup's"
                    subtitle="Registro de informação da realização de backup"
                    // onRowClick={this.rowClick/*RowClick*/}
                    // localization={{
                    //     header: {
                    //         actions: ""
                    //     }
                    // }}
                    
                />
                {this.baseRender()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
        SetLinhaProduto: (linhaproduto: string) => dispatch(SetLinhaProduto(linhaproduto)),
    };
}

export default connect(mapStateToProps)(InformacaoBackup);