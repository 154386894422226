import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { SetLinhaProduto } from "../../reducer/ActionsCreator";
import { Store } from "../../reducer/Reducer";
import Component2, { EmptyBaseState } from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import { withRouter } from "react-router-dom";
import Tabela2, { ITableQuery } from "../Tabela/Tabela2";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate?: AppState;
    linhaproduto: string;
    SetLinhaProduto?: (linhaproduto: string) => void;
}
interface IVersao {
    [Name: string]: string;
}
interface IState {
    versoes: IVersao;
}
const sistema = (field: string) => (data: any) => {
    let c = data[field];
    return (c == -1 ? <span title={data['vc_'+field] ? "Versão "+data['vc_'+field] : "Versão não informada"} style={{color: 'red'}}>Sim</span> : (c == 1 ? "" : 
                      <span title={data['vc_'+field] ? "Versão "+data['vc_'+field] : "Versão não informada"} style={{color: 'green'}}>Não</span>))
}
class SistemasDesatualizados extends PrivateComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.state = {
            ...EmptyBaseState,
            versoes: {},
        }
    }
    componentDidMountPrivate() {
        this.getultimaversao();

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    async getultimaversao() {
        let result: any = await NocRequest.post("entity/sqlpage", {
            sql: `
                select * from  (
                    select idversao, 
                        versao[buscaindice(codigosistema,'banco')] as banco, 
                        versao[buscaindice(codigosistema,'icompany')] as icompany, 
                        versao[buscaindice(codigosistema,'wcompany')] as wcompany, 
                        versao[buscaindice(codigosistema,'ipdv')] as ipdv, 
                        versao[buscaindice(codigosistema,'imendes')] as imendes, 
                        versao[buscaindice(codigosistema,'ibalanco')] as ibalanco, 
                        versao[buscaindice(codigosistema,'nfedanfe')] as nfedanfe, 
                        versao[buscaindice(codigosistema,'xboleto')] as xboleto, 
                        versao[buscaindice(codigosistema,'nfemonitor')] as nfemonitor,
                        versao[buscaindice(codigosistema,'xport')] as xport, 
                        versao[buscaindice(codigosistema,'xpdv')] as xpdv, 
                        versao[buscaindice(codigosistema,'xposto')] as xposto
                    from (
                        select 
                            v.idversao, 
                            array_agg(v.codigosistema) as codigosistema, 
                            array_agg(t.descricao) as sistema, 
                            array_agg(v.linhaproduto) as linhaproduto, 
                            array_agg(v.versao) as versao 
                        from versoes v
                        left join tiposistema t on (t.linhaproduto,t.codigo)=(v.linhaproduto,v.codigosistema)
                        where v.linhaproduto = '${this.props.linhaproduto}' and
                            idversao = (select max(idversao) from versoes where linhaproduto = '${this.props.linhaproduto}')
                        group by idversao 
                        order by idversao desc
                    ) as cc 
                ) as con            
            `,
            page: 1,
            pageSize: 100,
            search: "",
            orderBy: ""
        });

        if (result.data && result.data.length > 0) {
            let v: any = this.state.versoes;
            for (let c in result.data[0]) {
                if ((typeof result.data[0][c] !== "function") && (typeof result.data[0][c] !== null)) {
                    v[c] = result.data[0][c];
                }
            }
            this.estado({versoes: v});
        }
        //console.log(result)

    }
    async consulta(query: ITableQuery) {
        let order = (query?.orderBy?.field || 'empresa') + ' ' + (query?.orderDirection || '');
        let search: string = query?.search || '';
        let field: string = query?.field || '';
        let where: string = "";
        where = field == "" ? `
            where  false
            or cnpj::text like '%${search.toUpperCase()}%'
            or filial::text like '%${search.toUpperCase()}%'
            or empresa::text like '%${search.toUpperCase()}%'
        ` : `
            where  ${field}::text like '%${search.toUpperCase()}%'
        `;
        
        return new Promise<any>(async(resolve, reject) => {
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql: `
                    select * from (
                        select * from (
                            select empresa, cnpj, filial, idversao, linhaproduto, 
                                atualizado[buscaindice(codigosistema,'banco')] as banco, 
                                atualizado[buscaindice(codigosistema,'icompany')] as icompany, 
                                atualizado[buscaindice(codigosistema,'wcompany')] as wcompany, 
                                atualizado[buscaindice(codigosistema,'ipdv')] as ipdv, 
                                atualizado[buscaindice(codigosistema,'imendes')] as imendes, 
                                atualizado[buscaindice(codigosistema,'ibalanco')] as ibalanco, 
                                atualizado[buscaindice(codigosistema,'nfedanfe')] as nfedanfe, 
                                atualizado[buscaindice(codigosistema,'xboleto')] as xboleto, 
                                atualizado[buscaindice(codigosistema,'nfemonitor')] as nfemonitor,
                                atualizado[buscaindice(codigosistema,'xport')] as xport, 
                                atualizado[buscaindice(codigosistema,'xpdv')] as xpdv, 
                                atualizado[buscaindice(codigosistema,'xposto')] as xposto,

                                versaoatual[buscaindice(codigosistema,'banco')] as va_banco, 
                                versaoatual[buscaindice(codigosistema,'icompany')] as va_icompany, 
                                versaoatual[buscaindice(codigosistema,'wcompany')] as va_wcompany, 
                                versaoatual[buscaindice(codigosistema,'ipdv')] as va_ipdv, 
                                versaoatual[buscaindice(codigosistema,'imendes')] as va_imendes, 
                                versaoatual[buscaindice(codigosistema,'ibalanco')] as va_ibalanco, 
                                versaoatual[buscaindice(codigosistema,'nfedanfe')] as va_nfedanfe, 
                                versaoatual[buscaindice(codigosistema,'xboleto')] as va_xboleto, 
                                versaoatual[buscaindice(codigosistema,'nfemonitor')] as va_nfemonitor,
                                versaoatual[buscaindice(codigosistema,'xport')] as va_xport, 
                                versaoatual[buscaindice(codigosistema,'xpdv')] as va_xpdv, 
                                versaoatual[buscaindice(codigosistema,'xposto')] as va_xposto,

                                versaocliente[buscaindice(codigosistema,'banco')] as vc_banco, 
                                versaocliente[buscaindice(codigosistema,'icompany')] as vc_icompany, 
                                versaocliente[buscaindice(codigosistema,'wcompany')] as vc_wcompany, 
                                versaocliente[buscaindice(codigosistema,'ipdv')] as vc_ipdv, 
                                versaocliente[buscaindice(codigosistema,'imendes')] as vc_imendes, 
                                versaocliente[buscaindice(codigosistema,'ibalanco')] as vc_ibalanco, 
                                versaocliente[buscaindice(codigosistema,'nfedanfe')] as vc_nfedanfe, 
                                versaocliente[buscaindice(codigosistema,'xboleto')] as vc_xboleto, 
                                versaocliente[buscaindice(codigosistema,'nfemonitor')] as vc_nfemonitor,
                                versaocliente[buscaindice(codigosistema,'xport')] as vc_xport, 
                                versaocliente[buscaindice(codigosistema,'xpdv')] as vc_xpdv, 
                                versaocliente[buscaindice(codigosistema,'xposto')] as vc_xposto

                            from (
                                select empresa, cnpj, filial, idversao, linhaproduto, array_agg(codigosistema) as codigosistema, array_agg(atualizado) as atualizado, array_agg(versaoatual) as versaoatual, array_agg(versaocliente) as versaocliente from (
                                    select l.empresa, l.cnpj, l.filial, v.idversao, v.linhaproduto, v.codigosistema, v.versao as versaoatual, s.versao as versaocliente, compare_versao(s.versao, v.versao) as atualizado
                                    from lojas l 
                                    left join (
                                        select max(idversao) as idversao, linhaproduto from versoes group by linhaproduto
                                    ) as con on con.linhaproduto = '${this.props.linhaproduto}'
                                    left join versoes v on v.linhaproduto = con.linhaproduto and v.idversao= con.idversao --and v.codigosistema = s.codigosistema
                                    left join sistemas s on (s.empresa,s.cnpj,s.loja,s.codigosistema)=(l.empresa,l.cnpj,l.codigo,v.codigosistema)
                                    left join tiposistema t on (t.linhaproduto,t.codigo)=(s.linhaproduto,s.codigosistema)
                                ) as conatu
                                group by empresa, cnpj, filial, linhaproduto, idversao
                            ) as cc 
                        ) as con
                        where idversao = (select max(idversao) from versoes where linhaproduto = '${this.props.linhaproduto}')
                            and (
                                coalesce(banco,0) <> 0 or
                                coalesce(icompany,0) <> 0 or
                                coalesce(wcompany,0) <> 0 or
                                coalesce(ipdv,0) <> 0 or
                                coalesce(imendes,0) <> 0 or
                                coalesce(ibalanco,0) <> 0 or
                                coalesce(nfedanfe,0) <> 0 or
                                coalesce(xboleto,0) <> 0 or
                                coalesce(nfemonitor,0) <> 0 or
                                coalesce(xport,0) <> 0 or
                                coalesce(xpdv,0) <> 0 or
                                coalesce(xposto,0) <> 0
                                )
                    ) as con2
                    ${where}
                    order by ${order}, empresa, filial               
                `,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: ""
            })
            
            resolve({
                data: result?.data?.data,
                page: result?.data?.page-1,
                totalCount: result?.data?.total
            });
        })   
    }
    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2

                    columns={this.props.linhaproduto == "linha1" ? [
                        { title: "Empresa", field: "empresa" },
                        { title: "CNPJ", field: "cnpj" },
                        { title: "Loja", field: "filial" },
                        { title: "Banco\n"+(this.state.versoes['banco']||''), field: "banco", render: sistema("banco"), },
                        { title: "iCompany\n"+(this.state.versoes['icompany']||''), field: "icompany", render: sistema("icompany"), },
                        { title: "wCompany\n"+(this.state.versoes['wcompany']||''), field: "wcompany", render: sistema("wcompany"), },
                        { title: "iPDV\n"+(this.state.versoes['ipdv']||''), field: "ipdv", render: sistema("ipdv"), },
                        { title: "iBalanco\n"+(this.state.versoes['ibalanco']||''), field: "ibalanco", render: sistema("ibalanco"), },
                        { title: "NFe DANFE\n"+(this.state.versoes['nfedanfe']||''), field: "nfedanfe", render: sistema("nfedanfe"), },
                        { title: "NFe Monitor\n"+(this.state.versoes['nfemonitor']||''), field: "nfemonitor", render: sistema("nfemonitor"), },
                        { title: "xBoleto\n"+(this.state.versoes['xboleto']||''), field: "xboleto", render: sistema("xboleto"), },
                        { title: "xPort\n"+(this.state.versoes['xport']||''), field: "xport", render: sistema("xport"), },
                        { title: "iMendes\n"+(this.state.versoes['imendes']||''), field: "imendes", render: sistema("imendes"), },
                    ]: [
                        { title: "Empresa", field: "empresa" },
                        { title: "CNPJ", field: "cnpj" },
                        { title: "Loja", field: "loja" },
                        { title: "Filial", field: "filial" },
                        { title: "Banco\n"+(this.state.versoes['banco']||''), field: "banco", render: sistema("banco"), },
                        { title: "xPDV\n"+(this.state.versoes['xpdv']||''), field: "xpdv", render: sistema("xpdv"), },
                        { title: "xPosto\n"+(this.state.versoes['xposto']||''), field: "xposto", render: sistema("xposto"), },
                    ]}
                    fieldsSelect={[
                        "empresa", "cnpj", "filial"
                    ]}
                    data={this.consulta}
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     minBodyHeight: 'calc(100vh - 200px)',
                    //     maxBodyHeight: 'calc(100vh - 200px)',
                    //     pageSize: 30,
                    //     pageSizeOptions: [9, 20, 30, 50, 100],
                    //     search: true,
                    // }}
                    // search2={true}
                    search={true}
                    pageSize={20}
                    // onSearchChange={(txt: string) => { console.log(txt); }}
                    // onQueryChange={() => { console.log('query changed') }}

                    title={"Sistemas Desatualizados " + (this.props.linhaproduto == "linha1" ? "Linha 1" : "Linha 2")}

                    
                />
                {this.baseRender()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
        SetLinhaProduto: (linhaproduto: string) => dispatch(SetLinhaProduto(linhaproduto)),
    };
}

const CSistemasDesatualizados = connect(mapStateToProps)(SistemasDesatualizados);
export default CSistemasDesatualizados;
export const SistemasDesatualizadosLinha1 = () => <CSistemasDesatualizados linhaproduto="linha1"/>;
export const SistemasDesatualizadosLinha2 = () => <CSistemasDesatualizados linhaproduto="linha2"/>;
