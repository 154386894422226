import React, { Component, forwardRef, ChangeEvent, EventHandler, KeyboardEvent } from "react";
import MaterialTable, { MaterialTableProps } from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import { ListItemAvatar, Typography, Input, IconButton, TextField, InputLabel, InputAdornment, Select, MenuItem, Icon, Button, Chip } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";

import Key from "../../utils/Keys";
import TabelaGridContainer from "../Tabela/TabelaGridContainer";
import TabelaGridItem from "../Tabela/TabelaGridItem";
import Card from "../../ui/components/Card/Card";
import CardHeader from "../../ui/components/Card/CardHeader";
import CardBody from "../../ui/components/Card/CardBody";
import Table from "../../ui/components/Table/Table";
import TabelaTable, { TTabelaTableDetailFunction } from "../Tabela/TabelaTable";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { IFormProps, IFormField, OldState, NewState } from "./FormularioTypes";
import { EmptyBaseState } from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import CustomInput from "../../ui/components/CustomInput/CustomInput";
import EditBox from "../EditBox/EditBox";
import SelectNovo2 from "../SelectNovo/SelectNovo2";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ChipGroup from "./ChipGroup";

const tableIcons: any = {
    Add: forwardRef((props, ref: any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref: any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref: any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref: any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref: any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref: any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref: any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref: any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref: any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref: any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref: any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref: any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref: any) => <ViewColumn {...props} ref={ref} />),
    AddCliente: forwardRef((props, ref: any) => <AddCircleIcon {...props} ref={ref} />),
};

export interface ITableColumn {
    title: string;
    field: string;
    type?: "string" | "number" | "numeric" | "date";
    render?: (rowData: any) => React.ReactElement | any;
}
export interface ITableQuery {
    orderBy: {
        field: string;
    };
    field: string;
    orderDirection: string;
    search: string;
    fieldtype: string;
    fieldformat: string;
    page: number;
    pageSize: number;
}
export interface ITableAction {
    /**
     * Indica se a ação é independente do registro da tabela (Ação global)
     */
    isFreeAction?: boolean;
    /**
     * Icone associado com a ação
     */
    icon: React.ReactNode | string;
    /**
     * 
     */
    onClick: (rowData: any) => Promise<void> | void;
}

interface IProps extends IFormProps {
    goBack?: () => void;
}
interface IRefs {
    [RefName: string]: React.RefObject<any>;
}
interface IValores {
    [Name: string]: any;
}
interface IState {
    valores: IValores;
    valoresIniciais: IValores;
}
export class FormularioClass extends PrivateComponent<IProps, IState> {
    Refs: IRefs;
    mounted: boolean;
    constructor(props: IProps) {
        super(props);
        this.voltar = this.voltar.bind(this);
        this.onchange = this.onchange.bind(this);
        this.changeedit = this.changeedit.bind(this);
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.remove = this.remove.bind(this);
        this.Refs = {};

        let val: IValores = {};
        let valInicial: IValores = {};

        for (let f of this.Campos()) {

            this.Refs[f.name] = React.createRef();
            val[f.name] = "";
            valInicial[f.name] = "";
            if (f.defaultValue !== undefined) {
                val[f.name] = f.defaultValue;
                valInicial[f.name] = f.defaultValue;
            }
        }
        this.state = {
            ...EmptyBaseState,
            valores: val,
            valoresIniciais: valInicial,
        };
        this.mounted = false;
    }
    voltar() {
        if ((this.props.caller || '') != "")
            this.redirectTo(this.props.caller || '');
        else if (this?.props?.goBack)
            this?.props?.goBack();
        else 
            this?.props?.history?.goBack();
    }
    Campos(): IFormField[] {
        return this.props.Campos || [];
    }
    async componentDidMountPrivate() {
        // for (let c = 0; c <= this.Campos().length-1; c++) {
        //     let combo = (this.Campos()[c].comboItensDef || {}) as any;
        //     let comboDef = this.Campos()[c].comboItensDef as any;
        //     if ((this.Campos()[c].type == "combo") && (typeof combo == "object") && (combo.sql)) {
        //         let r: any[] = await NocRequest.post(`entity/sql`, {
        //             sql: (this.Campos()[c].comboItensDef as any).sql
        //         });
        //         if (! this.isError(r)) {
        //             let it = [];
        //             for (let c of r) {
        //                 let value = '';
        //                 if (c[comboDef.valueField]) value = c[comboDef.valueField];
        //                 else {
        //                     value = comboDef.valueField;
        //                     for (let c1 in c) {
        //                         value = (""+value).replace("$"+c1, c[c1]);
        //                     }
        //                 }
        //                 it.push([c[comboDef.keyField], value]);
        //             }
        //             this.Campos()[c].comboItens = it; 
        //             this.estado({});
        //         }
        //         else
        //             this.Campos()[c].comboItens = []; 
        //     }
        //     else if ((this.Campos()[c].type == "combo") && (typeof combo == "object") && (combo.data)) {
        //         let r: any[] = await NocRequest.get(`entity/${this.props.entity}/${this.getParam("id")}`);    
        //         if (! this.isError(r)) {
        //             let it = [];
        //             /*for (let c of r) {
        //                 it.push([c[comboDef.keyField], c[comboDef.valueField]]);
        //             }*/

        //             for (let c of r) {
        //                 let value = '';
        //                 if (c[comboDef.valueField]) value = c[comboDef.valueField];
        //                 else {
        //                     value = comboDef.valueField;
        //                     for (let c1 in c) {
        //                         value = (""+value).replace("$"+c1, c[c1]);
        //                     }
                            
        //                 }
        //                 it.push([c[comboDef.keyField], value]);
        //             }
        //             this.Campos()[c].comboItens = it; 

        //             this.estado({});
        //         }
        //         else
        //             this.Campos()[c].comboItens = []; 
        //     }
        //     else if ((this.Campos()[c].type == "combo") && (typeof combo == "object") && (combo instanceof Array)) {
        //         this.Campos()[c].comboItens = [...(this.Campos()[c].comboItensDef as any[])]; 
        //     }
        // }
        if (this.getParam("id") != "") {
            let r = await NocRequest.get(`entity/${this.props.entity}/${this.getParam("id")}`);
            this.estado({valores: {...r}, valoresIniciais: {...r}});
        }
        else {
            this.estado({valores: {...this.state.valores}, valoresIniciais: {...this.state.valores}});
        }
        
    }
    static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        /*if ((typeof nextProps.data !== 'function') && (nextProps.data != prevState.data)) {
            return {
                data: nextProps.data,
                dataOriginal: nextProps.data,
                totalRows: nextProps.data.length
            }
        }
        else if (nextProps.refreshCount != prevState.refreshCount) {
            return {
                refreshCount: nextProps.refreshCount,
            }
        }*/
        return null;
    }

    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    onchange(ev: any) {
        const {name, value} = ev.target as any;
        console.log(name, value);
        let s: IState = this.state;
        s.valores[name] = value;
        this.setState(s);
    }
    changeedit(name: string, value: string, value2: string) {
        console.log(name, value);
        let s: IState = this.state;
        s.valores[name] = value;
        //s.valores[name+'2'] = value2;
        this.setState(s);
    }

    validate(): boolean {
        let r = true;
        let msg = "";
        let empty: string[] = [];
        let fields: string[] = [];
        for (let f of this.Campos()) {
            let minLength = f.minLength||0;
            if ((f.required === true)  && (this.state.valores[f.name] == "")) {
                r = false;
                empty.push(`Campo ${f.caption} deve ser preenchido.`);
                fields.push(f.name);
            }
            if ((minLength > 0) && (this.state.valores[f.name].length < minLength)) {
                r = false;
                empty.push(`Tamanho do campo ${f.caption} inferior ao mínimo permitido (${f.minLength}).`);
                fields.push(f.name);
            }
        }

        if (empty.length > 0) {
            this.Refs[fields[0]].current.focus();
            this.showAlert(empty);
        }

        return r;
    }
    async dobeforepost(oldstate: OldState): Promise<NewState|false> {
        try {
            if (this.props.onBeforePost) {
                let st = await this.props.onBeforePost(this, oldstate);
                if ((st === false) || (st === null))
                    return false;
                else if (st === undefined)
                    return oldstate;
                else if (st) {
                    return st;
                }
                else 
                    return oldstate;
            }
            else
                return oldstate;
        }
        catch(e: any) {
            this.showAlert(e.message);
            return false;
        }
        return oldstate;
    }
    async doafterpost(oldstate: OldState): Promise<void> {
        try {
            if (this.props.onAfterPost) {
                await this.props.onAfterPost(this, oldstate);
            }
        }
        catch(e) {
        }
    }
    async submit() {
        if (this.validate()) {
            let val = {...this.state.valores};
            for (let f of this.Campos()) {
                if ((f.type === "date") && (val[f.name] == "")) {
                    val[f.name] = null;
                }
            }
            // console.log('valores', val);
            let val2;
            if (val = await this.dobeforepost(val)) {
                this.showConfirm("Confirma a gravação dos dados ?", async () => {
                    let r = await NocRequest.post(`entity/${(this.getParam("id") != "" ? "update" : "insert")}/${this.props.entity}`, {
                        ...val,
                    });
                    if (! this.isError(r)) {
                        await this.doafterpost(val);
                        this.showAlert("Registro salvo com sucesso", () => {
                            this.voltar();
                        });
                    }
                    else {
                        this.showAlert("Ocorreu um erro durante a operação\n" + r.message);
                    }
                }); 
            }
        }
    }
    async remove() {
        if (this.validate()) {
            let val = this.state.valores;
            let val2;
            if (val = await this.dobeforepost(val)) {
                this.showConfirm("Confirma a removeção dos dados ?", async () => {
                    let r = await NocRequest.post(`entity/delete/${this.props.entity}`, {
                        ...val,
                    });
                    if (! this.isError(r)) {
                        await this.doafterpost(val);
                        this.showAlert("Registro removido com sucesso", () => {
                            this.voltar();
                        });
                    }
                    else {
                        this.showAlert("Ocorreu um erro durante a operação\n" + r.message);
                    }
                }); 
            }
        }
    }
    renderPrivate() {
        let Cells: any[] = [];
        this.Campos().forEach((f: IFormField, index: number) => {
            let type = (f.type || "string");
            let mask = (f.mask || "");
            if (type == "space") {
                Cells.push([
                    <TabelaGridItem xs={12} sm={12} md={f.widthField||1}>
                        
                    </TabelaGridItem>,
                ]);
            }
            else if (((type == "string") || (type == "date")) && (mask == "")) {
                Cells.push([
                    <TabelaGridItem xs={12} sm={12} md={f.widthField||12}>
                        <CustomInput
                            inputRef={this.Refs[f.name]} name={f.name}
                            disabled={f.disabled === true}
                            labelText={f.caption}
                            helpText={f.helpText || f.caption || ""}
                            id={f.name}
                            formControlProps={{fullWidth: true}}
                            onChange={this.onchange} 
                            value={this.state.valores[f.name]} 
                            //onKeyDown={this.keydown}
                            required={f.required}
                        />                                
                    </TabelaGridItem>,
                ]);
            }
            else if (type == "multiline") {
                Cells.push([
                    <TabelaGridItem xs={12} sm={12} md={f.widthField||12}>
                        <CustomInput
                            inputRef={this.Refs[f.name]} name={f.name}
                            disabled={f.disabled === true}
                            labelText={f.caption}
                            helpText={f.helpText || f.caption || ""}
                            id={f.name}
                            formControlProps={{fullWidth: true}}
                            onChange={this.onchange} 
                            value={this.state.valores[f.name]} 
                            //onKeyDown={this.keydown}
                            required={f.required}
                            multiline={f.multiline}
                            multilineRows={f.multilineRows}
                        />                                
                    </TabelaGridItem>,
                ]);
            }
            else if (type == "password") {
                Cells.push([
                    <TabelaGridItem xs={12} sm={12} md={f.widthField||12}>
                        <CustomInput
                            inputRef={this.Refs[f.name]} name={f.name}
                            disabled={f.disabled === true}
                            labelText={f.caption}
                            helpText={f.helpText || f.caption || ""}
                            id={f.name}
                            formControlProps={{fullWidth: true}}
                            onChange={this.onchange} 
                            value={this.state.valores[f.name]} 
                            //onKeyDown={this.keydown}
                            required={f.required}
                            inputProps2={{
                                type: "password"
                            }}
                        />                                
                    </TabelaGridItem>,
                ]);
            }
            else if ((type == "string") && (mask != "")) {
                Cells.push([
                    <TabelaGridItem xs={12} sm={12} md={f.widthField||12}>
                        <EditBox inputRef={this.Refs[f.name]} 
                            className={"formControl col-12"}
                            name={f.name}
                            disabled={f.disabled === true}
                            value={this.state.valores[f.name]} 
                            value2={this.state.valores[f.name]} 
                            //onChange={this.onchange}
                            dataChanged={this.changeedit}
                            mask={f.mask || ""}
                            maskFillChar={f.maskFillChar || " "}
                            saveLiteralChar={f.maskSaveLiteralChar || false}
                        />
                    </TabelaGridItem>,
                ]);
            }
            else if (type == "number") {
                Cells.push([
                    <TabelaGridItem xs={12} sm={12} md={f.widthField||12}>
                        <CustomInput
                            inputRef={this.Refs[f.name]} name={f.name}
                            disabled={f.disabled === true}
                            labelText={f.caption}
                            helpText={f.caption}
                            id={f.name}
                            formControlProps={{fullWidth: true}}
                            onChange={this.onchange} 
                            value={this.state.valores[f.name]} 
                            //onKeyDown={this.keydown}
                            required={f.required}
                            onlynumbers={true}
                        />                                
                    </TabelaGridItem>,
                ]);
            }
            else if (type == "combo") {
                Cells.push([
                    <TabelaGridItem xs={12} sm={12} md={f.widthField||12}>
                            <SelectNovo2
                                inputRef={this.Refs[f.name]} 
                                name={f.name}
                                helpText={f.caption}
                                placeHolderText={f.caption}
                                data={f.comboItensDef as any}
                                value={this.state.valores[f.name]}
                                onChange={this.onchange} 
                                //onKeyDown={this.keydown}
                                required={f.required}
                            />
                    </TabelaGridItem>,
                ]);
            }
            else if (type == "chipgroup") {
                Cells.push([
                    <TabelaGridItem xs={12} sm={12} md={f.widthField||12}>
                            <ChipGroup
                                name={f.name}
                                chipLabels={f.chipGroupLabels || []}
                                chipValues={f.chipGroupValues || []}
                                chipValueSeparator={f.chipValueSeparator || ","}
                                value={this.state.valores[f.name]}
                                onChange={this.onchange} 
                            />
                    </TabelaGridItem>,
                ]);
            }
        });
        
        // if (Cells.length % 2 != 0) {
        //     Cells.push([
        //         <TableCell className="formapag col-3">&nbsp;</TableCell>,
        //         <TableCell className="formapag col-9">&nbsp;</TableCell>,
        //     ]);
        // }

        return (
            <div className={"container-fluid col-"+(this.props.formWidth || 12)}>
                
                <TabelaGridContainer>
                    <TabelaGridItem xs={12} sm={12} md={12}>
                        <Card>
                            {this.props.disableHeader !== true ?
                            <CardHeader color=""
                                title={this.props.formTitle}
                                subtitle={this.props.formSubTitle}
                            >
                            </CardHeader>
                            : null}
                            <CardBody>


                                <TabelaGridContainer>
                                    {Cells.map((el: any) => {
                                        return el;
                                    })}
                                </TabelaGridContainer>  

                                <TabelaGridContainer>
                                    <TabelaGridItem xs={12} sm={12} md={12}>
                                    <Button color="primary" variant="contained" onClick={this.submit}>Salvar</Button>
                                    &nbsp;<Button color="default" variant="contained" onClick={this.voltar}>Voltar</Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {this.getParam("id") != "" ? 
                                    <Button color="secondary" variant="contained" onClick={this.remove}>Remover</Button>
                                    : null}

                                    </TabelaGridItem>
                                </TabelaGridContainer>            


                            </CardBody>
                        </Card>
                    </TabelaGridItem>
                </TabelaGridContainer>

                {this.baseRender()}
            </div>
        );
    }
}
const mapStateToProps = () => {
    return {};
}
export default withRouter(connect(mapStateToProps)(FormularioClass));
