import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { withRouter } from "react-router-dom";
import Tabela2 from "../Tabela/Tabela2";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddCliente: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

const consulta = (query: any) => {
    let order = (query?.orderBy?.field || 'certificado_val') + ' ' + (query?.orderDirection || 'desc');
    let search: string = query?.search || '';
    let field: string = query?.field || '';
    let where: string = "";
    where = field == "" ? `
        where (certificado_val <= to_char(current_date, 'yyyy-mm-dd')) and
        (
        empresa like '%${search.toUpperCase()}%'
        or codigo like '%${search.toUpperCase()}%'
        or filial like '%${search.toUpperCase()}%'
        or nome like '%${search.toUpperCase()}%'
        or certificado_val like '%${search.toUpperCase()}%'
        or certificado_num like '%${search.toUpperCase()}%'
        )
    ` : `
        where  ${field} like '%${search.toUpperCase()}%'
    `;
    
    return new Promise<any>(async(resolve, reject) => {
        let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
            sql: `
                select * from (
                    select *
                    from lojas                      
                    ) as con
                    ${where}
                order by ${order}               
            `,
            page: query.page,
            pageSize: query.pageSize,
            search: query.search,
            orderBy: ""
        })

        resolve({
            data: result?.data?.data,
            page: result?.data?.page-1,
            totalCount: result?.data?.total
        });
    })   
}
interface IProps {
    selecao?: boolean;
    SetClienteVenda: (codigocliente: number, nome: string) => void;
}
class CertificadosVencidos extends PrivateComponent<IProps, any> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    columns={[
                        { title: "Empresa", field: "empresa" },
                        { title: "Codigo", field: "codigo" },
                        { title: "Filial", field: "filial" },
                        { title: "Nome", field: "nome" },
                        { title: "Serial", field: "certificado_num" },
                        { title: "Validade", field: "certificado_val" },
                        
                    ]}
                    fieldsSelect={[
                        "empresa", "codigo", "filial", "certificado_num","certificado_val"
                    ]}
                    data={consulta}
                    orderByField="certificado_val"
                    orderByDirection="desc"
                    pageSize={30}
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     minBodyHeight: 'calc(100vh - 200px)',
                    //     maxBodyHeight: 'calc(100vh - 200px)',
                    //     pageSize: 30,
                    //     pageSizeOptions: [9, 20, 30, 50, 100],
                    //     search: true,
                    // }}
                    // search2={true}
                    // onSearchChange={(txt: string) => { console.log(txt); }}
                    // onQueryChange={() => { console.log('query changed') }}

                    title="Certificados Vencidos"
                    subtitle="Listagem de certificados vencidos"
                    // onRowClick={this.rowClick/*RowClick*/}
                    // localization={{
                    //     header: {
                    //         actions: ""
                    //     }
                    // }}
                    printReport={true}
                    actionBarFreeWidth={2}
                    actionsColumns={2}
                    actionColumnWidth={25}
                    reportSql={"select * from lojas where (certificado_val <= to_char(current_date, 'yyyy-mm-dd')) order by certificado_val desc"}
                    reportTitle={"CERTIFICADOS VENCIDOS"}
                    reportColumns={[
                        {
                            name: "empresa",
                            title: "Empresa",
                            align: "left",
                            type: "string"
                        },
                        {
                            name: "cnpj",
                            title: "CNPJ",
                            align: "left",
                            type: "string"
                        },
                        {
                            name: "filial",
                            title: "Loja",
                            align: "left",
                            type: "string"
                        },
                        {
                            name: "certificado_val",
                            title: "Validade",
                            align: "left",
                            type: "string"
                        },
                    ]}
                    
                />

            </div>
        );
    }
}

// const mapStateToProps = (state: any, ownProps: any) => {
//     return state;
// }
// const mapDisptachToProps = (dispatch: any, ownProps: any) => {
//     return {
//         //SetClienteVenda: (codigocliente: number, nome: string) => dispatch(SetClienteVenda(codigocliente, nome)),
//         ...ownProps,
//     };
// }

export default CertificadosVencidos;