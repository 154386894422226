import React, { Component, forwardRef, createRef } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from "@material-ui/core";

interface IState {
}
interface IProps {
    open: boolean;
    title: string;
    message: string|string[];
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    dialogClose: () => void;
}

class MessageBox extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        
        this.state = {
        }
    }
    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.dialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={this.props.size}
                    fullWidth={this.props.size !== false}
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title || ""}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {(! (this.props.message instanceof Array) ? [this.props.message] : 
                            this.props.message).map((el: string) => {
                                return <Typography>{el}</Typography>
                            })}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.dialogClose} color="primary" autoFocus>
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


export default MessageBox;