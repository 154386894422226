import React, { Component, forwardRef, ChangeEvent, KeyboardEvent, useMemo } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Input, Button, Select, MenuItem } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { ITiposistema } from "../../persistence/interfaces/ITiposistema";
import { EmptyBaseState } from "../Component2/Component2";
import { RecordWithTtl } from "dns";
import MessageBox from "../MessageBox/MessageBox";
import NumericInput from "../NumericInput/NumericInput";
import { connect } from "react-redux";
import { AppState } from "../../reducer/ReducerTypes";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import { ISprint } from "../../persistence/interfaces/ISprint";
import { withRouter } from "react-router-dom";
import TabelaGridContainer from "../Tabela/TabelaGridContainer";
import TabelaGridItem from "../Tabela/TabelaGridItem";
import Card from "../../ui/components/Card/Card";
import CardHeader from "../../ui/components/Card/CardHeader";
import CardBody from "../../ui/components/Card/CardBody";
import Formulario, { FormularioClass } from "../Formulario/Formulario";
import Combos from "../Formulario/Combos";
import { FormFuncs, OldState } from "../Formulario/FormularioTypes";
import md5 from "md5";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate: AppState;
}
interface IState {
}
class AddAtualizacaoAutomatica extends PrivateComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        //this.onBeforePost = this.onBeforePost.bind(this);
        this.state = {
            ...EmptyBaseState,
        }
    }
    componentDidMountPrivate() {
    }
    renderPrivate() {
        return (
            <Formulario
                formWidth={8}
                formTitle="Atualização Automática"
                entity="Nocatualizador"
                goBack={() => this.redirectTo("/admin/atualizacaoautomatica")}
                Campos={[
                    {
                        type: "string",
                        name: "id",
                        caption: "ID",
                        widthField: 1,
                        disabled: true,
                    },
                    {
                        type: "string",
                        name: "empresa",
                        caption: "Empresa",
                        helpText: "Código empresa para atualização",
                        widthField: 3,
                    },
                    {
                        type: "string",
                        name: "loja",
                        caption: "Loja",
                        helpText: "Código da loja para atualização",
                        widthField: 3,
                    },
                    {
                        type: "string",
                        name: "cnpj",
                        caption: "CNPJ",
                        helpText: "CNPJ da loja para atualização",
                        widthField: 5,
                    },

                    {
                        type: "string",
                        name: "uuid",
                        caption: "UUID",
                        widthField: 12,
                        disabled: true,
                    },

                    {
                        type: "string",
                        name: "agenda_minuto",
                        caption: "Minuto",
                        helpText: "Minuto do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "string",
                        name: "agenda_hora",
                        caption: "Hora",
                        helpText: "Hora do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "string",
                        name: "agenda_dia",
                        caption: "Dia",
                        helpText: "Dia do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "string",
                        name: "agenda_mes",
                        caption: "Mês",
                        helpText: "Mês do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "string",
                        name: "agenda_ano",
                        caption: "Ano",
                        helpText: "Ano do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "string",
                        name: "agenda_diasemana",
                        caption: "Dia Semana",
                        helpText: "Dia da Semana do agendamento para execução da tarefa. Ex: * | */2 | 1-4 | 1-4,8,12",
                        widthField: 2,
                        defaultValue: "*",
                    },
                    {
                        type: "combo",
                        name: "ativo",
                        caption: "Ativo",
                        helpText: "Indica se a entrada de atualização esta Ativa",
                        comboItensDef: Combos.SimNao(1, 0),
                        widthField: 3, 
                        defaultValue: 0,
                    },

                    {
                        type: "combo",
                        name: "concluida",
                        caption: "Concluído",
                        helpText: "Indica se a atualização foi concluída com sucesso",
                        comboItensDef: Combos.SimNao(1, 0),
                        widthField: 3, 
                        disabled: true, 
                        defaultValue: 0,
                    },
                    {
                        type: "combo",
                        name: "concluida_erro",
                        caption: "Erro",
                        helpText: "Indica se a atualização foi concluída com erro",
                        comboItensDef: Combos.SimNao(1, 0),
                        widthField: 6,
                        disabled: true, 
                        defaultValue: 0,
                    },

                    {
                        type: "combo",
                        name: "clientebackup",
                        caption: "Cliente Backup",
                        helpText: "Fase de upload no Cliente",
                        comboItensDef: Combos.FaseAtualizacao(),
                        widthField: 4,
                        disabled: true, 
                        defaultValue: 0,
                    },
                    {
                        type: "combo",
                        name: "serverupdate",
                        caption: "Server Update",
                        helpText: "Fase de atualiação do banco no servidor",
                        comboItensDef: Combos.FaseAtualizacao(),
                        widthField: 4,
                        disabled: true, 
                        defaultValue: 0,
                    },
                    {
                        type: "combo",
                        name: "clienterestore",
                        caption: "Restauração Cliente",
                        helpText: "Fase de restauração no banco do cliente",
                        comboItensDef: Combos.FaseAtualizacao(),
                        widthField: 4,
                        disabled: true, 
                        defaultValue: 0,
                    },

                ]}
            >
            </Formulario>
        );    
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
// const mapDisptachToProps = (dispatch: any, ownProps: any) => {
//     return {
//         //SetClienteVenda: (codigocliente: number, nome: string) => dispatch(SetClienteVenda(codigocliente, nome)),
//         ...ownProps,
//     };
// }

export default connect(mapStateToProps)(AddAtualizacaoAutomatica);
