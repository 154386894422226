import React, { Component } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "../../ui/assets/jss/material-dashboard-react/components/tableStyle";
import { warningColor, primaryColor, dangerColor, successColor, infoColor, roseColor, grayColor, defaultFont } from "../../ui/assets/jss/material-dashboard-react";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Icon, TableFooter } from "@material-ui/core";
import { ITableAction } from "./Tabela2";
import TabelaGridContainer from "./TabelaGridContainer";
import TabelaGridItem from "./TabelaGridItem";

import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { CSSProperties } from "@material-ui/core/styles/withStyles";

//const useStyles = makeStyles(styles);
const estilo = () => createStyles(styles);

export type TTabelaTableDetailFunction = (rowData: any) => Promise<React.ReactElement>;
interface IProps {
    classes: any;
    tableHeaderColor: "warning" | "primary" | "danger" | "success" | "info" | "rose" | "gray";
    tableHead: any[];
    tableData: any[][];
    tableDataObject?: any[];
    detail?: React.ReactElement | TTabelaTableDetailFunction;
    actions?: ITableAction[];
    actionsColumns?: number;
    actionColumnWidth?: number;
    page: number;
    pageSize: number;
    totalRows: number;
    onFirstPage?: () => void;
    onPriorPage?: () => void;
    onNextPage?: () => void;
    onLastPage?: () => void;
    timeoutDetail?: number;
}
interface IState {
    showDetail: boolean;
    detailtId: number;
    detailRender: React.ReactElement;
    idTimeout: number;
    timeout: number;
}
class TabelaTable extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.clickDetail = this.clickDetail.bind(this);
        this.detailTimer = this.detailTimer.bind(this);
        this.execDetail = this.execDetail.bind(this);
        this.state = {
            showDetail: false,
            detailtId: -1,
            detailRender: null as any,
            idTimeout: 0,
            timeout: this.props.timeoutDetail || 0,
        }
    }
    colCount() {
        return (this.props.tableData.length == 0 ? 0 : this.props.tableData[0].length) + (this.props.detail ? 1 : 0) + ((this?.props?.actions?.length || 0) > 0 ? 1 : 0);
    }
    getFreeActionCount(){
        return (this.props.actions || []).filter((act: ITableAction) => act.isFreeAction === true).length;
    } 
    getNonFreeActionCount() {
        return (this.props.actions || []).filter((act: ITableAction) => act.isFreeAction !== true).length;
    }
    getActionsCols() {
        return Math.min(this?.props?.actionsColumns || this.getNonFreeActionCount(), this.getNonFreeActionCount());
    }
    
    async clickDetail(id: number) {
        let render: React.ReactElement = null as any;
        let obj: any[] = this.props.tableDataObject as any;
        
        let show = (! this.state.showDetail) || (this.state.detailtId != -1 && this.state.detailtId != id);
        if (show) {
            if (typeof this.props.detail === 'function') {
                render = await (this.props.detail as TTabelaTableDetailFunction)(obj[id]);
            }
            else {
                render = this.props.detail as any;
            }
        }
        let idTimeout = this.state.idTimeout;
        if (this.state.timeout > 0) {
            if (show) {
                setTimeout(this.execDetail, this.state.timeout);
            }
            else {
                clearTimeout(this.state.idTimeout);
                idTimeout = 0;
            }
        }
        this.setState({
            showDetail: show,
            detailtId: show ? id : -1,
            detailRender: render,
            idTimeout,
        });
    }
    async execDetail() {
        let id = this.state.detailtId;
        let render: React.ReactElement = null as any;
        let obj: any[] = this.props.tableDataObject as any;
        
        let show = this.state.showDetail;
        //console.log('detalhe', id, show);
        if (show) {
            if (typeof this.props.detail === 'function') {
                render = await (this.props.detail as TTabelaTableDetailFunction)(obj[id]);
            }
            else {
                render = this.props.detail as any;
            }
        }

        let idTimeout = this.state.idTimeout;
        if (this.state.timeout > 0) {
            if (show) {
                setTimeout(this.execDetail, this.state.timeout);
            }
            else {
                clearTimeout(this.state.idTimeout);
                idTimeout = 0;
            }
        }
        this.setState({
            showDetail: show,
            detailtId: show ? id : -1,
            detailRender: render,
            idTimeout,
        });
    }
    async detailTimer() {
        if (this.state.showDetail)
            this.clickDetail(this.state.detailtId);
    }
    getHeaderArray() {
        let r:  any[] = [];
        if (this.props.detail)
            r.push("");
        if (this.props.actions)
            r.push("");
        return [...r, ...this.props.tableHead];
    }
    getDataArray(prop: any[], key: number) {
        let r: any[] = [];
        
        const styleDiv: CSSProperties = {
            cursor: 'pointer',
        }
        let obj: any = this.props.tableDataObject;
        if (this.props.detail)
            r.push(<div className={this.props.classes.divDetail} onClick={() => this.clickDetail(key)}> {this.state.showDetail && (this.state.detailtId == key) ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>} </div>);
        if (this.props.actions) {
            let actionCols = this.getActionsCols();
            let template = "";
            for (let c = 1; c <= actionCols; c++) {
                template += (this.props.actionColumnWidth || 0).toFixed(0) + "px ";
            }
            
            r.push(<div style={{display: 'grid', gridTemplateColumns: template, cursor: 'pointer'}}>
                {this.props.actions.map((act: ITableAction, key1: number) => {
                    if (act.isFreeAction !== true)
                        return <div onClick={() => act.onClick(obj[key])}>{typeof act.icon === 'string' ? <Icon>{act.icon}</Icon> : act.icon}</div>;
                })}
            </div>);
        }

        return [...r, ...prop];
    }
    getActionColStyle(key: number) {
        let actionCols = this.getActionsCols();
        let actionClass: any = {}
        if ((actionCols > 0) && (key == 1)) {
            actionClass = {
                width: (actionCols * (this.props.actionColumnWidth || 0)).toFixed(0)+'px',
            };
        }
        return actionClass;
    }
    getPaginador() {
        const rows = this.props.totalRows || 0;
        const size = this.props.pageSize || 0;
        const pages = Math.floor(rows / size)+((rows % size) != 0 ? 1 : 0);
        
        const style: CSSProperties = {
            display: 'grid',
            gridTemplateColumns: "25px 25px 80px 25px 25px ",
            gridColumnGap: '5px',
            paddingLeft: '5px',
            //backgroundColor: 'red',
            justifyContent: 'left',
            width: 'col-12',
            vertialAlign: 'bottom',
        };
        const styleDiv: CSSProperties = {
            cursor: 'pointer',
        }
        return <div style={style as any}>
            <div style={styleDiv as any} onClick={this.props.onFirstPage}><FirstPageIcon /></div>
            <div style={styleDiv as any} onClick={this.props.onPriorPage}><NavigateBeforeIcon /></div>
            <span style={{justifyContent: 'center', textAlign: 'center'}}>{`${this.props.page} de ${pages} (${this.props.totalRows})`}</span>
            <div style={styleDiv as any} onClick={this.props.onNextPage}><NavigateNextIcon /></div>
            <div style={styleDiv as any} onClick={this.props.onLastPage}><LastPageIcon /></div>
            
        </div>
    }
    render() {
        const { tableHead, tableData, tableHeaderColor, classes } = this.props;
        
        
        return (
            <div className={classes.tableResponsive}>
                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            <TableRow className={classes.tableHeadRow}>
                                {this.getHeaderArray().map((prop: any, key: number) => {
                                    let detailClass = (this.props.detail && key==0 ? classes.tableCellDetail +" ": "");

                                    return (
                                        <TableCell
                                            style={{...this.getActionColStyle(key)}}
                                            className={detailClass + classes.tableCell + " " + classes.tableHeadCell}
                                            key={key}
                                        >
                                            {prop}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    <TableBody>
                        {tableData.map((prop: any, key: number) => {
                            let ret = ([
                                <TableRow key={key} className={classes.tableBodyRow}>
                                    {this.getDataArray(prop, key).map((prop: any, key: number) => {
                                        let detailClass = (this.props.detail && key==0 ? classes.tableCellDetail +" ": "");

                                        return (
                                            <TableCell style={{...this.getActionColStyle(key)}} className={detailClass + classes.tableCell} key={key}>
                                                {prop}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ]);
                            if (this.props.detail !== undefined) {
                                ret.push(
                                <TableRow key={`h${key}`} className={(this.state.showDetail && (this.state.detailtId == key) ? "" :  classes.hidden) + " " + classes.tableBodyRow}>
                                    <TableCell colSpan={this.colCount()} className={classes.tableCell} key={key}>
                                        {this.state.detailRender}
                                    </TableCell>
                                </TableRow>
                                );
                            }
                            return ret;
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={this.colCount()}>
                                {this.getPaginador()}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                    
                </Table>
            </div>
        );
    }
}

export default withStyles(estilo())(TabelaTable);