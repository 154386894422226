import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios, { AxiosRequestConfig } from "axios";
import { Input, Button, Select, MenuItem } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import { EmptyBaseState } from "../Component2/Component2";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tabela2 from "../Tabela/Tabela2";
import Formatter from "../../utils/Formatters";
var fileDownload = require('js-file-download');

const tableIcons: any = {
    Add: forwardRef((props, ref: any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref: any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref: any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref: any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref: any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref: any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref: any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref: any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref: any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref: any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref: any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref: any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref: any) => <ViewColumn {...props} ref={ref} />)
};

const consulta = (query: any) => {
    let order = (query?.orderBy?.field || 'datahora') + ' ' + (query?.orderDirection || 'desc');
    let search: string = query?.search || '';
    return new Promise<any>(async (resolve, reject) => {
        let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
            sql: `
                select * from liberacaolicencas
                where 
                    validade >= current_date-10
                order by validade, ${order}               
            `,
            page: query.page,
            pageSize: query.pageSize,
            search: query.search,
            orderBy: ""
        })

        resolve({
            data: result?.data?.data,
            page: result?.data?.page - 1,
            totalCount: result?.data?.total
        });
    })
}
const RowClick = async (event: any, rowdata: any, errorFunc: any) => {
}
interface IRef {
    [Name: string]: React.RefObject<HTMLInputElement>;
}
interface IState {
    estado: "grid" | "edit" | "insert";
    razao_social: string;
    cnpj: string;
    ativo: string;
}
class LiberacaoLicencaVencendo extends PrivateComponent<any, IState> {
    Ref: IRef;
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.Ref = {
            'cnpj': React.createRef(),
            'razao_social': React.createRef(),
            'ativo': React.createRef(),
        };
        
        this.state = {
            ...EmptyBaseState,
            estado: "grid",
            cnpj: "",
            razao_social: "",
            ativo: ""
        }
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    actions={[
                        // {
                        //     isFreeAction: true,
                        //     icon: <AddBox />,
                        //     onClick: (rowData: any) => {
                        //         this.redirectTo("/getinfolib");
                        //     }
                        // }
                    ]}
                    actionBarFreeWidth={2}
                    actionsColumns={2}
                    actionColumnWidth={25}
                    columns={[
                        { title: "ID", field: "id" },
                        { title: "Usuário", field: "usuario" },
                        { title: "Data/Hora", field: "datahora", render: Formatter.date("datahora", "d-m-Y h:n:s") },
                        { title: "Lista CNPJ", field: "listacnpj" },
                        { title: "Validade", field: "validade", render: Formatter.date("validade", "d-m-Y") },
                        { title: "Observação", field: "motivo" },
                        { title: "Arquivo LIB", field: "arquivolib", render: (data: any) => {
                            return <Button color="primary" onClick={() => fileDownload(data.arquivolib, 'infolib.dat')}>Baixar</Button>
                        } },
                    ]}
                    

                    data={consulta}
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     //maxBodyHeight: '50vw',
                    //     pageSize: 30,
                    //     pageSizeOptions: [5, 10, 20, 30, 50, 100]
                    // }}
                    search={true}
                    pageSize={30}
                    title="Licenças LIB Vencendo"
                    // onRowClick={this.rowClick/*RowClick*/}
                    printReport={true}
                    reportSql={"select *, replace(listacnpj, ' ', chr(13)||chr(10)) as cnpj2 from liberacaolicencas where validade >= current_date-10 order by validade desc, datahora desc"}
                    reportTitle={"LICENÇAS LIBS VENCENDO"}
                    reportColumns={[
                        {
                            name: "id",
                            title: "ID",
                            align: "left",
                            type: "string"
                        },
                        {
                            name: "usuario",
                            title: "Usuário",
                            align: "left",
                            type: "string"
                        },
                        {
                            name: "datahora",
                            title: "Data",
                            align: "left",
                            type: "date",
                            format: "d-m-Y",
                            width: 100,
                        },
                        {
                            name: "cnpj2",
                            title: "CNPJ's",
                            align: "left",
                            type: "string"
                        },
                        {
                            name: "validade",
                            title: "Validade",
                            align: "left",
                            type: "date",
                            format: "d-m-Y",
                            width: 100
                        },

                    ]}

                />
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return state;
}
export default connect(mapStateToProps)(LiberacaoLicencaVencendo);