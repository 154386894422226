import React, { Component, forwardRef, ChangeEvent, EventHandler, KeyboardEvent, ReactNode } from "react";
import MaterialTable, { MaterialTableProps } from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import { ListItemAvatar, Typography, Input, IconButton, TextField, InputLabel, InputAdornment, Select, MenuItem, Icon, Tooltip } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import "./Tabela.css";
import Key from "../../utils/Keys";
import TabelaGridContainer from "./TabelaGridContainer";
import TabelaGridItem from "./TabelaGridItem";
import Card from "../../ui/components/Card/Card";
import CardHeader from "../../ui/components/Card/CardHeader";
import CardBody from "../../ui/components/Card/CardBody";
import Table from "../../ui/components/Table/Table";
import TabelaTable, { TTabelaTableDetailFunction } from "./TabelaTable";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Print } from "@material-ui/icons";
import { IReportColumn, ReportGridSettings, ReportOptions } from "../../ReportTypes";
import NocRequest from "../../utils/NocRequest";
import PDFViewer from "../PDFViewer/PDFViewer";

const tableIcons: any = {
    Add: forwardRef((props, ref: any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref: any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref: any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref: any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref: any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref: any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref: any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref: any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref: any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref: any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref: any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref: any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref: any) => <ViewColumn {...props} ref={ref} />),
    AddCliente: forwardRef((props, ref: any) => <AddCircleIcon {...props} ref={ref} />),
};

export interface ITableColumn {
    title: string;
    field: string;
    type?: "string" | "number" | "numeric" | "date";
    render?: (rowData: any) => React.ReactElement | any;
}
export interface ITableQuery {
    orderBy: {
        field: string;
    };
    field: string;
    orderDirection: string;
    search: string;
    fieldtype: string;
    fieldformat: string;
    page: number;
    pageSize: number;
}
export interface ITableAction {
    /**
     * Indica se a ação é independente do registro da tabela (Ação global)
     */
    isFreeAction?: boolean;
    /**
     * Icone associado com a ação
     */
    icon: React.ReactNode | string;
    /**
     * 
     */
    onClick: (rowData: any) => Promise<void> | void;
    /**
     * Texto informativo da ação
     */
    tooltip?: string;
}

type TTableData<RowData = any> = (query: ITableQuery) => Promise<RowData>;

interface IProps<RowData> {
    /**
     * Desabilita o cabeçalho da tabela
     */
    disableHeader?: boolean;
    /**
     * Lista de ações associadas com a tabela
     */
    actions?: ITableAction[];
    /**
     * Quantidade de colunas usadas pelos icones
     */
    actionsColumns?: number;
    /**
     * Largura da coluna ocupada por cada ação (Pixel)
     */
    actionColumnWidth?: number;
    /**
     * Largura da barra de acões livres (colunas bootstrap)
     */
    actionBarFreeWidth?: number;
    /**
     * Titulo da tabela
     */
    title: string;
    /**
     * SubTitulo da tabela
     */
    subtitle?: string;
    /**
     * Lista de colunas
     */
    columns: ITableColumn[];
    /**
     * Lista de campos de pesquisa utilizados na busca tipo 2
     */
    fieldsSelect?: string[];
    /**
     * Ativa modo de pesquisa tipo 2
     */
    search?: boolean;
    /**
     * Largura da barra de busca (colunas bootstrap)
     */
    searchBarWidth?: number;
    /**
     * Contador de refresh da tabela, sua mudanca forca a reexecucao da consulta da tabela
     */
    refreshCount?: number;
    /**
     * Informacões da tabela
     */
    data: RowData[] | TTableData<RowData>;
    /**
     * PageSize da consulta
     */
    pageSize: number;
    /**
     * Painel de detalhes da linha
     */
    detail?: React.ReactElement | TTabelaTableDetailFunction;
    /**
     * Campo padrão de ordenação
     */
    orderByField?: string;
    /**
     * Direção padrão de ordenação
     */
    orderByDirection?: "asc" | "desc";
    /**
     * timeout da seção detalhe da tabela
     */
    timeoutDetail?: number;
    /**
     * indica se a tabela deve ser impressa
     */
    printReport?: boolean;
    /**
     * Caption do botão de impressão
     */
    reportButtonCaption?: string;
    /**
     * instrução SQL utilizada para renderizar o relatório
     */
    reportSql?: string;
    /**
     * Colunas utilizadas no relatório
     */
    reportColumns?: IReportColumn[];
    /**
     * Titulo do relatório
     */
    reportTitle?: string;
    /**
     * Opções extras para o relatório
     */
    reportOptions?: ReportOptions;
}
interface IState<RowData> {
    Values: IValue;
    data: RowData[];
    dataOriginal: RowData[];
    totalRows: number;
    page: number;
    orderByField: string;
    orderByDirection: string;
    refreshCount: number;
    refreshCountOld: number;
    showPDF: boolean;
    showPDFData: string;
}
interface IValue {
    [Name: string]: any;
}
class Tabela2<RowData = any> extends React.Component<IProps<RowData>, IState<RowData>> {
    TableRef: React.RefObject<any>;
    inputRef: React.RefObject<any>;
    mounted: boolean;
    constructor(props: IProps<RowData>) {
        super(props);
        this.TableRef = React.createRef();
        this.inputRef = React.createRef();
        this.getDataHeaders = this.getDataHeaders.bind(this);
        this.getData = this.getData.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.clearEditSearch = this.clearEditSearch.bind(this);
        this.searchKeydown = this.searchKeydown.bind(this);
        this.doFirstPage = this.doFirstPage.bind(this);
        this.doPriorPage = this.doPriorPage.bind(this);
        this.doNextPage = this.doNextPage.bind(this);
        this.doLastPage = this.doLastPage.bind(this);
        this.closePDF = this.closePDF.bind(this);
        this.buildReport = this.buildReport.bind(this);
        this.state = {
            Values: {
                'ComboSearch': '-1',
                'EditSearch': '',
            },
            data: [],
            dataOriginal: [],
            totalRows: 0,
            page: 1,
            orderByField: this?.props?.orderByField || "",
            orderByDirection: this?.props?.orderByDirection || "asc",
            refreshCount: 0,
            refreshCountOld: 0,
            showPDF: false,
            showPDFData: "",
                };
        this.mounted = false;
    }
    closePDF() {
        this.setState({showPDF: false, showPDFData: ""});
    }    
    searchChange(ev: ChangeEvent|any) {
        const {name, value} = ev.target;
        this.setState({
            Values: {...this.state.Values, [name]: value}
        });
    }
    clearEditSearch(){
        this.setState({
            Values: {...this.state.Values, 'EditSearch': ''}
        },() => {
            this.executeSearch(1);
        });
    }
    searchKeydown(ev: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if ([Key.VK_RETURN, Key.VK_ENTER].indexOf(ev.which) >= 0) {
            this.executeSearch(1);
        }
    }
    executeSearch(pageId: number) {
        const rows = this.state.totalRows;
        const size = this.props.pageSize;
        const pages = Math.floor(rows / size)+((rows % size) != 0 ? 1 : 0);
        
        let id = new Number(this.state.Values["ComboSearch"]).valueOf();
        let search = this.state.Values["EditSearch"];
        let col = "";
        if (id != -1) {
            let cols = this.props?.fieldsSelect || [];
            col = cols.length > 0 ? cols[id] : this.state.orderByField;
        }
        if (typeof this.props.data === 'function') {
            this.props.data({
                orderBy: { 
                    field: this.state.orderByField /*col*/,
                },
                field: col,
                orderDirection: this.state.orderByDirection,
                search: search,
                fieldtype: '',
                fieldformat: '',
                page: pageId,
                pageSize: this.props.pageSize,
            }).then((res: any) => {
                this.setState({totalRows: res.totalCount, data: res.data, page: pageId, refreshCountOld: this.state.refreshCount});
            }).catch(err => {
                //console.log("Error", err)
            });
        }
        else {
            let filtered: RowData[] = [];
            if (search == "")
                filtered = [...this.state.dataOriginal];
            else 
                filtered = this.state.dataOriginal.filter((data: any, key: number) => {
                    let r = false;
                    if (col == "") {
                        for (let c of this.props.columns) {
                            r = data[c.field].indexOf(search) > -1;
                        }
                    }
                    else 
                        r = data[col].indexOf(search) > -1;
                    return r;
                });
            this.setState({totalRows: filtered.length, data: filtered, page: pageId, refreshCountOld: this.state.refreshCount});
        }
    }
    doFirstPage() {
        this.executeSearch(1);
    }
    doPriorPage() {
        if (this.state.page > 1)        
            this.executeSearch(this.state.page-1);
    }
    doNextPage() {
        const rows = this.state.totalRows;
        const size = this.props.pageSize;
        const pages = Math.floor(rows / size)+((rows % size) != 0 ? 1 : 0);
        if (this.state.page < pages)
            this.executeSearch(this.state.page+1);        
    }
    doLastPage() {
        const rows = this.state.totalRows;
        const size = this.props.pageSize;
        const pages = Math.floor(rows / size)+((rows % size) != 0 ? 1 : 0);
        this.executeSearch(pages);        
    }
    getColDescription(colName: string) {
        let s = colName;
        let r = this.props.columns.filter((c: any) => c.field == colName );
        if (r.length > 0)
            s = `${r[0].title}`;

        return s == "" ? colName : s;
    }
    componentDidMount() {
        this.executeSearch(1);
    }
    static getDerivedStateFromProps(nextProps: IProps<any>, prevState: IState<any>) {
        if ((typeof nextProps.data !== 'function') && (nextProps.data != prevState.data)) {
            return {
                data: nextProps.data,
                dataOriginal: nextProps.data,
                totalRows: nextProps.data.length
            }
        }
        else if (nextProps.refreshCount != prevState.refreshCount) {
            return {
                refreshCount: nextProps.refreshCount,
            }
        }
        return null;
    }
    doFilter() {
    }
    getDataHeaders(): string[] {
        return this.props.columns.map((col: ITableColumn) => {
            return col.title;
        })
    }
    getData() {
        let data = this.state.data;
        if (typeof this.props.data !== 'function') {
            let fmin = (this.state.page-1) * this.props.pageSize;
            let fmax = (this.state.page * this.props.pageSize)-1;
            data = this.state.data.filter((data: any, key: number) => key >= fmin && key <= fmax);
        }
        return data.map((data: any, key: number) => {
            let r: any[] = [];
            for (let c of this.props.columns) {
                if (c.render) {
                    r.push(c.render(data));
                }
                else 
                    r.push(data[c.field]);
            }
            return r;
        });
    }
    hasFreeAction() {
        return (this.props.actions || []).filter((act: ITableAction) => act.isFreeAction === true).length > 0;
    }
    getActionsArrayPrint(): ITableAction[] {
        let r: ITableAction[] = [...(this.props.actions || [])];
        if (this.props.printReport === true) {
            r.push({
                icon: <Print />,
                isFreeAction: true,
                tooltip: this.props.reportButtonCaption || "Imprimir tabela",
                onClick: this.buildReport,
            });
        }
        return r;
    }
    async buildReport() {
        let order = this.props.orderByField + ' ' + (this.props.orderByDirection || 'asc');
        let sql: string = this.props.reportSql || "";
        let rep: ReportGridSettings = {
            title: this.props.reportTitle || this.props.title,
            columns: this.props.reportColumns || [],
            sql,//this.props.reportSql || this.props.sql || `select * from ${this.props.tableName}`,
            options: this.props.reportOptions || {},            
        }

        
        let r = await NocRequest.post("report/gridreport", rep);
        //fileDownload(Buffer.from(r.data as string, "base64"), "report.pdf");
        this.setState({showPDF: true, showPDFData: r.data as string});

    }

    render() {
        // console.log(this.state.refreshCount , this.state.refreshCountOld)
        if (this.state.refreshCount != this.state.refreshCountOld) {
            this.executeSearch(this.state.page);
            //this.setState({refreshCountOld: this.state.refreshCount});
            //return null;
        }
        let freeCount = (this.props.actions || []).filter((act: ITableAction) => act.isFreeAction === true).length;
                                            
        let actionCols = Math.min(this?.props?.actionsColumns || freeCount, freeCount);
        let template = "";
        for (let c = 1; c <= actionCols + (this.props.printReport === true ? 1 : 0); c++) {
            template += (this.props.actionColumnWidth || 0).toFixed(0) + "px ";
        }
        let obj: any = this.state.data;

        return [

            <TabelaGridContainer>
                <TabelaGridItem xs={12} sm={12} md={12}>
                    <Card>
                        {this.props.disableHeader !== true ?
                        <CardHeader color=""
                            title={this.props.title}
                            subtitle={this.props.subtitle}
                            searchBarWidth={this.props.search === true ? (this.props.searchBarWidth || 4) : 0}
                            searchBar={this.props.search === true ? (
                                <TabelaGridContainer>
                                    <TabelaGridItem xs={4} sm={4} md={4}>
                                        <Select
                                            labelId="demo-simple-select-label "
                                            id="demo-simple-select"
                                            name="ComboSearch"
                                            value={this.state.Values['ComboSearch']}
                                            fullWidth={true}
                                            startAdornment={<SearchOutlined />}
                                            onChange={this.searchChange}
                                            >
                                            <MenuItem value={'-1'}>Todos</MenuItem>
                                            {(this.props.fieldsSelect || []).map((el: string, index: number) => <MenuItem value={index.toString()}>{this.getColDescription(el.split(":")[0])}</MenuItem>)}
                                        </Select>

                                        
                                    </TabelaGridItem>
                                    <TabelaGridItem xs={8} sm={8} md={8}>
                                        <Input
                                            
                                            placeholder="Informe a busca"
                                            className="searchInput"
                                            fullWidth={true}
                                            name="EditSearch"
                                            value={this.state.Values['EditSearch']}
                                            onChange={this.searchChange}
                                            onKeyDown={this.searchKeydown}
                                            // startAdornment={
                                            //     <SearchOutlined />
                                            // }
                                            endAdornment={
                                                <IconButton size="small" onClick={this.clearEditSearch}>
                                                <span><i className="fas fa-times"></i></span>
                                                </IconButton>
                                            }
                                        />
                                        
                                    </TabelaGridItem>
                                </TabelaGridContainer>        
                            ): null}
                            actionBarWidth={this.hasFreeAction() || (this.props.printReport === true)  ? (this.props.actionBarFreeWidth || 2) : 0}
                            actionBar={this.hasFreeAction() || (this.props.printReport === true) ? (
                                <TabelaGridContainer>
                                    <TabelaGridItem xs={12} sm={12} md={12}>
                                        <div style={{display: 'grid', gridTemplateColumns: template, justifyContent: 'right'}}>
                                        {this.getActionsArrayPrint()/*(this.props.actions || [])*/.map((act: ITableAction, key: number) => {
                                            let ico: ReactNode = typeof act.icon === 'string' ? <Icon>{act.icon}</Icon> : act.icon;
                                            if ((act.tooltip || "") != "")
                                                ico = <Tooltip title={act.tooltip || ""}>{ico as any}</Tooltip>
                                            if (act.isFreeAction === true)
                                                return <div style={{cursor: 'pointer'}} onClick={() => act.onClick({})}>{ico}</div>;
                                        })}
                                        </div>
                                        
                                    </TabelaGridItem>
                                </TabelaGridContainer>        
                            ): null}
                        >
                        </CardHeader>
                        : null}
                        <CardBody>
                            <TabelaTable
                                tableHeaderColor="primary"
                                tableHead={this.getDataHeaders()}
                                tableData={this.getData()}
                                tableDataObject={this.state.data}
                                detail={this.props.detail}
                                actions={this.props.actions}
                                actionsColumns={this.props.actionsColumns}
                                actionColumnWidth={this.props.actionColumnWidth}
                                page={this.state.page}
                                pageSize={this.props.pageSize}
                                totalRows={this.state.totalRows}
                                onFirstPage={this.doFirstPage}
                                onPriorPage={this.doPriorPage}
                                onNextPage={this.doNextPage}
                                onLastPage={this.doLastPage}
                                timeoutDetail={this.props.timeoutDetail || 0}
                            />
                        </CardBody>
                    </Card>
                </TabelaGridItem>
            </TabelaGridContainer>,
            <PDFViewer title="Visualização" open={this.state.showPDF} pdf={this.state.showPDFData} dialogClose={this.closePDF}  />,
        ];
    }
}

export default Tabela2;