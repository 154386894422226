import React, { Component, createRef, useCallback, useEffect, useRef, useState } from "react";
import PrivateComponent from "../Component2/PrivateComponent";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import ia from "../../ui/ia.png";
import "./ia.css";
import { Add, Check, Refresh } from "@material-ui/icons";
import { Badge, Card, CardContent, Chip, IconButton, Typography, TextField, Button } from "@material-ui/core";
import CardHeader from "../../ui/components/Card/CardHeader";
import axios, {AxiosInstance} from "axios";
import CardFooter from "../../ui/components/Card/CardFooter";
import ReactMarkdown from 'react-markdown'
import ReactDom from 'react-dom'
// import remarkGfm from 'remark-gfm'

export interface IProps {}

interface MiniChipProps {
    texto: string;
    ocorrencias: number;
    id: number;
}
interface Docs {
    name: string;
    size: number;
}

interface Message {
    tipo: "pergunta" | "resposta",
    texto: string;
    duracao: number;
    horario: Date;
}

const If = ({cond, vtrue, vfalse}) => {
    return <>{cond ? vtrue : vfalse}</>;
}

const AgenteSuporte = (props: IProps) => {
    const ref = useRef<HTMLDivElement>();
    const refUpload = useRef<HTMLInputElement>();

    const wait = (tempo: number) => {
        return new Promise((resolve) => {
            const data = {"status": "ok", "message": "", "data": {"answer": "U2ltISBWb2PDqiBwb2RlIGRlZmluaXIgdW1hIHZhcmnDoXZlbCBkbyB0aXBvIGludGVpcmEgdXRpbGl6YW5kbyBhIHNpbnRheGU6CmBgYHBhc2NhbAp2YXIgTm9tZV92YXJpw6F2ZWwxLCBOb21lX3ZhcmnDoXZlbDIsLi4uLCBOb21lX3ZhcmnDoXZlbG46IFRpcG9fZGFfdmFyacOhdmVsOwpgYGAKRW0gc2VndWlkYSwgdm9jw6ogcG9kZSBhdHJpYnVpciB1bSB2YWxvciDDoCB2YXJpw6F2ZWwgdXNhbmRvIG8gb3BlcmFkb3IgZGUgYXRyaWJ1acOnw6NvIChgPWApOgpgYGBwYXNjYWwKTm9tZV92YXJpw6F2ZWwxIDo9IFZhbG9yOw==", "total_docs": 4, "time": 121.62521696090698}};
            setTimeout(() => resolve(data), tempo*1000)
        })
    }
    const getHora = (d: Date) => {
        const str = d.toLocaleTimeString("pt-BR");
        const str2 = new Date().toLocaleTimeString("pt-BR");

        const h1 = str.substring(str.length-8).substring(0, 5);
        const h2 = str2.substring(str2.length-8).substring(0, 5);
        return /*h1 === h2 ? "agora" :*/ h1;
    }
    const renderMessage = (m: Message) => {
        return (
            <div style={{
                // width: "100%",
                display: "flex",
                alignItems: "flex-end",
                marginBottom: 10,
            }}>
                <If cond={m.tipo === "pergunta"} vtrue={<div style={{flexGrow: 1}} />} vfalse={null} />
                <Card>
                    <CardContent  style={{ padding: 5, backgroundColor: m.tipo+'a' === "pergunta" ? "#a0c5b4" : "", minWidth: 150 }}>
                        <div>
                            <Typography style={{color: "rgba(255, 0, 0, 0.7)", fontWeight: "bold", fontSize: "14px", paddingBottom: 5}}>{m.tipo === "pergunta" ? "Você" : "Pixianne"}</Typography>
                            {/* {m.texto.split("\n").map(e => <Typography style={{color: "black", fontSize: "14px", paddingBottom: 5}}>{e}</Typography>)} */}
                            <div style={{paddingRight: 50}}>
                            <ReactMarkdown children={m.texto} />
                            </div>
                            {/* remarkPlugins={[remarkGfm]} */}
                            <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                        </div>
                        <div ref={(el) => ref.current = el as any } style={{display: "flex", flexDirection: "column", alignItems: "end"}}>
                            <Typography style={{color: "rgba(0, 0, 0, 0.4)", fontWeight: "bold", fontSize: "14px", flexGrow: 1}}>{getHora(m.horario)}{m.tipo === "resposta"? ` (${m.duracao.toFixed(2)} secs)` : ""} <Check style={{fontSize: "12px", fontWeight: "bold"}} htmlColor="#ccc" /></Typography>
                        </div>
                    </CardContent>
                </Card>
                <If cond={m.tipo === "resposta"} vtrue={<div style={{flexGrow: 1}} />} vfalse={null} />
            </div>
        );
    }
    const pixianne: AxiosInstance = axios.create({
        baseURL: "https://pixianne.wcompany.dev.br"
    });
    const [messages, setMessages] = useState<Message[]>([]);
    const [pensando, setPensando] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [textoPesquisa, setTextoPesquisa] = useState("");
    const [docs, setDocs] = useState<Docs[]>([]);
    const [inputText, setInputText] = useState("");
    const uploadFile = (ev: any) => {
        if (ev.target.files.length > 0) {
            let form = new FormData();
            form.append("file",  ev.target.files[0]);
            setOperacao("Pixianne esta aprendendo com o arquivo enviado ...");
            pixianne.post("/add", form).then(d => {
                setOperacao("");
                if (d?.data?.status === "ok") {
                    setPensando(false);
                    getInfo();
                }
                else {
                    alert(d?.data?.message || "Erro desconhecido");
                }
            }).catch(e => {
                setOperacao("");
            });
        }
        else {
            alert("Nenhum arquivo selecionado");
        }
        console.log(ev.target.files[0]);
    }
    const onRemove = (id: number) => {
        if (pensando || operacao.length > 0) 
            return;
        setOperacao("Pixianne esta removendo informações da sua memória");
        pixianne.post("/remove", {filename: docs[id].name}).then(ret => {
            setOperacao("");
            let d = [...docs];
            d.splice(id, 1);
            setDocs(d);
        })
        .catch(e => {
            setOperacao("");

        })
    }
    const refreshDocs = () => {
        getInfo();
    }
    const addDoc = () => {
        refUpload?.current?.click();
    }
    const sendMessage = () => {
        let msg = [...messages]
        msg.push({
            duracao: 0,
            horario: new Date(),
            texto: inputText,
            tipo: "pergunta",
        });
        setPensando(true);
        setMessages(msg);
        setTextoPesquisa(inputText);
        setInputText("");
    }
    const MiniChip = (props: MiniChipProps) => {
        return (
            <Chip label={props.texto} color="primary" style={{margin: 2}} onDelete={() => onRemove(props.id)}>
                <Badge badgeContent={props.ocorrencias} />
            </Chip>
        );
    }
    const getInfo = useCallback(() => {
        setOperacao("Pixianne esta lendo os dados da sua memória");
        pixianne.get("/docs-info").then(d => {
            setOperacao("");
            if (d?.data?.status === "ok") {
                let docs: Docs[] = [];
                for (let k in (d?.data?.data || {})) {
                    docs.push({name: k, size: d[k]});
                }
                setDocs(docs);
            }
        }).catch(e => {
            setOperacao("");
        })
    }, []);
    const perguntePixianne = (question: string) => {
        // wait(1).then((d: any) => {
        //     let msg: Message[] = [...messages];
        //     msg.push({
        //         tipo: "resposta",
        //         horario: new Date(),
        //         duracao: d?.data?.time || 0,
        //         texto:  atob(d?.data?.answer || ""),
        //     });
            
        //     setMessages(msg);
        //     setPensando(false);
        //     setInputText("");

        // })
        pixianne.post("/query", {question}).then(d => {
            if ((d?.data?.status === "ok") || (d?.data?.status === "error")) {
                let msg: Message[] = [...messages];
                
                msg.push({
                    tipo: "resposta",
                    horario: new Date(),
                    duracao: d?.data?.data?.time || 0,
                    texto: d?.data?.status === "ok" ? atob(d?.data?.data?.answer || "") : ("Erro: " + (d?.data?.message || "")),
                });
                
                setMessages(msg);
                setPensando(false);
                setInputText("");
            }
            else {
                alert("Resposta não identificada da IA");
                setPensando(false);
                setInputText("");
            }
        }).catch(e => {
            setPensando(false);
            setInputText("");
        })
    };

    useEffect(() => {
        getInfo();
    }, []);
    useEffect(() => {
        if (pensando) {
            perguntePixianne(textoPesquisa);
        }
    }, [pensando]);
    useEffect(() => {
        ref?.current?.scrollIntoView({behavior: "smooth"})
    }, [messages])

    const fazendoAlgo = pensando || operacao.length > 0
    
    return (
        <div className="main">
            <input ref={(el) => refUpload.current = el as any} type="file" onChange={uploadFile} name="file" style={{display: "none"}} />
            <div className="leftPanel">
                <div style={{margin: "5px"}}>
                    <Card style={{height: "100%"}}>
                        <CardHeader>
                            <Typography>
                                Documentos Anexados
                            </Typography>
                            <IconButton size="small" disabled={fazendoAlgo} onClick={refreshDocs}><Refresh /></IconButton>
                            <IconButton size="small" disabled={fazendoAlgo} onClick={addDoc}><Add /></IconButton>
                        </CardHeader>
                        
                        <CardContent>
                            <If
                                cond={docs.length > 0}
                                vtrue={docs.map((d, i) => <MiniChip texto={d.name} ocorrencias={2} id={i} /> )}
                                vfalse={null}
                            ></If>
                        </CardContent>
                    </Card>
                    
                </div>
                {/* <div></div> */}
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                <div style={{margin: "5px", flexGrow: 1}}>
                    <Card style={{height: "100%", maxHeight: "100%", padding: 10, display: "flex", flexDirection: "column"}}>
                        <div style={{flexGrow: 1, height: "calc(100vh - 243px)", overflow: "scroll", padding: 10}}>
                            {messages.map(m => {
                                return renderMessage(m)
                            })}
                        </div>
                        <div style={{height: 20}}>
                            <If
                                cond={pensando}
                                vtrue={<Typography style={{color: "#aaa"}}>Pixianne está pensando ...</Typography>}
                                vfalse={<If
                                    cond={operacao.length > 0}
                                    vtrue={<Typography style={{color: "#aaa"}}>{operacao + " ..."}</Typography>}
                                    vfalse={<></>}
                                />}
                            />
                            
                        </div>
                    </Card>
                </div>
                <div style={{margin: "5px", height: 100}}>
                    <Card style={{height: "100%", display: "flex", flexDirection: "row", alignItems: "stretch"}}>
                        <input
                            style={{flexGrow: 1, margin: 5, fontSize: "1.2rem"}}
                            placeholder="Pergunte para Pixianne"
                            value={inputText}
                            onChange={(ev) => setInputText(ev.target.value)}
                            readOnly={fazendoAlgo}
                            autoFocus
                            onKeyDown={(ev) => {
                                if (!ev.ctrlKey && !ev.altKey && !ev.shiftKey && ev.keyCode == 13) {
                                    sendMessage();
                                }
                            }}
                        />
                        <Button disabled={fazendoAlgo} variant="contained" color="primary" style={{width: 120, margin: 5}} onClick={sendMessage}>Enviar</Button>
                    </Card>
                </div>
            </div>
        </div>

          
    );

}


const mapStateToProps = (state: AppState, ownProps: any) => {
  return { appstate: state };
};
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
  return {
    ...ownProps,
  };
};

export default connect(mapStateToProps, mapDisptachToProps)(AgenteSuporte);
