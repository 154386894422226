import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { SetLinhaProduto } from "../../reducer/ActionsCreator";
import { Store } from "../../reducer/Reducer";
import Component2 from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import { withRouter } from "react-router-dom";
import Tabela2, { ITableQuery } from "../Tabela/Tabela2";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate?: AppState;
    linhaproduto: string;
    SetLinhaProduto?: (linhaproduto: string) => void;
}

class Configuracoes extends PrivateComponent<IProps, any> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    async consulta(query: ITableQuery) { 
        let order = (query?.orderBy?.field || 'classe') + ' ' + (query?.orderDirection || 'asc');
        let search: string = query?.search || '';
        let field: string = query?.field || '';
        let where: string = "";
        where = field == "" ? `
            where  false
            or upper(id::text) like '%${search.toUpperCase()}%'
            or upper(classe::text) like '%${search.toUpperCase()}%'
            or upper(parametro::text) like '%${search.toUpperCase()}%'
            or upper(descricao::text) like '%${search.toUpperCase()}%'
            or upper(valor::text) like '%${search.toUpperCase()}%'
        ` : `
            where  upper(${field}::text) like '%${search.toUpperCase()}%'
        `;
        
        return new Promise<any>(async(resolve, reject) => {
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql: `
                    select * from (
                        select * from configuracoes 
                    ) as con
                    ${where}
                    order by ${order}               
                `,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: ""
            })
            
    
            resolve({
                data: result?.data?.data,
                page: result?.data?.page-1,
                totalCount: result?.data?.total
            });
        })   
    }
    addVersao() {
    }
    renderPrivate() {
        
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    actions={[
                        {
                            icon: <AddCircleIcon/>,
                            isFreeAction: true,
                            //tooltip: "Adicionar uma nova versão",
                            onClick: (data: any) => {
                                //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                                this.redirectTo("/addconfiguracoes/new");
                            }
                        },
                        {
                            icon: <DeleteOutline/>,
                            //isFreeAction: false,
                            //tooltip: "Remover versão",
                            onClick: (data: any) => {
                                //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                                //this.removerUsuario(data.idversao);
                            }
                        },
                        {
                            icon: <Edit />,
                            //isFreeAction: false,
                            //tooltip: "Remover versão",
                            onClick: (data: any) => {
                                //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                                this.redirectTo(`/addconfiguracoes/edit/${data.id}`);
                            }
                        },
                    ]}
                    actionBarFreeWidth={2}
                    actionsColumns={2}
                    actionColumnWidth={25}
                    columns={[
                        { title: "ID", field: "id" },
                        { title: "Classe", field: "classe" },
                        { title: "Parâmetro", field: "parametro" },
                        { title: "Descrição", field: "descricao" },
                        { title: "Valor", field: "valor" },
                    ]}
                    fieldsSelect={[
                        "id", "classe", "parametro", "descricao", "valor"
                    ]}
                    data={this.consulta}
                    search={true}
                    pageSize={30}
                    refreshCount={this.state.refreshCount}
                    orderByField="classe"
                    orderByDirection="asc"
                    
                    title={"Parâmetros de Configurações"}
                    subtitle="Listagem de parâmetros gerais de configuração dos serviços do NOC"
                />
                {this.baseRender()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state, ...ownProps};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
        SetLinhaProduto: (linhaproduto: string) => dispatch(SetLinhaProduto(linhaproduto)),
    };
}

export default  connect(mapStateToProps)(Configuracoes);

