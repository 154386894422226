import React, {Component, KeyboardEventHandler, ChangeEventHandler} from "react";
import Requester from "../../utils/NocRequest";
import WS from "../../Config/WS";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from "@material-ui/core/FormControl";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

import { Tooltip } from '@material-ui/core';
const interrogation = require("../../resources/interrogation.png");


interface IOptions {
    caption: string;
    value: string;
}
interface ISqlInfo {
    sql: string;
    fieldValue: string;
    fieldLabel: string;
}
interface IProps {
    inputRef?: React.RefObject<any>;
    className?: string;
    placeHolderText?: string;
    helpText?: string;
    data: IOptions[] | ISqlInfo;
    name: string;
    id?: string;
    onKeyDown?: KeyboardEventHandler;
    onChange?: ChangeEventHandler;
    value: string;
    maxMenuHeight?: number;
    clearOnPropsChange?: boolean;
    required?: boolean;
}

interface IState {
    options: IOptions[];
    value: string;
    data: IOptions[] | ISqlInfo;
    renderReload: boolean;
}
export default class SelectNovo2 extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);
        this.changeSelect = this.changeSelect.bind(this);
        this.keyDownSelect = this.keyDownSelect.bind(this);
        this.reload = this.reload.bind(this);
        this.inputchange = this.inputchange.bind(this);
        let options: IOptions[] = [];
        if (this.props.data instanceof Array) {
            options = [...this.props.data];
        }
        this.state = {
            options,
            value: this.props.value,
            data: this.props.data,
            renderReload: false,
        }
    }
    static getDerivedStateFromProps(nextProps: IProps, oldState: IState) {
        //console.log('esta derivado', nextProps, oldState);

        if ((nextProps.value !== oldState.value) && ((nextProps.data instanceof Array) && (nextProps.data !== oldState.data))) {
            //console.log(1)
            return {value: nextProps.value, data: nextProps.data};
        }
        if ((nextProps.value !== oldState.value) && ((!(nextProps.data instanceof Array)) && ((nextProps.data as ISqlInfo).sql !== (oldState.data as ISqlInfo).sql))) {
            //console.log(2)
            return {value: nextProps.value, data: nextProps.data, renderReload: true};
        }
        if (nextProps.value !== oldState.value) {
            //console.log(3)
            return {value: nextProps.value};
        }
        if ((nextProps.data instanceof Array) && (nextProps.data !== oldState.data)) {
            //console.log(4)
            return {data: nextProps.data, renderReload: true};
        }
        if ((!(nextProps.data instanceof Array)) && ((nextProps.data as ISqlInfo).sql !== (oldState.data as ISqlInfo).sql)) {
            //console.log(5)
            return {data: nextProps.data, renderReload: true};
        }
        return null;
    }
    async componentDidMount() {
        if (! (this.props.data instanceof Array)) {
            let sql: ISqlInfo = this.props.data as any;
            let r: any[] = await Requester.post(`entity/sql`, {sql: sql.sql});
            let ar = r.map((v: any) => {
                return {caption: v[sql.fieldLabel], value: v[sql.fieldValue]};
            })
            this.setState({options: ar});
        } 
    }
    changeSelect(event: object, value: any, reason: string) {
        if (value != null) {
            this.setState({value: value.value});
            
            if (this.props.onChange) {
                this.props?.onChange({target: {name: this.props.name, value: value.value}} as any);
            }
        }
    }
    keyDownSelect(e: React.KeyboardEvent<HTMLElement>) {
        //this.setState({valueObj: value, value: value.value});
        if (this.props.onKeyDown) {
            (e.target as any).name = this.props.name;
            this.props?.onKeyDown(e as any);
        }
    }
    findValor(findIn: IOptions[]|null = null): IOptions|null {
        for (let x of findIn || this.state.options) {
            if (x.value == this.state.value) {
                return x;
            }
        }
        return null;
    }
    async reload() {
        let sql: ISqlInfo = this.props.data as any;
        
        let r: any[] = await Requester.post(`entity/sql`, {sql: sql.sql});
        //console.log(sql.sql, r)
        if (r instanceof Array) {
            let ar = r.map((v: any) => {
                let cap = "";
                if (v[sql.fieldLabel]) 
                    cap = `${v[sql.fieldLabel]}`;
                else {
                    cap = sql.fieldLabel;
                    for (let tag in v) {
                        if (typeof v !== 'function') {
                            cap = cap.replace('$'+tag, v[tag]);
                        }
                    }
                }
                return {caption: cap, value: v[sql.fieldValue]};
            })
            this.setState({options: ar as any});
        }
        else {
            console.log('SelectNovo2.Reload', r, sql.sql)
        }
    }
    async inputchange(event: object, value: string, reason: string) {
        //console.log('inputchange', value);
    }
    render() {
        if (this.state.renderReload) {
            this.setState({renderReload: false});
            this.reload();
            // setTimeout(() => {
            //     this.reload();
            // }, 10);
        }
        let v = this.findValor();
        /*if ((this.props.clearOnPropsChange === true) && (v == null) && (this.state.value != null) && (this.state.value != "")) {
            //console.log( (this.props.clearOnPropsChange === true) , (v == null) , (this.state.value != null) , (this.state.value != "") )
            console.log("opcao nao encontrada", this.props.name);
            this.setState({value: ""});
            this.changeSelect({}, {value: ""}, "");
        }*/
        //console.log("Avalicao ", this.props.name, this.props.required && (v == null || v == undefined || this.state.value == "" || this.state.value == undefined || this.state.value == null))
        return (
            <FormControl fullWidth={true} style={{
                
                    paddingBottom: "10px",
                    //margin: "27px 0 0 0",
                    position: "relative",
                    verticalAlign: "unset"
                  
            }}>
            <Autocomplete<IOptions>
                // {...defaultProps}
                options={this.state.options}
                getOptionLabel={(option: IOptions) => option.caption}
                getOptionSelected={(option: IOptions) => option.value === this.state.value}
                id={this.props.id || ""}
                placeholder={this.props.placeHolderText || ""}
                debug={false}
                renderInput={(params) => <TextField inputRef={this.props.inputRef} onKeyDown={this.keyDownSelect} {...params} label={this.props.placeHolderText || ""} margin="normal" />}
                fullWidth={true}
                onInputChange={this.inputchange}
                onChange={this.changeSelect}
                value={v as IOptions}
                loading={true}
                clearOnEscape={true}
            />
            {this.props.required && (v == null || v == undefined || this.state.value == "" || this.state.value == undefined || this.state.value == null) ? (
                <Clear style={{
                    position: "absolute",
                    top: "36px",
                    right: "30px",
                    zIndex: 2,
                    display: "block",
                    width: "24px",
                    height: "24px",
                    textAlign: "center",
                    pointerEvents: "none",
                    color: "#f44336",
                }} />
            ) : null}
            </FormControl>
        );
    }
}