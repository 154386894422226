import Keycloak from "keycloak-js";
const keycloak = new Keycloak({
  url: "https://keycloak.wcompany.dev.br/",
  realm: "nextcompany",
  clientId: "noc_ui",
});
keycloak.clientSecret = "tOe8WXJrI63G2A5bHFeriyl0x3Rcj1pW"
export default keycloak
export const KeycloakFactory = () => {
  let key = new Keycloak({
    url: "https://keycloak.wcompany.dev.br/",
    realm: "nextcompany",
    clientId: "noc_ui",
  })
  key.clientSecret = "tOe8WXJrI63G2A5bHFeriyl0x3Rcj1pW"; 
  return key;
};