import React, { Component, forwardRef, ChangeEvent } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, TableContainer, Table, TableBody, Paper, TableRow, TableCell, Button, Input } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { AppState, IClienteLiberacao } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { EmptyBaseState } from "../Component2/Component2";
import { Store } from "../../reducer/Reducer";
import Component2 from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import Formatter from "../../utils/Formatters";
import "./LiberarClienteInadimplente.css";
import { Router, useParams, useLocation, withRouter } from "react-router-dom";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};
type ITipo = "bloqueio" | "liberacao";
interface IProps {
    appstate?: AppState;
    tipo?: "bloqueio" | "liberacao";
    rotaIncial?: string;
    clienteliberacao: IClienteLiberacao;
    location?: any;
}
interface IRefs {
    [RefName: string]: React.RefObject<any>;
}
interface IValores {
    [Name: string]: any;
}
interface IState {
    valores: IValores;
    possuiDebitos: boolean;
    bloqueioPendente: boolean;
}
class LiberarClientesInadimplente extends PrivateComponent<IProps, IState> {
    Refs: IRefs;
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.onchange = this.onchange.bind(this);
        this.voltar = this.voltar.bind(this);
        this.submit = this.submit.bind(this);
        this.Refs = {};
        this.Refs['cliente'] = React.createRef();
        this.Refs['licenca'] = React.createRef();
        this.Refs['obs'] = React.createRef();
        this.Refs['buttonativar'] = React.createRef();
        this.Refs['buttonvoltar'] = React.createRef();
        let val: IValores = {};
        val['obs'] = "";
        this.state = {
            ...EmptyBaseState,
            valores: val,
            possuiDebitos: true,
            bloqueioPendente: true,
        };
    }
    voltar() {
        this.redirectTo("/clientesinadimplentes");
        //this.redirectBack();
    }
    isLiberacao(): boolean {
        return this.getParam("tipo").toLowerCase() == "liberar";
    }
    isBloqueio(): boolean {
        return this.getParam("tipo").toLowerCase() == "bloquear";
    }
    async componentDidMountPrivate() {
        let r = await NocRequest.get("clientes/clientePossuiContasAberto/"+this.props.clienteliberacao.codigo);
        let r1 = await NocRequest.get("eventos/liberacaoConfiancaPendente/"+this.props.clienteliberacao.codigo);

        this.estado({possuiDebitos: this.getBool(r.data), bloqueioPendente: this.getBool(r1.data)});
        if ((! this.getBool(r.data))) this.Refs['buttonativar'].current.focus();

        if ((this.state.possuiDebitos) && (! this.state.bloqueioPendente)) {
            //console.log('focus obs')
            this.Refs['obs'].current.focus();
        }
        else if ((this.state.possuiDebitos) && (this.state.bloqueioPendente)) {
            //console.log('focus voltar')
            this.Refs['buttonvoltar'].current.focus();        
        }        
        else if (! this.state.possuiDebitos) {
            //console.log('focus ativar')
            this.Refs['buttonativar'].current.focus();        
        }
    }

    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    onchange(ev: ChangeEvent) {
        const {name, value} = ev.target as any;
        let s: IState = this.state;
        s.valores[name] = value;
        this.setState(s);
    }
    async submit() {
        this.showConfirm("Confirma a "+(this.isLiberacao() ? "ativação" : "desativação")+" dos serviços desse Cliente ?", async () => {
            let r = await NocRequest.post("licenca/LiberacaoBloqueio", {
                codigo: this.props.clienteliberacao.codigo,
                justificativa: this.state.valores['obs'],
                usuario: this.props.appstate?.login.login,
                tipo: this.isLiberacao() ? "liberar" : "bloquear",
                liberacaoconfianca: this.isLiberacao() && this.state.possuiDebitos ? true : false
            });
            if (this.isOk(r)) {
                this.showAlert("Licença "+(this.isLiberacao() ? "liberada" : "bloqueada")+" com sucesso", () => {
                    this.redirectTo("/clientesinadimplentes");
                });
            }
            else {
                this.showAlert("Ocorreu um erro durante a "+(this.isLiberacao() ? "ativação" : "desativação")+" da licença\n" + r.message);
            }
        }); 
    }
    renderPrivate() {
        let Cells: any[] = [];
        Cells.push([
            <TableCell className="formapag col-3">Cliente</TableCell>,
            <TableCell className="formapag col-9">
                <Input inputRef={this.Refs['cliente']} 
                    className="formControl numericInput col-12 " 
                    name="cliente"
                    disabled={true}
                    value={`${this.props.clienteliberacao.codigo} - ${this.props.clienteliberacao.nome}`} 
                />
            </TableCell>,
        ]);
        Cells.push([
            <TableCell className="formapag col-3">Licença</TableCell>,
            <TableCell className="formapag col-9">
                <Input inputRef={this.Refs['licenca']} 
                    className="formControl numericInput col-12 " 
                    name="licenca"
                    disabled={true}
                    value={`${this.props.clienteliberacao.licenca ? "Ativa" : "Inativa"}`} 
                />
            </TableCell>,
        ]);
        Cells.push([
            <TableCell className="formapag col-3">Justificativa</TableCell>,
            <TableCell className="formapag col-9">
                <Input inputRef={this.Refs['obs']} 
                    className="formControl numericInput col-12 " 
                    multiline={true}
                    rows={8}
                    name="obs"
                    disabled={(this.isLiberacao()) && (! this.state.possuiDebitos) || (this.state.bloqueioPendente)}
                    value={this.state.valores['obs']} 
                    onChange={this.onchange}
                />
            </TableCell>,
        ]);
        if ((this.isLiberacao()) && (this.state.possuiDebitos) && (! this.state.bloqueioPendente)) {
            
            Cells.push([
                <TableCell colSpan={2} className="formapag col-2">
                    <span className="aviso"><b>Atenção:</b> Cliente possui débitos em atraso e a liberação ocorrerá sob o regime de cofiança. Dentro do prazo de 15 dias caso os débitos em atraso não sejam pagos então ocorrerá o bloqueio definitivo e a liberação só ocorrerá mediante quitação das contas em aberto.</span>
                </TableCell>,
            ]);
        }
        else if ((this.isLiberacao()) && (this.state.possuiDebitos) && (this.state.bloqueioPendente)) {
            Cells.push([
                <TableCell colSpan={2} className="formapag col-2">
                    <span className="aviso"><b>Atenção:</b> Cliente possui uma liberação em cofiança pendente, a liberação definitiva só poderá ocorrer mediante pagamento das contas em atraso.</span>
                </TableCell>,
            ]);
        }
        else if ((this.isBloqueio()) && (! this.state.possuiDebitos)) {
            Cells.push([
                <TableCell colSpan={2} className="formapag col-2">
                    <span className="aviso"><b>Atenção:</b> Cliente não possui débitos pendentes.</span>
                </TableCell>,
            ]);
        }
        else if ((this.isBloqueio()) && (this.state.possuiDebitos)) {
            Cells.push([
                <TableCell colSpan={2} className="formapag col-2">
                    <span className="aviso"><b>Atenção:</b> Cliente possui débitos pendentes.</span>
                </TableCell>,
            ]);
        }
        if (Cells.length % 2 != 0) {
            Cells.push([
                <TableCell className="formapag col-3">&nbsp;</TableCell>,
                <TableCell className="formapag col-9">&nbsp;</TableCell>,
            ]);
        }

        return (
            <div className="container-fluid col-6">
                <div className="row">
                    <div className="col col-12">
                        <div className="card">
                            <div className="card-header">
                                {this.getParam("tipo") == "liberar" ? "Liberação" : "Bloqueio"} de Cliente
                            </div>
                            <div className="card-body">
                                <TableContainer component={Paper} style={{height: '100%'}}>
                                    <Table className="" aria-label="simple table">
                                        <TableBody>
                                            {Cells.map((v: any, index: number) => {
                                                //if (index % 2 == 0)
                                                    return (
                                                        <TableRow>
                                                            {Cells[index][0]}
                                                            {Cells[index][1]}
                                                            {/* {Cells[index+1][0]}
                                                            {Cells[index+1][1]} */}
                                                        </TableRow>
                                                    );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Button ref={this.Refs['buttonativar']} disabled={this.isLiberacao() && this.state.possuiDebitos && this.state.bloqueioPendente} color="primary" variant="contained" onClick={this.submit}>{this.isLiberacao() ? "Ativar" : "Inativar"}</Button>
                                                    &nbsp;<Button ref={this.Refs['buttonvoltar']} color="secondary" variant="contained" onClick={this.voltar}>Voltar</Button>
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>            
                                
                            </div>
                        </div>
                    </div>
                </div>
                {this.baseRender()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {
        appstate: state,
        clienteliberacao: state.dados.clienteliberacao,
    };
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
    };
}


export default connect(mapStateToProps, mapDisptachToProps)(LiberarClientesInadimplente);

