import React, { Component, forwardRef, ChangeEvent, KeyboardEvent, useMemo } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Input, Button, Select, MenuItem } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { ITiposistema } from "../../persistence/interfaces/ITiposistema";
import { EmptyBaseState } from "../Component2/Component2";
import { RecordWithTtl } from "dns";
import MessageBox from "../MessageBox/MessageBox";
import NumericInput from "../NumericInput/NumericInput";
import { connect } from "react-redux";
import { AppState } from "../../reducer/ReducerTypes";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import { ISprint } from "../../persistence/interfaces/ISprint";
import { withRouter } from "react-router-dom";
import TabelaGridContainer from "../Tabela/TabelaGridContainer";
import TabelaGridItem from "../Tabela/TabelaGridItem";
import Card from "../../ui/components/Card/Card";
import CardHeader from "../../ui/components/Card/CardHeader";
import CardBody from "../../ui/components/Card/CardBody";
import Formulario, { FormularioClass } from "../Formulario/Formulario";
import { FormFuncs, OldState } from "../Formulario/FormularioTypes";
import md5 from "md5";
import { GetConfiguracoes } from "../../reducer/ActionsCreator";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate: AppState;
    configuracoes: () => void;
}
interface IState {
}
class AddConfiguracoes extends PrivateComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        //this.onBeforePost = this.onBeforePost.bind(this);
        this.afterpost = this.afterpost.bind(this);
        this.state = {
            ...EmptyBaseState,
        }
    }
    componentDidMountPrivate() {
    }
    async afterpost(self: FormularioClass, state: any) {
        console.clear();
        console.log('carregado configuracoes', state);
        this.props.configuracoes();
    }
    renderPrivate() {
        return (
            <Formulario
                formWidth={8}
                formTitle="Parâmetros de Configurações"
                entity="Configuracoes"
                goBack={() => this.redirectTo("/admin/configuracoes")}
                onAfterPost={this.afterpost}
                Campos={[
                    {
                        type: "string",
                        name: "id",
                        caption: "ID",
                        widthField: 1,
                        disabled: true,
                    },
                    {
                        type: "string",
                        name: "classe",
                        caption: "Classe",
                        helpText: "Classe a qual o parâmetro pertence",
                        widthField: 2,
                    },
                    {
                        type: "string",
                        name: "parametro",
                        caption: "Parâmetro",
                        helpText: "Informar a senha em formato plano ou md5",
                        widthField: 3,
                    },
                    {
                        type: "string",
                        name: "descricao",
                        caption: "Descrição",
                        helpText: "Informe a descrição para o parâmetro",
                        widthField: 6,
                    },
                    {
                        type: "string",
                        name: "valor",
                        caption: "Valor",
                        helpText: "Informe o valor para o parâmetro",
                        widthField: 12,
                    },
                ]}
            >
            </Formulario>
        );    
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        //SetClienteVenda: (codigocliente: number, nome: string) => dispatch(SetClienteVenda(codigocliente, nome)),
        ...ownProps,
        configuracoes: () => dispatch(GetConfiguracoes()),
    };
}

export default connect(mapStateToProps, mapDisptachToProps)(AddConfiguracoes);
