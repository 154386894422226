import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios, { AxiosRequestConfig } from "axios";
import { Input, Button, Select, MenuItem } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import { EmptyBaseState } from "../Component2/Component2";
import { AppState } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tabela2 from "../Tabela/Tabela2";
import Formatter from "../../utils/Formatters";
import { Filter1, Filter2, Filter3, Filter4, Filter5 } from "@material-ui/icons";
var fileDownload = require('js-file-download');

const tableIcons: any = {
    Add: forwardRef((props, ref: any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref: any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref: any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref: any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref: any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref: any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref: any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref: any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref: any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref: any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref: any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref: any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref: any) => <ViewColumn {...props} ref={ref} />)
};

const RowClick = async (event: any, rowdata: any, errorFunc: any) => {
}
interface IRef {
    [Name: string]: React.RefObject<HTMLInputElement>;
}
interface IState {
    estado: "grid" | "edit" | "insert";
    razao_social: string;
    cnpj: string;
    ativo: string;
    tipo: string;
    comContratos: "S" | "N" | "T";
}
class CNPJInstalados extends PrivateComponent<any, IState> {
    Ref: IRef;
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.getTitulo = this.getTitulo.bind(this);
        this.getSql = this.getSql.bind(this);
        this.subtitle = this.subtitle.bind(this);
        this.Ref = {
            'cnpj': React.createRef(),
            'razao_social': React.createRef(),
            'ativo': React.createRef(),
        };
        
        this.state = {
            ...EmptyBaseState,
            estado: "grid",
            cnpj: "",
            razao_social: "",
            ativo: "",
            tipo: "LNI",
            comContratos: "T"
        }
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    consulta(query: any) {
        let order = (query?.orderBy?.field || 'razaosocial') + ' ' + (query?.orderDirection || 'asc');
        let search: string = query?.search || '';
        return new Promise<any>(async (resolve, reject) => {
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql: `
                    select * from (
                        ${this.getSql()}
                    ) as con
                    order by ${order}
                `,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: ""
            })
    
            resolve({
                data: result?.data?.data,
                page: result?.data?.page - 1,
                totalCount: result?.data?.total
            });
        })
    }
    getSql(): string {
        // LNI NLNI LI NLI
        let ret = "";
        if (this.state.tipo == "LNI") {
            ret = `
                select n.cnpj, n.razao_social as razaosocial, '' as cnpjnoc, '' as razaosocialnoc
                from nfes_licencas n
                left join lojas l on l.cnpj = n.cnpj
                left join a_client cli on cli.c_cgccpf = n.cnpj and trim(cli.c_cgccpf) <> ''
                left join a_contas con on con.c_codcf = cli.c_codigo
                where true
                    ${this.state.comContratos == "S" ? "and coalesce(con.c_contrato,0) > 0 " : (this.state.comContratos == "N" ? "and coalesce(con.c_contrato,0) = 0 " : (""))}
                    and n.ativo = true
                    and l.cnpj is null
                group by n.cnpj, n.razao_social--, l.cnpj, l.nome
                order by 2
            `;
        }
        else if (this.state.tipo == "NLNI") {
            ret = `
                select n.cnpj, n.razao_social as razaosocial, '' as cnpjnoc, '' as razaosocialnoc
                from nfes_licencas n
                left join lojas l on l.cnpj = n.cnpj
                left join a_client cli on cli.c_cgccpf = n.cnpj and trim(cli.c_cgccpf) <> ''
                left join a_contas con on con.c_codcf = cli.c_codigo
                where true
                    ${this.state.comContratos == "S" ? "and coalesce(con.c_contrato,0) > 0 " : (this.state.comContratos == "N" ? "and coalesce(con.c_contrato,0) = 0 " : (""))}
                    and n.ativo = false
                    and l.cnpj is null
                group by n.cnpj, n.razao_social--, l.cnpj, l.nome
                order by 2
            `;
        }
        else if (this.state.tipo == "LI") {
            ret = `
                select n.cnpj, n.razao_social as razaosocial, l.cnpj as cnpjnoc, l.nome as razaosocialnoc
                from nfes_licencas n
                inner join lojas l on l.cnpj = n.cnpj
                left join a_client cli on cli.c_cgccpf = n.cnpj and trim(cli.c_cgccpf) <> ''
                left join a_contas con on con.c_codcf = cli.c_codigo
                where true
                    ${this.state.comContratos == "S" ? "and coalesce(con.c_contrato,0) > 0 " : (this.state.comContratos == "N" ? "and coalesce(con.c_contrato,0) = 0 " : (""))}
                    and n.ativo = true
                group by n.cnpj, n.razao_social, l.cnpj, l.nome
                order by 2
            `;
        }
        else if (this.state.tipo == "NLI") {
            ret = `
                select n.cnpj, n.razao_social as razaosocial, l.cnpj as cnpjnoc, l.nome as razaosocialnoc
                from lojas l 
                left join nfes_licencas n on l.cnpj = n.cnpj
                left join a_client cli on cli.c_cgccpf = n.cnpj and trim(cli.c_cgccpf) <> ''
                left join a_contas con on con.c_codcf = cli.c_codigo
                where true
                    ${this.state.comContratos == "S" ? "and coalesce(con.c_contrato,0) > 0 " : (this.state.comContratos == "N" ? "and coalesce(con.c_contrato,0) = 0 " : (""))}
                    and n.ativo = true
                    and n.cnpj is null
                group by n.cnpj, n.razao_social, l.cnpj, l.nome
                order by 2
            `;
        }
        console.log(ret);
        return ret;
    }
    
    getTitulo(): string {
        // LNI NLNI LI NLI
        let ret = "";
        if (this.state.tipo == "LNI") {
            ret = "CNPJ Liberado e não Instalado";
        }
        else if (this.state.tipo == "NLNI") {
            ret = "CNPJ não Liberado e não Instalado";
        }
        else if (this.state.tipo == "LI") {
            ret = "CNPJ Liberado e Instalado";
        }
        else if (this.state.tipo == "NLI") {
            ret = "CNPJ não Liberados e Instalado";
        }
        return ret;
    }
    subtitle(): string {
        let ret = "";
        if (this.state.comContratos == "S") {
            ret = "Licenças com contratos vinculados";
        }
        else if (this.state.comContratos == "N") {
            ret = "Livenças sem contratos vinculados";
        }
        else if (this.state.comContratos == "T") {
            ret = "Todas as licenças";
        }
        return ret;
    }
    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    actions={[
                        {
                            isFreeAction: true,
                            tooltip: "CNPJ's Liberados e não Instalados",
                            icon: <Filter1 />,
                            onClick: (rowData: any) => {
                                this.setState({tipo: "LNI"})
                                this.addRefreshCount();
                            }
                        },
                        {
                            isFreeAction: true,
                            tooltip: "CNPJ's não Liberados e não Instalados",
                            icon: <Filter2 />,
                            onClick: (rowData: any) => {
                                this.setState({tipo: "NLNI"})
                                this.addRefreshCount();
                            }
                        },
                        {
                            isFreeAction: true,
                            tooltip: "CNPJ's Liberados e Instalados",
                            icon: <Filter3 />,
                            onClick: (rowData: any) => {
                                this.setState({tipo: "LI"})
                                this.addRefreshCount();
                            }
                        },
                        {
                            isFreeAction: true,
                            tooltip: "CNPJ's não Liberados e Instalados",
                            icon: <Filter4 />,
                            onClick: (rowData: any) => {
                                this.setState({tipo: "NLI"})
                                this.addRefreshCount();
                            }
                        },
                        {
                            isFreeAction: true,
                            tooltip: "Filtrar licenças vinculadas a contratos",
                            icon: <Filter5 />,
                            onClick: (rowData: any) => {
                                const s = this.state.comContratos == "S" ? "N" : (this.state.comContratos == "N" ? "T" : "S");
                                this.setState({comContratos: s})
                                this.addRefreshCount();
                            }
                        },
                    ]}
                    actionBarFreeWidth={2}
                    actionsColumns={6}
                    actionColumnWidth={25}
                    columns={[
                        { title: "CNPJ", field: "cnpj" },
                        { title: "Razão Social (Lic)", field: "razaosocial" },
                        { title: "CNPJ", field: "cnpjnoc" },
                        { title: "Razão Social (NOC)", field: "razaosocialnoc" },
                    ]}
                    

                    data={this.consulta}
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     //maxBodyHeight: '50vw',
                    //     pageSize: 30,
                    //     pageSizeOptions: [5, 10, 20, 30, 50, 100]
                    // }}
                    search={true}
                    pageSize={30}
                    title={this.getTitulo()}
                    subtitle={this.subtitle()}
                    // onRowClick={this.rowClick/*RowClick*/}
                    refreshCount={this.state.refreshCount}
                    printReport={true}
                    reportSql={this.getSql()}
                    reportTitle={this.getTitulo()}
                    reportOptions={{
                        orientation: "landscape"
                    }}
                    reportColumns={[
                        {
                            name: "cnpj",
                            title: "CNPJ",
                            align: "left",
                            type: "string",
                            width: 100,
                        },
                        {
                            name: "razaosocial",
                            title: "Razão Social (Lic)",
                            align: "left",
                            type: "string"
                        },
                        {
                            name: "cnpjnoc",
                            title: "CNPJ",
                            align: "left",
                            type: "string",
                            width: 100,
                        },
                        {
                            name: "razaosocialnoc",
                            title: "Razão Social (NOC)",
                            align: "left",
                            type: "string",
                        },
                        

                    ]}

                />
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return state;
}
export default connect(mapStateToProps)(CNPJInstalados);