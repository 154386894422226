import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { AppState, IClienteLiberacao } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { SetClienteLiberacao } from "../../reducer/ActionsCreator";
import { Store } from "../../reducer/Reducer";
import Component2 from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import Formatter from "../../utils/Formatters";
import { withRouter } from "react-router-dom";
import Tabela2 from "../Tabela/Tabela2";
import Utils from "../../utils/Utils";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate?: AppState;
    linhaproduto: string;
    SetClienteLiberacao: (clienteliberacao: IClienteLiberacao) => void;
}

class ClientesInadimplentes extends PrivateComponent<IProps, any> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.ativarServicos = this.ativarServicos.bind(this);
        this.inativarServicos = this.inativarServicos.bind(this);
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    consulta(query: any) {
        const expr = (field: string, type: string, formmat: string) => {
            let s = field;
            if (type == "date")
                s = `to_char(${field}, '${formmat}')`;
            return s;
        }
        //console.log(query)
        let order = (query?.orderBy?.field || 'diasvenc') + ' ' + (query?.orderDirection || 'desc');
        let search: string = query?.search || '';
        let field: string = query?.field || '';
        let type: string = query?.fieldtype || '';
        let formmat: string = query?.fieldformmat || '';
        let where: string = "";
        where = field == "" ? `
            where  false
            or c_codcf::text like '%${search.toUpperCase()}%'
            or c_cliente::text like '%${search.toUpperCase()}%'
            or c_cgccpf::text like '%${search.toUpperCase()}%'
        ` : `
            where  ${expr(field, type, formmat)}::text like '%${search.toUpperCase()}%'
        `;

        let contrato = Utils.ConfiguracaoBool(this.props.appstate?.dados.configuracoes || [], 'financeiro/contas-contrato');
        return new Promise<any>(async(resolve, reject) => {
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql: `

                    select * from (
                        select 
                            c_codcf, 
                            c.c_numdoc,
                            c.c_fatura,
                            c.c_dtvenc,
                            cli.c_cliente, 
                            cli.c_cgccpf,
                            greatest(0, current_date - c_dtvenc) as diasvenc, 
                            greatest(0, current_date - c_dtvenc) between  1 and 15 as venc15, 
                            greatest(0, current_date - c_dtvenc) between 16 and 30 as venc30, 
                            greatest(0, current_date - c_dtvenc) > 30 as venc, 
                            case when greatest(0, current_date - c_dtvenc) between  1 and 15 then 'venc15' else
                            case when greatest(0, current_date - c_dtvenc) between 16 and 30 then 'venc30' else
                            'venc' end end tipovenc,
                            c_saldo ,
                            case when l.ativo is null then '' else case when coalesce(l.ativo,false) then 'Ativa' else 'Inativa' end end as licencaativa,
                            c.c_contrato
                        from a_contas c
                        left join a_client cli on cli.c_codigo = c.c_codcf
                        left join nfes_licencas l on regexp_replace(l.cnpj, '[^0-9]', '', 'gi') = regexp_replace(cli.c_cgccpf, '[^0-9]', '', 'gi')
                        where c.c_tipo='R' and trim(c.c_sit) = '' and greatest(0, current_date - c_dtvenc) > 0
                            ${contrato ? ` and coalesce(c.c_contrato, 0) > 0  ` : ''}
                    ) as con1
                    ${where}
                    order by ${order}               
                `,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: "" 
            })
    
            resolve({
                data: result?.data?.data,
                page: result?.data?.page-1,
                totalCount: result?.data?.total
            });
        })   
    }
    ativarServicos(rowData: any) {
        //console.log(rowData, `${rowData.licencaativa}`.toUpperCase());
        if (`${rowData.licencaativa}`.toUpperCase() != "ATIVA") {
            this.showConfirm("Confirma ativação dos serviços desse Cliente ?", async () => {
                this.props.SetClienteLiberacao({
                    codigo: rowData.c_codcf,
                    nome: rowData.c_cliente,
                    licenca: false,
                })
                this.redirectTo("/liberarclienteinadimplente/liberar/"+rowData.c_codcf);
            });
        }
        else {
            this.showAlert("Licença do cliente já esta ativa")
        }
    }
    inativarServicos(rowData: any) {
        if (`${rowData.licencaativa}`.toUpperCase() == "ATIVA") {
            this.showConfirm("Confirma inativação dos serviços desse Cliente ?", async () => {
                this.props.SetClienteLiberacao({
                    codigo: rowData.c_codcf,
                    nome: rowData.c_cliente,
                    licenca: false,
                })
                this.redirectTo("/liberarclienteinadimplente/bloquear/"+rowData.c_codcf);
            });
        }
        else {
            this.showAlert("Licença do cliente já esta inativa")
        }
    }
    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    actions={[
                        {
                            icon: <Clear />,
                            onClick: (rowData: any) => {
                                this.inativarServicos(rowData);
                            },
                            tooltip: "Inativar Serviços",
                        },
                        {
                            icon: <AddCircleIcon/>,
                            onClick: (rowData: any) => {
                                this.ativarServicos(rowData);
                            },
                            tooltip: "Ativar Serviços",
                        },
                    ]}
                    columns={[
                        { title: "Código", field: "c_codcf" },
                        { title: "CPF/CNPJ", field: "c_cgccpf" },
                        { title: "Cliente", field: "c_cliente" },
                        { title: "Dias Venc.", field: "diasvenc" },
                        { title: "Tipo", field: "tipovenc", render: (data: any) => {
                            return (data.tipovenc == "venc15" ? "Até 15 dias" : (data.tipovenc == "venc30" ? "Até 30 dias" : "+ 30 dias"));
                        } },
                        { title: "Núm. Doc.", field: "c_numdoc" },
                        { title: "Fatura", field: "c_fatura" },
                        { title: "Vencimento", field: "c_dtvenc", type: 'date', render: Formatter.date("c_dtvenc") },
                        { title: "Saldo R$", field: "c_saldo", type: 'numeric', render: Formatter.moeda("c_saldo", 2) },
                        { title: "Licença", field: "licencaativa" },
                        { title: "Contrato", field: "c_contrato" },
                    ]}
                    fieldsSelect={[
                        "c_codcf", "c_cgccpf", "c_cliente", "c_dtvenc:date:dd-mm-yyyy", "c_numdoc", "c_fatura"
                    ]}
                    data={this.consulta}
                    orderByField="diasvenc"
                    orderByDirection="desc"
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     minBodyHeight: 'calc(100vh - 200px)',
                    //     maxBodyHeight: 'calc(100vh - 200px)',
                    //     pageSize: 30,
                    //     pageSizeOptions: [9, 20, 30, 50, 100],
                    //     search: true,
                    // }}
                    // search2={true}
                    search={true}
                    pageSize={30}
                    // onSearchChange={(txt: string) => { console.log(txt); }}
                    // onQueryChange={() => { console.log('query changed') }}

                    title="Clientes Inadimplentes"
                    actionBarFreeWidth={2}
                    actionsColumns={2}
                    actionColumnWidth={25}
                    // onRowClick={this.rowClick/*RowClick*/}
                    // localization={{
                    //     header: {
                    //         actions: ""
                    //     }
                    // }}
                    
                />
                {this.baseRender()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
        SetClienteLiberacao: (clienteliberacao: IClienteLiberacao) => dispatch(SetClienteLiberacao(clienteliberacao)),
    };
}

export default connect(mapStateToProps, mapDisptachToProps)(ClientesInadimplentes);