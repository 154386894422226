import React, { Component, forwardRef, ChangeEvent, KeyboardEvent, useMemo } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, Badge, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Input, Button, IconButton } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { ITiposistema } from "../../persistence/interfaces/ITiposistema";
import Component2, { EmptyBaseState } from "../Component2/Component2";
import { RecordWithTtl } from "dns";
import MessageBox from "../MessageBox/MessageBox";
import NumericInput from "../NumericInput/NumericInput";
import { connect } from "react-redux";
import { AppState } from "../../reducer/ReducerTypes";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import Utils from "../../utils/Utils";
import EditBox from "../EditBox/EditBox";
import { withRouter } from "react-router-dom";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
require("./Instalador.css");

var fileDownload = require('js-file-download');

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate: AppState;
}
interface IState {
    sistemas: ITiposistema[];
    valores: IValor;
    listaCNPJ: string[];
}
interface IRefs {
    [Name: string]: React.RefObject<any>;
}
interface IValor {
    [Name: string]: string;
}
interface ICampo {
    codigo: string;
    descricao: string;
    mask: string;
    extrainfo: string;
    type: string;
    defaultValue: string;
}
class InstaladorSmartCompany extends PrivateComponent<IProps, IState> {
    Refs: IRefs;
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.onchange = this.onchange.bind(this);
        this.onkeydown = this.onkeydown.bind(this);
        this.changeedit = this.changeedit.bind(this);
        this.submit = this.submit.bind(this);
        this.copy = this.copy.bind(this);
        this.addLista = this.addLista.bind(this);
        this.removeLista = this.removeLista.bind(this);
        this.Refs = {};
        let v: IValor = {};
        this.listaCampos().forEach((cmp: ICampo, index: number) => {
            this.Refs[cmp.codigo] = React.createRef();
            v[cmp.codigo] = cmp.defaultValue;
        });
        //v['cnpj'] = '011';
        this.state = {
            ...EmptyBaseState,
            sistemas: [],
            valores: v,
            listaCNPJ: [],
        }
    }
    listaCampos(): ICampo[] {
        return [
            {codigo: "servidorip", descricao: "IP do Servidor", type: "edit", mask: "", extrainfo: "Endereço do servidor onde serão disponibilizados os serviços. Não utilize localhost ou 127.0.0.1", defaultValue: "", },
            {codigo: "gatewayportaoperacao", descricao: "Gateway Porta de Acesso", type: "edit", mask: "", extrainfo: "Porta de acesso aos serviços expostos pelo Gateway. Padrão 3000", defaultValue: "3000", },
            {codigo: "gatewayportaadm", descricao: "Gateway Porta de Acesso Administrativo", type: "edit", mask: "", extrainfo: "Porta de acesso aos serviços administrativos expostos pelo Gateway. Padrão 3001", defaultValue: "3001", },
            {codigo: "smartcompanyporta", descricao: "SmartCompany Porta de Acesso", type: "edit", mask: "", extrainfo: "Porta onde os serviços do Webservice do SmartCompany irá export seus serviços. Padrão 3057", defaultValue: "3057", },
            {codigo: "bancoip", descricao: "Banco de Dados - Host", type: "edit", mask: "", extrainfo: "Endereço IP do servidor de banco de dados. Não utilize localhost ou 127.0.0.1", defaultValue: "", },
            {codigo: "bancoporta", descricao: "Banco de Dados - Porta", type: "edit", mask: "", extrainfo: "Porta do servidor de banco de dados", defaultValue: "5432", },
            {codigo: "bancousuario", descricao: "Banco de Dados - Usuário", type: "edit", mask: "", extrainfo: "Usuário de acesso ao servidor de banco de dados", defaultValue: "icomp", },
            {codigo: "bancosenha", descricao: "Banco de Dados - Senha", type: "edit", mask: "", extrainfo: "Senha do usuário de acesso ao servidor de banco de dados", defaultValue: "icompdbpw", },
            {codigo: "bancobanco", descricao: "Banco de Dados - Banco de dados", type: "edit", mask: "", extrainfo: "Nome do banco de dados vinculado ao SmartCompany", defaultValue: "banco", },
        ];

    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    async consulta() {
        
        let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
            sql: `
                select * from tiposistema where linhaproduto = ''
                order by codigo='banco' desc            
            `,
            page: 1,
            pageSize: 100,
            search: '',
            orderBy: ""
        });
    }
    changeedit(name: string, value: string, value2: string) {
        let s: IState = this.state;
        s.valores[name] = value;
        s.valores[name+'2'] = value2;
        this.setState(s);
    }
    onchange(ev: ChangeEvent) {
        const {name, value} = ev.target as any;
        let s: IState = this.state;
        s.valores[name] = value;
        this.setState(s);
    }
    onkeydown(e: KeyboardEvent) {
        let lista = this.listaCampos();
        const {name, value} = e.target as any;

        if (e.which == 13) {
            let id = -1;
            lista.forEach((v: ICampo, index: number) => {
                if (v.codigo == name) {
                    id = index+1;
                }
            });

            id = id >= lista.length ? 0 : id;
            e.preventDefault();
            this.Refs[lista[id].codigo].current?.focus();
        }
    }
    async submit() {
        let msg = "";

        let dados: any = {
            servidorip: this.state.valores["servidorip"],
            gatewayportaoperacao: this.state.valores["gatewayportaoperacao"],
            gatewayportaadm: this.state.valores["gatewayportaadm"],
            smartcompanyporta: this.state.valores["smartcompanyporta"],
            bancoip: this.state.valores["bancoip"],
            bancoporta: this.state.valores["bancoporta"],
            bancousuario: this.state.valores["bancousuario"],
            bancosenha: this.state.valores["bancosenha"],
            bancobanco: this.state.valores["bancobanco"],
            cache: new Date(),
            cache2: new Date().getTime(),
        }
    
        let data = Buffer.from(JSON.stringify(dados)).toString("base64");
        this.show2Buttons([
            "Para instalar o NOC/Atualizador automático execute o comando abaixo no terminal do servidor (como usuário root):",
            `bash <(curl -s http://noc.wcompany.com.br:3054/smartcompany/install/${data})`
        ], 
        'Copiar', 'Fechar',
        () => {
            let data = Buffer.from(JSON.stringify(dados)).toString("base64");
            navigator.clipboard.writeText(`bash <(curl -s http://noc.wcompany.com.br:3054/smartcompany/install/${data})`);
        }, () => {}, "lg");

    }
    async copy() {
        let msg = "";
    }
    delayedMount() {
        //this.Refs['cnpj'].current?.focus();
    }
    addLista() {
        let {cnpj, validade} = this.state.valores;
        let c = Utils.onynumbers(cnpj);
        if (c.length != 14) {
            this.showAlert("CNPJ inválido");
        }
        else if (this.state.listaCNPJ.indexOf(c) >= 0) {
            this.showAlert("CNPJ já inserido")
        }
        else {
            let s = [...this.state.listaCNPJ, c];
            this.estado({listaCNPJ: s});
        }
    }
    removeLista(cnpj: string) {
        this.estado({listaCNPJ: this.state.listaCNPJ.filter((c: string) => c != cnpj )});
    }
    
    renderPrivate() {
        let Cells = this.listaCampos().map((v: ICampo, index: number) => {
            return [
                <TableCell className="formapag col-3" size="small">
                    {v.descricao}
                    {v.extrainfo != "" ? <div className="extra">{v.extrainfo}</div> : null}
                </TableCell>,
                <TableCell className="formapag col-3" size="small">
                    {v.type == "mask" ? 
                        <EditBox inputRef={this.Refs[v.codigo]} 
                            className="formControl numericInput " 
                            name={v.codigo} 
                            value={this.state.valores[v.codigo]} 
                            // onChange={this.onchange} 
                            onKeyDown={this.onkeydown}
                            //autoFocus={false} 
                            mask={v.mask}
                            maskFillChar="x"
                            saveLiteralChar={index == 1}
                            ref={this.Refs[v.codigo]} 
                            dataChanged={this.changeedit}
                        />
                    : 
                        <Input inputRef={this.Refs[v.codigo]} 
                            className="formControl numericInput " 
                            name={v.codigo} 
                            value={this.state.valores[v.codigo]} 
                            onChange={this.onchange} 
                            onKeyDown={this.onkeydown}
                            fullWidth
                            //autoFocus={false} 
                            
                            ref={this.Refs[v.codigo]} 
                            //dataChanged={this.changeedit}
                        />
                    }
                    {/* {v.codigo=="cnpj" ? <IconButton onClick={this.addLista}><AddCircleIcon style={{color: "green"}} /></IconButton> : null} */}
                </TableCell>,
            ];
        });

        return (
            <div className="container-fluid col-6">
                <div className="row">
                    <div className="col col-12">
                        <div className="card">
                            <div className="card-header">
                                Instalador SmartCompany
                            </div>
                            <div className="card-body">
                                <TableContainer component={Paper} style={{height: '100%'}}>
                                    <Table className="" aria-label="simple table">
                                        <TableBody>
                                            {Cells.map((v: any, index: number) => {
                                                return (
                                                    <TableRow>
                                                        {Cells[index][0]}
                                                        {Cells[index][1]}
                                                    </TableRow>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Button color="primary" variant="contained" onClick={this.submit}>Instalar</Button>
                                                    {/* <Button color="secondary" variant="contained" onClick={() => { this.redirectTo("/admin/liberacaolicenca"); } }>Voltar</Button> */}
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>   
                                {/* Val: <span>{this.state.valores['cnpj']}</span>         
                                &nbsp;Val2: <span>{this.state.valores['cnpj2']}</span>          */}
                                {this.baseRender()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );    
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
// const mapDisptachToProps = (dispatch: any, ownProps: any) => {
//     return {
//         //SetClienteVenda: (codigocliente: number, nome: string) => dispatch(SetClienteVenda(codigocliente, nome)),
//         ...ownProps,
//     };
// }

export default connect(mapStateToProps)(InstaladorSmartCompany);
