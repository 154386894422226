import React, {Component} from "react";
import { Store } from "../../reducer/Reducer";
import { Redirect } from "react-router-dom";
import Component2 from "./Component2";
import Key from "../../utils/Keys";
import { aRoutes } from "../../ui/layouts/Admin";
import { AppState } from "../../reducer/ReducerTypes";
class PrivateComponent<P={}, S={}, SS=any> extends Component2<P, S, SS> {
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        super.componentDidMount();
        if (Store.getState().login.logged) 
            this.componentDidMountPrivate();    
    }
    componentWillUnmount() {
        super.componentWillUnmount();
    }
    // componentWillUpdate() {
    //     if (Store.getState().login.logged) 
    //         this.componentWillUpdatePrivate();    
    // }
    render() {
        let R = null;
        R = super.render() as any;
        if (R)
            return R;
        if (! Store.getState().login.logged)
            return <Redirect to="/login" />

        for (let r of aRoutes) {
            //console.log(this.getRouteUrl() === r.props.path)
            if (this.getRouteUrl() === r.props.path) {
                let login = Store.getState().login;
                let gruposPermitidos = r?.props?.gruposPermitidos || [];
                let pode = false;
                //console.log(r.props.path, login.gruposUsuario, gruposPermitidos)
                if ((gruposPermitidos.length == 0) || (login.gruposUsuario.indexOf("noc") >= 0)) {
                  pode = true;
                }
                else {
                  for (let c of login.gruposUsuario) {
                    if (gruposPermitidos.indexOf(c) >= 0) {
                      pode = true;
                      break;
                    }
                  }
                }
          
                if (! pode) {
                    return <Redirect to="/semacesso" />
                }
            }
        }
        return this.renderPrivate();
    }
    async componentWillUpdatePrivate() {
        super.componentDidMount();
    }
    componentDidMountPrivate() {
    }
    renderPrivate() {
        return <React.Fragment></React.Fragment>;
    }
    doKeydown(event: React.KeyboardEvent<Element>) {
        //console.log('PrivateComponent.doKeydown', event.which, this.constructor.name, Sequencer())
        
        switch (event.which) {
            case Key.VK_F2: {
                this.doRedir("/");
                event.preventDefault();
                break;
            }
        }
        if (! event.isDefaultPrevented())
            super.doKeydown(event);
    }
}

export default PrivateComponent;