import React, {Component} from "react";
import $ from "../../includes/jquery";
import { Redirect, withRouter } from "react-router-dom";
import * as H from "react-router-dom";
import MessageBox from "../MessageBox/MessageBox";
import ConfirmBox from "../MessageBox/ConfirmBox";
import { KeycloakInstance } from "keycloak-js";
type ICallable = ()=>void;
const ICallabledUndef = undefined as any;
export interface IBaseState {
    redirectTo: string;
    error: boolean;
    errorMessage: string|string[];
    confirm: boolean;
    confirmMessage: string|string[];
    confirmButtonText?: string,
    closeButtonText?: string;
    refreshCount: number;
    refreshCountOld: number;
    dialogSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    confirmCB: ()=>void;
    alertCB: ()=>void;
}
export const EmptyBaseState: IBaseState = {
    redirectTo: '',
    error: false,
    errorMessage: '',
    confirm: false,
    confirmMessage: '',
    confirmButtonText: '',
    closeButtonText: '',
    refreshCount: 0,
    refreshCountOld: 0,
    confirmCB: () => {},
    alertCB: () => {},
}
export interface BaseProps extends H.RouteComponentProps<any>  {
}
class Component2<P={}, S={}, SS=any> extends Component<P & BaseProps, S & IBaseState, SS> {
    mounted: boolean;
    eventAttached: boolean;
    constructor(props: any) {
        super(props);
        this.mounted = false;
        this.eventAttached = true;
        this.closeAlert = this.closeAlert.bind(this);
        this.alertDialog = this.alertDialog.bind(this);
        this.confirmDialog = this.confirmDialog.bind(this);
        this.confirmClose = this.confirmClose.bind(this);
        this._keydown = this._keydown.bind(this);
        this.delayedMount = this.delayedMount.bind(this);
        this.state = {
            redirectTo: '',
            error: false,
            errorMessage: '',
            confirm: false,
            confirmMessage: '',
            refreshCount: 0,
            refreshCountOld: 0,
        } as any;
        this.attachEvents();
    }
    estado(newState: (S & IBaseState) | {}) {
        this.setState(newState);
    }
    componentDidMount() {
        this.mounted = true;
        this.attachEvents();
        setTimeout(this.delayedMount, 100);
    }
    componentWillUnmount() {
        this.mounted = false;
        //$(document).off('keydown.geral');
        this.dettachEvents();
    }
    delayedMount() {

    }
    private _keydown(event: React.KeyboardEvent<Element>) {
        if (this.eventAttached)
            this.doKeydown(event);
    }
    doKeydown(event: React.KeyboardEvent<Element>) {
        //console.log('Component2.doKeydown', event.which, this.constructor.name, Sequencer())
    }
    /**
     * Redireciona para um destino do roteador.
     * @param target Destino do redirecionamento
     */
    doRedir(target: string) {
        this.estado({redirectTo: target});
    }
    redirectTo(target: string) {
        this.estado({redirectTo: target});
    }
    render() {
        if (this.state.redirectTo != "")
            return <Redirect to={this.state.redirectTo}></Redirect>
        return null;
    }
    baseRender() {
        return [
            <MessageBox size={this.state.dialogSize} title="Aviso" open={this.state.error} message={this.state.errorMessage} dialogClose={this.closeAlert} />,
            <ConfirmBox title="Aviso" open={this.state.confirm} message={this.state.confirmMessage} dialogClose={this.confirmClose} dialogConfirm={this.confirmDialog} confirmButtonText={this.state.confirmButtonText == '' ? 'Confirmar' : this.state.confirmButtonText} closeButtonText={this.state.closeButtonText == '' ? 'Fechar' : this.state.closeButtonText} />,
        ];
    }
    attachEvents() {
        this.eventAttached = true;
        $(document).off('keydown.'+this.constructor.name).on('keydown.'+this.constructor.name, this._keydown);
    }
    dettachEvents() {
        this.eventAttached = false;
        $(document).off('keydown.'+this.constructor.name);
        //console.log('componente desatachado', this.constructor.name)
    }
    // setState(state: any, callback?: () => void): void {
    //     if (this.mounted)
    //         super.setState(Object.assign({}, this.state, state), callback);
    // }
    async alertDialog() {
        const exec = async () => {
            this.state.alertCB.bind(this)();
        }
        if (typeof this.state.alertCB == 'function') {
            await exec();
        }
        this.estado({error: false});
    }
    async confirmDialog() {
        const exec = async () => {
            this.state.confirmCB.bind(this)();
        }
        if (typeof this.state.confirmCB == 'function') {
            await exec();
        }
        this.estado({confirm: false});
    }
    async closeAlert() {
        await this.alertDialog();
        //this.estado({error: false});
    }
    confirmClose() {
        this.estado({confirm: false});
    }
    showAlert(message: string | string[], CB: ICallable = ICallabledUndef, dialogSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false) {
        this.estado({error: true, errorMessage: message, alertCB: CB, dialogSize });
    }
    showConfirm(message: string | string[], CB: ()=>void, dialogSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false) {
        this.estado({confirm: true, confirmMessage: message, confirmCB: CB, confirmButtonText: 'Confirmar', closeButtonText: 'Fechar', dialogSize});
    }
    show2Buttons(message: string | string[], button1Caption: string, button2Caption: string, CBButton1: ()=>void, CBButton2: ()=>void, dialogSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false) {
        this.estado({
            confirm: true, confirmMessage: message, 
            confirmCB: CBButton1, confirmButtonText: button1Caption, 
            closeCB: CBButton2, closeButtonText: button2Caption,
            dialogSize});
    }
    isOk(v: any): boolean {
        let s: string = ""+(v?.status || '');
        let s1: string = ""+(v?.data?.status || 'ok');
        return (s.toLowerCase() == "ok") && (s1.toLowerCase() == "ok");
    }
    isError(v: any): boolean {
        let s: string = ""+(v?.status || '');
        let s1: string = ""+(v?.data?.status || '');
        return (s.toLowerCase() == "error") || (s1.toLowerCase() == "error");
    }
    getMessage(v: any): string {
        let s: string = "";
        if (this.isError(v)) {
            s = v?.data?.message || v?.message || '';
        }
        return s;
    }   
    firstNonZero(v: number[]) {
        let r: number = 0;
        v.forEach((v: number) => {
            if ((r == 0) && (v != 0)) {
                r = v;
            }
        })
        return r;
    }
    getBool(v: any) {
        if (typeof v == 'boolean') {
            return v;
        }
        else if (typeof v == 'string') {
            return ['/sim/s/t/y/yes/tr/true/v/verd/verdadeiro/'].indexOf('/'+v.trim().toLowerCase()+'/') >= 0;
        }
        else if (typeof v == 'number') {
            return v != 0;
        }
        else 
            return false;
    }
    redirectBack() {
        //console.log(this.props);
        
        // if (this.props.history) {
        //     this.props.history.push();
        // }
    }
    getParam(parametro: string): string {
        let r = this.props.match && this.props.match.params && this.props.match.params[parametro];
        r = r || '';
        return `${r}`;
    }
    getRouteUrl(): string {
        let r = this.props.match && this.props.match.path;
        r = r || '';
        return `${r}`;

    }
    /**
     * Incrementa o contador de refresh
     * @param extraState Estados extras para atualizar durante o ciclo de refresh
     */
    addRefreshCount(extraState: any = {}) {
        this.estado({...extraState, refreshCount: this.state.refreshCount + 1});
    }
    /**
     * Verifica se houve mudança no contador de refresh e dispara o metodo doRefresh caso tenha havido mudanças
     */
    checkRefreshCount() {
        const {refreshCount, refreshCountOld} = this.state;
        let x: number = this.state.refreshCount;
        let x1: number = this.state.refreshCountOld;
        if (x != x1) {
            this.doRefresh();
            this.estado({refreshCountOld: x});
        }
    }
    doRefresh() {

    }
}
export default Component2;