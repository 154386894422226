import React, {Component} from "react";
import { Redirect } from "react-router-dom";
import { Store } from "../../reducer/Reducer";
import { Logout as DoLogout } from "../../reducer/ActionsCreator";
class Logout extends Component {
    // constructor(props: any) {
    //     super(props);
    // }
    render() {
        Store.dispatch(DoLogout());
        window.location.href = `https://keycloak.wcompany.dev.br/realms/nextcompany/protocol/openid-connect/logout?post_logout_redirect_uri=${window.origin}/admin/dashboard&client_id=noc_ui`;
        return (
            <Redirect to="/admin/dashboard" 
            />
        );
    }
}

export default Logout;